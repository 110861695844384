import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {
  Component,
} from 'react';
import {
  connect,
} from 'react-redux';
import {
  Button, Card, Checkbox, Header,
  Icon, Modal,
} from 'semantic-ui-react';

class CheckIn extends Component {
  static propTypes = {
    checkInHandler: PropTypes.func.isRequired,
    closeHandler: PropTypes.func.isRequired,
    isCustomer: PropTypes.bool,
  }

  constructor(props) {
    super(props);

    const {
      services,
    } = this.props;
    this.state = {
      checkins: _.map(services, (_, serviceKey) => ({
        service: serviceKey, selected: false,
      })),
    };
  }

  editHandler = (service, selected) => {
    const {
      checkins,
    } = this.state;
    _.find(checkins, (checkin) => checkin.service === service).selected = selected;
    this.setState({
      checkins,
    });
  }

  renderCheckins = () => {
    const {
      services,
      checkInHandler,
      closeHandler,
      isCustomer,
    } = this.props;
    const {
      checkins,
    } = this.state;

    const groupCheckins = _.groupBy(checkins, (checkin) => _.get(services, [checkin.service, 'group'], ''));
    const sortedGroupNames = _.sortBy(_.keys(groupCheckins));

    return (
      <Modal
        open
        onClose={closeHandler}
        closeIcon={(
          <Icon
            name="close" color="red" size="large"
            onClick={closeHandler}
          />
        )}
      >
        <Modal.Header>{isCustomer ? 'Choose Services' : 'Sign-in'}</Modal.Header>
        <Modal.Content style={{
          height: '100%', overflow: 'auto',
        }}
        >
          {_.map(sortedGroupNames, (groupName) => (
            <div
              key={groupName} style={{
                marginBottom: 30,
              }}
            >
              <Header color="blue">{groupName}</Header>
              <Card.Group itemsPerRow={3}>
                {_.map(
                  _.sortBy(
                    _.map(groupCheckins[groupName], (checkin) => ({
                      ...checkin,
                      serviceName: _.get(services, [checkin.service, 'name'], ''),
                    })),
                    'serviceName',
                  ),
                  (checkin, index) => (
                    <Card key={index} onClick={() => this.editHandler(checkin.service, !checkin.selected)} color="teal">
                      <Card.Content>
                        <Card.Header>
                          <Checkbox
                            label={checkin.serviceName}
                            checked={checkin.selected || false}
                          />
                        </Card.Header>
                      </Card.Content>
                    </Card>
                  ),
                )}
              </Card.Group>
            </div>
          ))}
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary onClick={() => checkInHandler(checkins)} style={{
              fontSize: 20,
            }}
          >
            <Icon name="send" />
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  render() {
    return (
      <div>
        {this.renderCheckins()}
      </div>
    );
  }
}

const mapStateToProps = ({
  shop,
}) => ({
  services: shop.shopData.services || {},
});

export default connect(mapStateToProps)(CheckIn);
