import _ from 'lodash';
import {
  all, call,
  put, takeEvery,
} from 'redux-saga/effects';

import {
  db, deleteField,
} from '../../libs/firebase';
import {
  errorHandler, successHandler,
} from '../../libs/log';

import {
  SHOPS,
} from './constants';

function* update(id, data = {}) {
  yield put({
    type: SHOPS.update,
    id,
    data,
  });
}

function* fetchShops() {
  try {
    const ref = db.collection('cached').doc('shops');
    const cachedDoc = yield call([ref, ref.get]);
    yield put({
      type: SHOPS.fetch,
      data: cachedDoc.data(),
    });
  } catch (error) {
    // errorHandler('Failed to fetch shops', error);
    console.log(error);
  }
}

function* updateShops({
  shopID, data, onSuccess,
}) {
  try {
    yield call(() => (new Promise((resolve, rejects) => {
      db.collection('cached').doc('shops')
        .set({
          [shopID]: data,
        }, {
          mergeFields: [shopID],
        })
        .then(() => resolve(true))
        .catch((err) => rejects(err));
    })));

    if (!_.get(data, 'createdAt')) {
      _.set(data, 'createdAt', new Date());
    }
    _.set(data, 'modifiedAt', new Date());
    console.log(data);

    yield call(() => (new Promise((resolve, rejects) => {
      db.collection('shops').doc(shopID)
        .set(data, {
          mergeFields: _.keys(data),
        })
        .then(() => resolve(true))
        .catch((err) => rejects(err));
    })));
    yield* update(shopID, data);
    successHandler('Done', 'Update successfully', onSuccess);
  } catch (error) {
    errorHandler('Failed to update', error);
  }
}

function* removeShops({
  shopID, onSuccess,
}) {
  try {
    const batch = db.batch();
    batch.update(db.collection('cached').doc('shops'), {
      [shopID]: deleteField,
    });
    batch.delete(db.collection('shops').doc(shopID));
    yield call(() => (new Promise((resolve, rejects) => {
      batch.commit().then(() => {
        resolve(true);
      }).catch((error) => {
        errorHandler('Error', error);
        rejects(error);
      });
    })));

    const deleteRefs = [];
    yield call(() => (new Promise((resolve, rejects) => {
      db.collection('shops').doc(shopID).collection('customers').get()
        .then((docs) => {
          docs.forEach((doc) => {
            deleteRefs.push(doc.ref);
          });
          resolve(true);
        })
        .catch((error) => {
          errorHandler('Error', error);
          rejects(error);
        });
    })));
    yield call(() => (new Promise((resolve, rejects) => {
      db.collection('shops').doc(shopID).collection('requests').get()
        .then((docs) => {
          docs.forEach((doc) => {
            deleteRefs.push(doc.ref);
          });
          resolve(true);
        })
        .catch((error) => {
          errorHandler('Error', error);
          rejects(error);
        });
    })));
    const calls = _.map(_.chunk(deleteRefs, 500), (chunk) => {
      const deleteBatch = db.batch();
      _.forEach(chunk, (ref) => {
        deleteBatch.delete(ref);
      });
      return call(() => (new Promise((resolve, rejects) => {
        deleteBatch.commit().then(() => {
          resolve(true);
        }).catch((error) => {
          errorHandler('Error', error);
          rejects(error);
        });
      })));
    });
    yield all(calls);

    yield* update(shopID, {
      deleted: true,
    });
    successHandler('Done', 'Remove successfully', onSuccess);
  } catch (error) {
    errorHandler('Failed to remove', error);
  }
}

export const fetchShopsHandler = () => ({
  type: SHOPS.handlers.fetch,
});

export const updateShopsHandler = (shopID, data, onSuccess) => ({
  type: SHOPS.handlers.update,
  shopID,
  data,
  onSuccess,
});

export const removeShopsHandler = (shopID, onSuccess) => ({
  type: SHOPS.handlers.remove,
  shopID,
  onSuccess,
});

export default function* shopsSaga() {
  yield all([
    yield takeEvery(SHOPS.handlers.fetch, fetchShops),
    yield takeEvery(SHOPS.handlers.update, updateShops),
    yield takeEvery(SHOPS.handlers.remove, removeShops),
  ]);
}
