import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {
  Component,
} from 'react';
import {
  connect,
} from 'react-redux';
import {
  toastr,
} from 'react-redux-toastr';
import ReactTable from 'react-table';
import {
  bindActionCreators,
} from 'redux';
import {
  Button, Form, Header,
  Icon, Modal,
} from 'semantic-ui-react';
import {
  v4 as uuid,
} from 'uuid';

import {
  deleteField,
} from '../../libs/firebase';
import {
  reactTableProps,
} from '../../libs/utils';
import {
  updateShopHandler,
} from '../../redux/actions/shop';

class Tech extends Component {
  static propTypes = {
    techs: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      editor: undefined,
    };
  }

  editHandler = (field, value) => {
    const {
      editor,
    } = this.state;
    this.setState({
      editor: {
        ...editor,
        [field]: value,
      },
    });
  }

  validateHandler = () => {
    const {
      editor,
    } = this.state;
    const requiredFields = [
      {
        field: editor.name, text: 'Name technician',
      },
    ];

    let result = true;
    _.forEach(requiredFields, (item) => {
      if (result && !item.field) {
        result = false;
        toastr.warning(`${item.text} is required!`);
      }
    });
    return result;
  }

  removeHandler = () => {
    const {
      updateShopHandler,
    } = this.props;
    const {
      editor,
    } = this.state;
    updateShopHandler({
      [`techs.${editor.id}`]: deleteField,
    }, () => {
      this.setState({
        editor: undefined,
      });
    });
  }

  saveHandler = () => {
    if (!this.validateHandler()) { return; }

    const {
      techs, updateShopHandler,
    } = this.props;
    const {
      editor,
    } = this.state;
    const id = editor.id || uuid();
    const newTech = {
      ..._.cloneDeep(techs[id]),
      ..._.cloneDeep(editor),
    };
    delete newTech.id;

    updateShopHandler({
      [`techs.${id}`]: newTech,
    }, () => {
      this.setState({
        editor: undefined,
      });
    });
  }

  renderEditor = () => {
    const {
      editor,
    } = this.state;
    if (editor) {
      const {
        id,
      } = editor;
      return (
        <Modal
          className="custom"
          open
          onClose={() => this.setState({
            editor: undefined,
          })}
          closeIcon={(
            <Icon
              name="close"
              color="red"
              size="large"
              onClick={() => this.setState({
                editor: undefined,
              })}
            />
          )}
        >
          <Modal.Header>{id ? 'Edit Technician' : 'Add Technician'}</Modal.Header>
          <Modal.Content>
            <Form style={{
              padding: 10,
            }}
            >
              <Form.Input
                label="#"
                type="number"
                placeholder="#"
                value={_.get(editor, 'order', '')}
                onChange={(e, {
                  value,
                }) => this.editHandler('order', value)}
              />
              <Form.Input
                label="Name"
                placeholder="Name"
                value={_.get(editor, 'name', '')}
                onChange={(e, {
                  value,
                }) => this.editHandler('name', value)}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            {id ? (
              <Button negative onClick={this.removeHandler}>
                <Icon name="trash" />
                Delete
              </Button>
            )
              : null}
            <Button primary onClick={this.saveHandler}>
              <Icon name="save" />
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }
    return null;
  }

  renderTech = () => {
    const {
      techs,
    } = this.props;
    const columns = [
      {
        id: 'order',
        accessor: 'order',
        Header: '#',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
        },
        maxWidth: 50,
        Cell: (row) => <div>{row.value || row.index + 1}</div>,
      },
      {
        id: 'name',
        accessor: 'name',
        Header: 'Name',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
        },
        Cell: (row) => <div>{row.value}</div>,
      },
      {
        id: 'edit',
        Header: '',
        headerClassName: 'tableHeader',
        maxWidth: 50,
        style: {
          textAlign: 'center',
        },
        Cell: (row) => (
          <Button
            icon="edit" primary
            onClick={() => this.setState({
              editor: row.original,
            })}
          />
        ),
        filterable: false,
      },
    ];
    return (
      <div>
        <div style={{
          display: 'flex', justifyContent: 'space-between',
        }}
        >
          <Header as="h3">
            Technician (
            {_.size(techs)}
            )
          </Header>
          <Button.Group>
            <Button
              positive
              onClick={() => this.setState({
                editor: {
                  order: _.size(techs) + 1,
                },
              })}
            >
              <Icon name="add circle" />
              Add Technician
            </Button>
          </Button.Group>
        </div>
        <ReactTable
          {...reactTableProps}
          data={_.orderBy(_.map(techs, (tech, id) => ({
            ...tech, id,
          })), 'order')}
          columns={columns}
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderEditor()}
        {this.renderTech()}
      </div>
    );
  }
}

const mapStateToProps = ({
  shop,
}) => ({
  techs: shop.shopData.techs || {},
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateShopHandler,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Tech);
