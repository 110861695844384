import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import NumPad from 'react-numpad';
import { connect } from 'react-redux';
import { Button, Form, Header, Icon, Segment } from 'semantic-ui-react';

import { db } from '../../../libs/firebase';
import { errorHandler, successHandler } from '../../../libs/log';
import { formatPhone, isBirthdayValid, isPhoneValid } from '../../../libs/utils';

import CheckIn from './CheckIn';

class Signin extends Component {
  static propTypes = {
    shopID: PropTypes.string,
    signin: PropTypes.object,
  };

  constructor(props) {
    super(props);

    const { signin } = this.props;
    this.state = {
      editor: signin || {},
    };
  }

  componentDidMount() {
    document.getElementsByClassName('numpad-signin')[0].childNodes[1].childNodes[0].click();
  }

  cancelHandler = () => {
    this.setState({
      editor: {},
    });
  };

  validateHander = () => {
    const { phone, birthday } = this.state.editor;
    let result = true;
    if (!phone) {
      result = false;
      errorHandler('Error', 'First Name and Phone are required.');
    }
    if (!isPhoneValid(phone)) {
      result = false;
      errorHandler(
        'Error',
        'Phone should be in one of these formats: 123-456-7890, 1234567890, (123) 456-7890 and have 10 numbers only.'
      );
    }
    if (birthday && !isBirthdayValid(birthday)) {
      result = false;
      errorHandler('Error', 'Invalid month or day');
    }
    return result;
  };

  signinHandler = () => {
    if (!this.validateHander()) {
      return;
    }

    const { services, signinSetup } = this.props;

    if (!signinSetup.bypassService && _.size(services)) {
      this.setState({
        showCheckIn: true,
      });
    } else {
      this.checkInHandler([]);
    }
  };

  checkInHandler = (checkins) => {
    const { shopID, backHandler } = this.props;
    const { firstName, lastName, phone: rawPhone, birthday } = this.state.editor;

    const phone = formatPhone(rawPhone);

    db.collection('shops')
      .doc(shopID)
      .collection('customers')
      .doc(phone)
      .get()
      .then((doc) => {
        if (doc.exists) {
          errorHandler('Error', `${phone} is already existed`);
        } else {
          const request = {
            time: new Date().getTime(),
            firstName: firstName || '',
            ...(lastName && { lastName }),
            phone,
            ...(birthday && {
              birthday: moment(`2000/${birthday}`, 'YYYY/MM/DD', true).valueOf(),
            }),
            checkins: _.filter(checkins, 'selected'),
          };
          db.collection('shops')
            .doc(shopID)
            .collection('requests')
            .doc(phone)
            .set(request, {
              mergeFields: _.keys(request),
            })
            .then(() => {
              this.setState(
                {
                  showCheckIn: undefined,
                  editor: {},
                },
                () => backHandler()
              );
              successHandler('Done', 'Sign-In Success');
            })
            .catch((error) => {
              errorHandler('Error', error);
            });
        }
      });
  };

  editHandler = (field, value) => {
    const { editor } = this.state;
    editor[field] = value;
    this.setState({
      editor,
    });
  };

  renderCheckIn = () => {
    const { showCheckIn } = this.state;
    if (showCheckIn) {
      return (
        <CheckIn
          checkInHandler={this.checkInHandler}
          closeHandler={() =>
            this.setState({
              showCheckIn: undefined,
            })
          }
          isCustomer
        />
      );
    }
    return null;
  };

  renderSignin = () => {
    const { signinSetup } = this.props;
    const { editor } = this.state;
    return (
      <Form
        style={{
          fontSize: 20,
        }}
      >
        <Header>Customer Sign-In</Header>
        {signinSetup.formFull && (
          <Form.Group widths="equal">
            <Form.Field>
              <label>First Name (optional)</label>
              <Form.Input
                placeholder="First Name"
                value={editor.firstName || ''}
                onChange={(e, { value }) => this.editHandler('firstName', value)}
              />
            </Form.Field>
            <Form.Field>
              <label>Last Name (optional)</label>
              <Form.Input
                placeholder="Last Name"
                value={editor.lastName || ''}
                onChange={(e, { value }) => this.editHandler('lastName', value)}
              />
            </Form.Field>
          </Form.Group>
        )}
        <Form.Group widths="equal">
          <Form.Field required className="numpad-signin">
            <NumPad.Number
              value={editor.phone || ''}
              onChange={(value) => this.editHandler('phone', value)}
              label="Phone"
              placeholder="1234567890"
              decimal={false}
              negative={false}
              position="center"
              sync
              confirm={console.log}
              update={console.log}
            />
            <Button
              positive
              onClick={this.searchHandler}
              style={{
                fontSize: 20,
              }}
            >
              <Icon name="search" />
              Search
            </Button>
          </Form.Field>
          {signinSetup.formFull && (
            <Form.Field>
              <label>Birthday (optional)</label>
              <Form.Input
                placeholder="MM/DD"
                value={editor.birthday}
                onChange={(e, { value }) => this.editHandler('birthday', value)}
              />
            </Form.Field>
          )}
        </Form.Group>
      </Form>
    );
  };

  render() {
    const { name } = this.props;
    return (
      <div>
        {this.renderCheckIn()}
        <Segment>
          {this.renderSignin()}
          <Button
            negative
            onClick={this.cancelHandler}
            style={{
              fontSize: 20,
            }}
          >
            <Icon name="close" />
            Cancel
          </Button>
          <Button
            positive
            onClick={this.signinHandler}
            style={{
              fontSize: 20,
            }}
          >
            <Icon name="send" />
            Sign-In
          </Button>
          <br />
          <br />
          <p>
            <span
              style={{
                color: 'red',
              }}
            >
              *
            </span>{' '}
            By signing in with your mobile phone number, you agree that {name} may send you periodic
            SMS messages containing information, updates, deals, and promotions. You may unsubscribe
            at any time by replying with the word STOP.
          </p>
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = ({ shop }) => ({
  shopID: shop.shopID,
  services: shop.shopData.services || {},
  signinSetup: shop.shopData.signinSetup || {},
  name: shop.shopData.name || 'Shop',
});

export default connect(mapStateToProps)(Signin);
