import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {
  Component,
} from 'react';
import {
  connect,
} from 'react-redux';
import {
  toastr,
} from 'react-redux-toastr';
import ReactTable from 'react-table';
import {
  bindActionCreators,
} from 'redux';
import {
  Button, Form, Header,
  Icon, Modal,
} from 'semantic-ui-react';
import {
  v4 as uuid,
} from 'uuid';

import {
  deleteField,
} from '../../libs/firebase';
import {
  reactTableProps,
} from '../../libs/utils';
import {
  updateShopHandler,
} from '../../redux/actions/shop';

class Service extends Component {
  static propTypes = {
    services: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      editor: undefined,
    };
  }

  editHandler = (field, value) => {
    const {
      editor,
    } = this.state;
    this.setState({
      editor: {
        ...editor,
        [field]: value,
      },
    });
  }

  validateHandler = () => {
    const {
      editor,
    } = this.state;
    const requiredFields = [
      {
        field: editor.name, text: 'Name',
      },
      {
        field: editor.point, text: 'Point', isNumber: true,
      },
    ];

    let result = true;
    _.forEach(requiredFields, (item) => {
      if (result && (item.isNumber ? item.field === undefined : !item.field)) {
        result = false;
        toastr.warning(`${item.text} is required!`);
      }
    });
    return result;
  }

  removeHandler = () => {
    const {
      updateShopHandler,
    } = this.props;
    const {
      editor,
    } = this.state;
    updateShopHandler({
      [`services.${editor.id}`]: deleteField,
    }, () => {
      this.setState({
        editor: undefined,
      });
    });
  }

  saveHandler = () => {
    if (!this.validateHandler()) { return; }

    const {
      services, updateShopHandler,
    } = this.props;
    const {
      editor,
    } = this.state;
    const id = editor.id || uuid();
    const newService = {
      ..._.cloneDeep(services[id]),
      ..._.cloneDeep(editor),
    };
    if (newService.newGroup) {
      newService.group = newService.newGroup;
      delete newService.newGroup;
    }
    delete newService.id;

    updateShopHandler({
      [`services.${id}`]: newService,
    }, () => {
      this.setState({
        editor: undefined,
      });
    });
  }

  renderEditor = (groups) => {
    const {
      editor,
    } = this.state;

    if (editor) {
      const {
        id,
      } = editor;
      return (
        <Modal
          className="custom"
          open
          onClose={() => this.setState({
            editor: undefined,
          })}
          closeIcon={(
            <Icon
              name="close"
              color="red"
              size="large"
              onClick={() => this.setState({
                editor: undefined,
              })}
            />
          )}
        >
          <Modal.Header>{id ? 'Edit Service' : 'Add Service'}</Modal.Header>
          <Modal.Content>
            <Form style={{
              padding: 10,
            }}
            >
              <Form.Input
                label="#"
                type="number"
                placeholder="#"
                value={_.get(editor, 'order', '')}
                onChange={(e, {
                  value,
                }) => this.editHandler('order', value)}
              />
              <Form.Input
                label="Name"
                placeholder="Name"
                value={_.get(editor, 'name', '')}
                onChange={(e, {
                  value,
                }) => this.editHandler('name', value)}
              />
              <Form.Input
                label="Point"
                type="number"
                placeholder="Point"
                value={_.get(editor, 'point', '')}
                onChange={(e, {
                  value,
                }) => this.editHandler('point', value)}
              />
              <Form.Group inline>
                <label>Group</label>
                <Form.Dropdown
                  placeholder="Select group"
                  selection
                  search
                  clearable
                  options={_.map(groups, (group) => ({
                    key: group, value: group, text: group,
                  }))}
                  value={_.get(editor, 'group', '')}
                  onChange={(e, {
                    value,
                  }) => this.editHandler('group', value)}
                />
                <Form.Input
                  label="(New Group)"
                  placeholder="New Group"
                  value={_.get(editor, 'newGroup', '')}
                  onChange={(e, {
                    value,
                  }) => {
                    this.setState({
                      editor: {
                        ...editor,
                        group: '',
                        newGroup: value,
                      },
                    });
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            {id ? (
              <Button negative onClick={this.removeHandler}>
                <Icon name="trash" />
                Delete
              </Button>
            )
              : null}
            <Button primary onClick={this.saveHandler}>
              <Icon name="save" />
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }
    return null;
  }

  renderServices = () => {
    const {
      services,
    } = this.props;
    const columns = [
      {
        id: 'order',
        accessor: 'order',
        Header: '#',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
        },
        maxWidth: 50,
        Cell: (row) => <div>{row.value || row.index + 1}</div>,
      },
      {
        id: 'name',
        accessor: 'name',
        Header: 'Name',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
        },
        Cell: (row) => <div>{row.value}</div>,
      },
      {
        id: 'point',
        accessor: 'point',
        Header: 'Point',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
        },
        Cell: (row) => <div>{row.value}</div>,
      },
      {
        id: 'group',
        accessor: 'group',
        Header: 'Group',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center', whiteSpace: 'pre-wrap',
        },
        Cell: (row) => <div>{row.value}</div>,
      },
      {
        id: 'edit',
        Header: '',
        headerClassName: 'tableHeader',
        maxWidth: 50,
        style: {
          textAlign: 'center',
        },
        Cell: (row) => (
          <Button
            icon="edit" primary
            onClick={() => this.setState({
              editor: row.original,
            })}
          />
        ),
        filterable: false,
      },
    ];
    return (
      <div>
        <div style={{
          display: 'flex', justifyContent: 'space-between',
        }}
        >
          <Header as="h3">
            Service (
            {_.size(services)}
            )
          </Header>
          <Button.Group>
            <Button
              positive
              onClick={() => this.setState({
                editor: {
                  order: _.size(services) + 1,
                },
              })}
            >
              <Icon name="add circle" />
              Add Service
            </Button>
          </Button.Group>
        </div>
        <ReactTable
          {...reactTableProps}
          data={_.orderBy(_.map(services, (service, id) => ({
            ...service, id,
          })), 'order')}
          columns={columns}
        />
      </div>
    );
  }

  render() {
    const {
      services,
    } = this.props;
    const groups = _.sortBy(_.uniq(_.flatMap(services, (service) => service.group || [])));
    return (
      <div>
        {this.renderEditor(groups)}
        {this.renderServices()}
      </div>
    );
  }
}

const mapStateToProps = ({
  shop,
}) => ({
  services: shop.shopData.services || {},
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateShopHandler,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Service);
