import {
  SCREEN, SIGN_OUT,
} from '../actions/constants';

const initialState = {};

export default function screens(state = initialState, action) {
  switch (action.type) {
    case SCREEN.mounted:
      return {
        ...state,
        [action.screen]: {},
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
