import axios from 'axios';

import { app } from './firebase';

// const baseURL = 'http://localhost:7001/api';
const baseURL = 'https://quickstartsystem.herokuapp.com/api';

const api = axios.create({
  baseURL,
  withCredentials: true,
});

const getOptions = async () => {
  let token = localStorage.getItem('token');
  if (!token) {
    token = await app.auth().currentUser.getIdToken(true);
    localStorage.setItem('token', token);
  } else {
    token = await app.auth().currentUser.getIdToken(true);
    localStorage.setItem('token', token);
  }
  return {
    headers: {
      token,
    },
  };
};

const post = async (url, payload = {}, retry = 1) => {
  try {
    const options = await getOptions();
    const res = await api.post(url, payload, options);
    return res;
  } catch (error) {
    if (retry) {
      localStorage.removeItem('token');
      const newToken = await app.auth().currentUser.getIdToken(true);
      localStorage.setItem('token', newToken);
      const res = await post(url, payload, retry - 1);
      return res;
    }
    throw error;
  }
};

export { post };
