import {
  reducer as notifications,
} from 'react-redux-toastr';
import {
  combineReducers,
} from 'redux';

import screens from './screens';
import shop from './shop';
import shops from './shops';
import user from './user';

const rootReducer = combineReducers({
  toastr: notifications,
  screens,
  user,
  shop,
  shops,
});

export default rootReducer;
