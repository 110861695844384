import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {
  Component,
} from 'react';
import {
  Input,
} from 'semantic-ui-react';

export default class TextInput extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    action: PropTypes.shape({
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  };

  static defaultProps = {
    onChange: undefined,
    value: '',
    placeholder: '',
    action: undefined,
  };

  constructor(props) {
    super(props);

    const {
      value,
    } = this.props;
    this.state = {
      value,
    };
  }

  onChange = _.debounce(() => {
    const {
      onChange,
    } = this.props;
    const {
      value,
    } = this.state;
    if (onChange) { onChange(value); }
  }, 200);

  render() {
    const {
      placeholder, action,
    } = this.props;
    const {
      value,
    } = this.state;
    return (
      <Input
        style={{
          paddingLeft: 10,
        }}
        fluid
        placeholder={placeholder}
        value={value}
        onChange={(e, {
          value: val,
        }) => this.setState({
          value: val,
        }, this.onChange)}
        action={action}
      />
    );
  }
}
