import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  Component,
} from 'react';
import ReactApexChart from 'react-apexcharts';
import DatePicker from 'react-datepicker';
import {
  connect,
} from 'react-redux';
import {
  toastr,
} from 'react-redux-toastr';
import {
  Button,
  Dropdown,
  Form, Header,
} from 'semantic-ui-react';

class ServiceDaily extends Component {
  constructor() {
    super();

    this.state = {
      dateFrom: moment().subtract(1, 'week').valueOf(),
      dateTo: moment().valueOf(),
      numTopServices: 5,
      categorySeries: [],
      dataSeries: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({
      loading: false,
      ...this.getData(),
    });
  }

  getData = () => {
    const {
      customers,
      services,
    } = this.props;
    const {
      dateFrom,
      dateTo,
      numTopServices,
    } = this.state;

    const categorySeries = [];
    const dataSeries = {};
    const countServices = {};
    _.forEach(services, (service, serviceKey) => {
      dataSeries[serviceKey] = {
        name: service.name,
        data: [],
      };
      countServices[serviceKey] = 0;
    });

    const to = moment(dateTo);
    const dateCurr = moment(dateFrom);

    while (dateCurr <= to) {
      categorySeries.push(dateCurr.format('MM/DD'));

      const year = dateCurr.year();
      const dayOfYear = dateCurr.dayOfYear();

      _.forEach(services, (service, serviceKey) => {
        let count = 0;
        _.forEach(customers, (customer) => {
          const serviceVisits = _.filter(customer.visits, (visit) => (
            visit.time
            && moment(visit.time).year() === year
            && moment(visit.time).dayOfYear() === dayOfYear
            && _.includes(visit.services, serviceKey)
          ));
          count += serviceVisits.length;
        });
        dataSeries[serviceKey].data.push(count);
        countServices[serviceKey] += count;
      });

      dateCurr.add(1, 'day');
    }

    const topServiceKeys = _.takeRight(_.sortBy(_.keys(countServices), (serviceKey) => countServices[serviceKey]), numTopServices);

    return {
      categorySeries,
      dataSeries: _.filter(dataSeries, (data, serviceKey) => topServiceKeys.includes(serviceKey)),
    };
  }

  isValidChart = () => {
    const {
      dateFrom,
      dateTo,
    } = this.state;
    let result = true;
    if (dateFrom > dateTo) {
      toastr.warning('Date From should smaller than Date To');
      result = false;
    }
    return result;
  }

  renderFilter = () => {
    const {
      dateFrom,
      dateTo,
      numTopServices,
      loading,
    } = this.state;

    return (
      <Form>
        <Form.Group>
          <Form.Field required>
            <label>Date from</label>
            <DatePicker
              selected={dateFrom}
              onChange={(date) => {
                this.setState({
                  dateFrom: date.getTime(),
                });
              }}
            />
          </Form.Field>
          <Form.Field required>
            <label>Date to</label>
            <DatePicker
              selected={dateTo}
              onChange={(date) => {
                this.setState({
                  dateTo: date.getTime(),
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>
              Top
              {' '}
              {numTopServices}
              {' '}
              Services
            </label>
            <Dropdown
              search
              selection
              options={_.range(1, 100).map((num) => ({
                text: num,
                value: num,
              }))}
              value={numTopServices}
              onChange={(e, {
                value,
              }) => {
                this.setState({
                  numTopServices: value,
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <Button
              primary
              style={{
                marginTop: 23,
              }}
              loading={loading}
              disabled={loading}
              onClick={() => {
                if (!this.isValidChart()) {
                  return;
                }
                this.setState({
                  loading: true,
                });
                this.setState({
                  loading: false,
                  ...this.getData(),
                });
              }}
            >
              Search
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }

  renderChart = () => {
    const {
      categorySeries,
      dataSeries,
    } = this.state;

    return (
      <ReactApexChart
        series={dataSeries}
        options={
          {
            chart: {
              type: 'bar',
            },
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent'],
            },
            xaxis: {
              categories: categorySeries,
            },
            yaxis: {
              title: {
                text: 'Number of Services',
              },
            },
            fill: {
              opacity: 1,
            },
            tooltip: {
              y: {
                formatter: (val) => val,
              },
            },
          }
        }
        type="bar"
        height={400}
      />
    );
  }

  render() {
    return (
      <div>
        <Header as="h3">
          Service Daily Statistics
        </Header>
        {this.renderFilter()}
        {this.renderChart()}
      </div>
    );
  }
}

ServiceDaily.propTypes = {
  customers: PropTypes.object.isRequired,
  services: PropTypes.object.isRequired,
};

const mapStateToProps = ({
  shop,
}) => ({
  customers: shop.customers || {},
  services: shop.shopData.services || {},
});

export default connect(mapStateToProps)(ServiceDaily);
