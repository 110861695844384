import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, Menu } from 'semantic-ui-react';

import UserHeader from '../components/UserHeader';
import { screenMounted } from '../redux/actions/screens';
import { fetchShopsHandler } from '../redux/actions/shops';

import Shop from './Shop';
import ShopList from './ShopList';

class Home extends Component {
  static propTypes = {
    screenMounted: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.object,
    }),
  };

  static defaultProps = {
    match: {},
  };

  constructor(props) {
    super(props);

    const { match } = this.props;
    const { shopID } = _.get(match, 'params', {});
    this.state = {
      menuActive: shopID ? 'shop' : 'shopList',
    };
  }

  componentDidMount() {
    const { screenMounted, fetchShopsHandler } = this.props;
    setTimeout(() => screenMounted('home'), 500);
    fetchShopsHandler();
  }

  renderContent = () => {
    const { match, shops } = this.props;
    const { menuActive } = this.state;
    const { shopID } = _.get(match, 'params', {});
    switch (menuActive) {
      case 'shopList':
        return _.size(shops) ? <ShopList /> : null;
      case 'shop':
        return <Shop shopID={shopID} />;
      default:
        return null;
    }
  };

  renderMenu = () => {
    const { match, user } = this.props;
    const { menuActive } = this.state;
    const { shopID } = _.get(match, 'params', {});
    const isAdmin = _.get(user.roles, 'admin');

    // auto redirect from / to /shopID
    if (!isAdmin && !shopID) {
      const userShopID = _.head(_.keys(user.shops));
      if (userShopID) {
        this.props.history.push(`/${userShopID}`);
      }
    }

    return (
      <Menu
        style={{
          color: '#454545',
          background: 'transparent',
        }}
        secondary
        pointing
        // inverted
        icon="labeled"
        color="blue"
      >
        {isAdmin ? (
          <Menu.Item
            name="shopList"
            active={menuActive === 'shopList'}
            as="a"
            href={_.trimEnd(window.location, shopID || '')}
            style={{
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          >
            <Icon name="list alternate outline" />
            Shop List
          </Menu.Item>
        ) : null}
        <Menu.Menu
          position="right"
          style={{
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
        >
          <Menu.Item>
            <UserHeader />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  };

  render() {
    return (
      <div>
        {this.renderMenu()}
        {this.renderContent()}
        <div
          style={{
            height: 50,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ user, shops }) => ({
  user,
  shops,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      screenMounted,
      fetchShopsHandler,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);
