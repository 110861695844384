import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, List, Modal } from 'semantic-ui-react';

import { db } from '../../../libs/firebase';
import { errorHandler, successHandler } from '../../../libs/log';
import Reward from '../Customer/Reward';

import CheckIn from './CheckIn';

class SearchResult extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  signinHandler = (customer) => {
    const { services, signinSetup } = this.props;

    if (!signinSetup.bypassService && _.size(services)) {
      this.setState({
        showCheckIn: customer,
      });
    } else {
      this.checkInHandler([], customer);
    }
  };

  checkInHandler = (checkins, customer) => {
    const { shopID, onClose, onCheckIn } = this.props;
    const checkInCustomer = this.state.showCheckIn || customer;
    const { phone } = checkInCustomer;

    const request = {
      ...checkInCustomer,
      time: new Date().getTime(),
      checkins: _.filter(checkins, 'selected'),
    };
    db.collection('shops')
      .doc(shopID)
      .collection('requests')
      .doc(phone)
      .set(request, {
        mergeFields: _.keys(request),
      })
      .then(() => {
        this.setState(
          {
            showCheckIn: undefined,
          },
          () => {
            onClose();
            onCheckIn();
          }
        );
        successHandler('Done', 'Sign-In Success');
      })
      .catch((error) => {
        errorHandler('Error', error);
      });
  };

  renderCheckIn = () => {
    const { showCheckIn } = this.state;
    if (showCheckIn) {
      return (
        <CheckIn
          checkInHandler={this.checkInHandler}
          closeHandler={() =>
            this.setState({
              showCheckIn: undefined,
            })
          }
          isCustomer
        />
      );
    }
    return null;
  };

  renderReward = () => {
    const { showReward } = this.state;
    if (showReward) {
      return (
        <Reward
          customerID={showReward.id}
          totalPoints={showReward.totalPoints}
          rewards={showReward.rewards}
          closeHandler={() =>
            this.setState({
              showReward: undefined,
            })
          }
          fromSignin
        />
      );
    }
    return null;
  };

  render() {
    const { searchResults, onClose } = this.props;
    return (
      <Modal open closeIcon={<Icon name="close" color="red" size="large" onClick={onClose} />}>
        <Modal.Header>Search Results</Modal.Header>
        <Modal.Content
          style={{
            fontSize: 20,
          }}
        >
          {this.renderCheckIn()}
          {this.renderReward()}
          <List divided relaxed>
            {_.map(searchResults, (customer, key) => {
              const { phone, visits, rewards } = customer;
              const visitPoints = _.sumBy(visits, (visit) => +visit.points);
              const redeemPoints = _.sumBy(rewards, (reward) => +reward.points);
              const totalPoints = visitPoints - redeemPoints;
              return (
                <List.Item key={key}>
                  {phone}
                  &nbsp; &nbsp;
                  <Button
                    style={{
                      fontSize: 20,
                    }}
                    positive
                    onClick={() => this.signinHandler(customer)}
                  >
                    <Icon name="send" />
                    Sign-In
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    style={{
                      fontSize: 20,
                    }}
                    primary
                    onClick={() =>
                      this.setState({
                        showReward: {
                          ...customer,
                          id: phone,
                          totalPoints,
                        },
                      })
                    }
                  >
                    <Icon name="gift" />
                    My Rewards
                  </Button>
                </List.Item>
              );
            })}
          </List>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = ({ shop }) => ({
  shopID: shop.shopID,
  services: shop.shopData.services || {},
  signinSetup: shop.shopData.signinSetup || {},
});

export default connect(mapStateToProps)(SearchResult);
