import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const dev = false; // auto-replace-by-script

const config = dev
  ? {
      apiKey: 'AIzaSyBOvMVA0doqx_mfddX-LS-GymNJvu2eEas',
      authDomain: 'cm-nail2.firebaseapp.com',
      databaseURL: 'https://cm-nail2.firebaseio.com',
      projectId: 'cm-nail2',
      storageBucket: 'cm-nail2.appspot.com',
      messagingSenderId: '754267035959',
      appId: '1:754267035959:web:aa892dbb581c39bcf562b2',
    }
  : {
      apiKey: 'AIzaSyAD9YaY_1FZUAkhALCG7e1eGd11M9HSTng',
      authDomain: 'qss-2020.firebaseapp.com',
      databaseURL: 'https://qss-2020.firebaseio.com',
      projectId: 'qss-2020',
      storageBucket: 'qss-2020.appspot.com',
      messagingSenderId: '425244174738',
      appId: '1:425244174738:web:b6202eba9af06001faf94b',
      measurementId: 'G-4P427W9SFX',
    };

const app = firebase.initializeApp(config);
const db = app.firestore();

const getToken = (onSuccess) => {
  app
    .auth()
    .currentUser.getIdToken(true)
    .then((token) => {
      onSuccess(token);
    });
};

const deleteField = firebase.firestore.FieldValue.delete();

const googleProvider = new firebase.auth.GoogleAuthProvider().setCustomParameters({
  prompt: 'select_account',
});

const facebookProvider = new firebase.auth.FacebookAuthProvider().setCustomParameters({
  display: 'popup',
});

const createUserApp = firebase.initializeApp(config, 'createUserApp');

export { app, db, dev, googleProvider, facebookProvider, deleteField, createUserApp, getToken };
