import * as Sentry from '@sentry/browser';
import _ from 'lodash';
import {
  all,
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import {
  db,
} from '../../libs/firebase';
import {
  errorHandler, successHandler,
} from '../../libs/log';

import {
  USER,
} from './constants';

function* update(data = {}) {
  yield put({
    type: USER.update,
    data,
  });
}

function* loginUser({
  email, displayName, photoURL,
}) {
  if (email) {
    try {
      const userRef = db.collection('users').doc(email);
      const user = yield call([userRef, userRef.get]);

      if (window.location.hostname !== 'localhost') {
        window.FS.identify(user.uid, {
          displayName,
          email,
          reviewsWritten_int: 14,
        });
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: user.uid,
            username: displayName,
            email,
          });
        });
      }

      const userData = {
        ...user.data(),
        email,
        displayName,
        photoURL,
      };
      yield* update(userData);

      const staffRef = db.collection('staffs').doc(email);
      const staff = yield call([staffRef, staffRef.get]);
      yield* update({
        roles: _.get(staff.data(), 'roles'),
      });

      console.log(userData, staff.data());
    } catch (error) {
      errorHandler('Failed to get user info', error);
    }
  } else {
    errorHandler('Failed to get email');
  }
}

function* updateUser({
  data,
}) {
  try {
    const {
      email,
    } = _.get(yield select(), 'user');
    yield call(() => (new Promise((resolve, rejects) => {
      db.collection('users').doc(email)
        .set(data, {
          mergeFields: _.keys(data),
        })
        .then(() => resolve(true))
        .catch((err) => rejects(err));
    })));
    yield* update(data);
    successHandler('Done', 'Update successfully');
  } catch (error) {
    errorHandler('Failed to get email', error);
  }
}

export const updateUserHandler = (data) => ({
  type: USER.handlers.update,
  data,
});

export default function* userSaga() {
  yield all([
    yield takeEvery(USER.handlers.login, loginUser),
    yield takeEvery(USER.handlers.update, updateUser),
  ]);
}
