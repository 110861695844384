import {
  Editor,
} from '@tinymce/tinymce-react';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';
import React, {
  Component,
} from 'react';

export default class WordEditor extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    readonly: PropTypes.bool,
    height: PropTypes.number,
  };

  static defaultProps = {
    readonly: false,
    onChange: () => { },
    height: 200,
  };

  render() {
    const {
      id,
      data,
      readonly,
      onChange,
      height,
    } = this.props;
    if (readonly) { return Parser(data); }

    return (
      <Editor
        key={id}
        value={data}
        onEditorChange={(value) => onChange(value)}
        init={{
          plugins: 'autoresize colorpicker media contextmenu link paste save textcolor autolink fullscreen image insertdatetime lists preview searchreplace table',
          menubar: 'edit insert format table',
          toolbar: [
            'undo redo | cut copy paste | styleselect | bold italic | alignleft aligncenter alignright | link image',
            'bullist numlist | indent outdent | table tablecellprops | fullscreen',
          ],
          statusbar: false,
          height,
        }}
      />
    );
  }
}
