import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {
  Component,
} from 'react';
import {
  connect,
} from 'react-redux';
import {
  Dropdown,
  Feed,
} from 'semantic-ui-react';

import {
  app,
} from '../../libs/firebase';
import {
  SIGN_OUT,
} from '../../redux/actions/constants';

class UserHeader extends Component {
  static propTypes = {
    signOut: PropTypes.func.isRequired,
    user: PropTypes.shape({
      email: PropTypes.string.isRequired,
      displayName: PropTypes.string,
      photoURL: PropTypes.string,
    }).isRequired,
  };

  logoutHandler = () => {
    const {
      signOut,
    } = this.props;
    app.auth().signOut().then(() => {
      signOut();
    });
  }

  render() {
    const {
      user, members,
    } = this.props;
    const {
      email, displayName, photoURL,
    } = user;

    const isStaff = _.get(members, [email, 'role']) === 'staff';

    return (
      <Feed style={{
        float: 'right', paddingTop: 10,
      }}
      >
        <Feed.Event style={{
          width: 'auto', cursor: 'pointer',
        }}
        >
          {isStaff ? null : <Feed.Label image={photoURL} />}
          <Feed.Content style={{
            marinLeft: 5,
          }}
          >
            <Feed.Date>
              <Dropdown text={isStaff ? 'Logout' : email}>
                <Dropdown.Menu style={{
                  width: '100%',
                }}
                >
                  <Dropdown.Item text="Logout" onClick={this.logoutHandler} />
                </Dropdown.Menu>
              </Dropdown>
            </Feed.Date>
            {isStaff ? null : (
              <Feed.Summary style={{
                color: 'rgba(0, 0, 0, 0.61)',
              }}
              >
                {displayName}
              </Feed.Summary>
            )}
          </Feed.Content>
        </Feed.Event>
      </Feed>
    );
  }
}

const mapStateToProps = ({
  user, shop,
}) => ({
  user,
  members: shop.shopData.members || {},
});

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch({
    type: SIGN_OUT,
  }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserHeader);
