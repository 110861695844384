import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import ReactTable from 'react-table';
import { bindActionCreators } from 'redux';
import { Button, Checkbox, Dropdown, Form, Header, Icon, Modal, Popup } from 'semantic-ui-react';
import XLSX from 'xlsx';

import { RESOURCES } from '../../../libs/config';
import {
  defaultSms,
  exportToExcel,
  formatPhone,
  isBirthdayValid,
  isEnableSms,
  isPhoneValid,
  reactTableProps,
  sendSms,
} from '../../../libs/utils';
import {
  deleteRequestHandler,
  handleMergeCustomers,
  mergeGroupsHandler,
  updateCustomersHandler,
  updateShopHandler,
} from '../../../redux/actions/shop';

import Reward from './Reward';
import SigninList from './SigninList';
import Visit from './Visit';

class Customer extends Component {
  static propTypes = {
    services: PropTypes.object,
    customers: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      selectedAll: false,
      bulkAddGroups: [],
    };
    this.page = 0;
    this.pageSize = reactTableProps.defaultPageSize;
  }

  sendSMS = (phone, firstName, visits, rewards, onDone) => {
    const { shopID, rewardSetup, smsSetup, smsNumber } = this.props;
    const visitPoints = _.sumBy(visits, (visit) => +visit.points);
    const redeemPoints = _.sumBy(rewards, (reward) => +reward.points);
    const totalPoints = visitPoints - redeemPoints;
    const count = _.toInteger(totalPoints / rewardSetup.points);
    const redeemUsd = count * rewardSetup.usd;

    sendSms(
      {
        content: _.cloneDeep(smsSetup.template || defaultSms()),
        mediaUrl: smsSetup.imageUrls,
        shopID,
        phone,
        firstName,
        totalPoints,
        redeemUsd,
        smsNumber,
      },
      onDone
    );
  };

  cancelHandler = (phone) => {
    const { deleteRequestHandler } = this.props;
    deleteRequestHandler(phone);
  };

  checkOutHandler = (phone, request) => {
    const { enableSms, customers, smsSetup, updateCustomersHandler } = this.props;
    const { firstName, lastName, email, birthday, visit, enableSms: requestEnableSms } = request;
    const customer = {
      ...customers[phone],
      firstName,
      ...(lastName && {
        lastName,
      }),
      phone,
      ...(email && {
        email,
      }),
      ...(birthday && {
        birthday,
      }),
      visits: [
        ..._.get(customers[phone], 'visits', []),
        {
          ...visit,
          timeCheckOut: new Date().getTime(),
        },
      ],
    };

    const onDone = () => updateCustomersHandler(phone, customer, () => this.cancelHandler(phone));

    const { visits, rewards } = customer;

    if (isEnableSms(enableSms, smsSetup.enableSms, customer.enableSms, requestEnableSms)) {
      this.sendSMS(phone, firstName, visits, rewards, onDone);
    } else {
      onDone();
    }
  };

  editHandler = (field, value) => {
    const { editor } = this.state;
    this.setState({
      editor: {
        ...editor,
        [field]: value,
      },
    });
  };

  validateHandler = () => {
    const { editor } = this.state;
    const requiredFields = [
      {
        field: editor.firstName,
        text: 'Your first name',
      },
      {
        field: editor.phone,
        text: 'Your phone',
      },
    ];
    let result = true;
    _.forEach(requiredFields, (item) => {
      if (result && !item.field) {
        result = false;
        toastr.warning(`${item.text} is required!`);
      }
    });
    if (!isPhoneValid(editor.phone)) {
      result = false;
      toastr.warning(
        'Phone should be in one of these formats: 123-456-7890, 1234567890, (123) 456-7890 and have 10 numbers only.'
      );
    }
    if (editor.birthdayRaw && !isBirthdayValid(editor.birthdayRaw)) {
      result = false;
      toastr.warning('Invalid month or day');
    }
    return result;
  };

  saveHandler = () => {
    if (!this.validateHandler()) {
      return;
    }

    const { mergeGroupsHandler, updateCustomersHandler, groups } = this.props;
    const { editor } = this.state;
    const newCustomer = _.cloneDeep(editor);

    if (editor.birthdayRaw) {
      newCustomer.birthday = moment(`2000/${editor.birthdayRaw}`, 'YYYY/MM/DD', true).valueOf();
    }

    const phone = formatPhone(editor.id || editor.phone);

    updateCustomersHandler(phone, newCustomer, () => {
      this.setState({
        editor: undefined,
      });
    });

    const newGroups = {};
    _.forEach(editor.groups, (groupId) => {
      const groupCustomers = _.get(groups[groupId], 'customers', []);
      if (!_.includes(groupCustomers, phone)) {
        newGroups[groupId] = {
          ...groups[groupId],
          customers: [...groupCustomers, phone],
        };
      }
    });
    mergeGroupsHandler(newGroups);
  };

  removeHandler = () => {
    const { updateCustomersHandler } = this.props;
    const { editor } = this.state;
    updateCustomersHandler(editor.id, 'deleted', () => {
      this.setState({
        editor: undefined,
      });
    });
  };

  bulkRemoveHandler = () => {
    const { handleMergeCustomers } = this.props;
    const { selected } = this.state;

    if (_.size(selected)) {
      const removeCustomers = {};
      _.forEach(selected, (phone) => {
        removeCustomers[phone] = 'deleted';
      });
      handleMergeCustomers(removeCustomers);
    } else {
      toastr.warning('Warning', 'Select customers to delele');
    }
  };

  parseExcelData = (excelData) => {
    const rawExcels = [];
    _.forEach(excelData, (row, index) => {
      if (index > 0) {
        rawExcels.push({
          rowIndex: index,
          firstName: _.trim(row.A),
          lastName: _.trim(row.B),
          birthday: _.trim(row.C),
          phone: _.trim(row.D),
          email: _.trim(row.E),
          points: _.trim(row.H),
          visitPoints: _.trim(row.I),
          rewardValue: _.trim(row.J),
          lastVisit: _.trim(row.L),
        });
      }
    });
    return rawExcels;
  };

  checkRow = (rawRow) => {
    let result = true;
    if (!rawRow.firstName || !rawRow.phone) {
      result = false;
    }
    if (!isPhoneValid(rawRow.phone)) {
      result = false;
    }
    return result;
  };

  parseRawCustomers = (successRows) => {
    const customers = {};
    _.forEach(successRows, (successRow) => {
      const visits = [];
      const rewards = [];
      visits.push({
        ...(successRow.lastVisit && {
          time: new Date(`${successRow.lastVisit}`).getTime(),
        }),
        points: successRow.visitPoints,
      });

      if (successRow.rewardValue > 0 || successRow.visitPoints > successRow.points) {
        rewards.push({
          ...(successRow.lastVisit && {
            time: new Date(`${successRow.lastVisit}`).getTime(),
          }),
          usd: successRow.rewardValue || 0,
          points: successRow.visitPoints - successRow.points,
        });
      }

      const phone = formatPhone(successRow.phone);

      customers[phone] = {
        firstName: successRow.firstName,
        lastName: successRow.lastName,
        birthday: successRow.birthday && moment(successRow.birthday).valueOf(),
        phone,
        email: successRow.email,
        visits,
        rewards,
      };
    });
    return customers;
  };

  handleImportSheet = (excelData) => {
    const { handleMergeCustomers: handler } = this.props;
    const rawRows = this.parseExcelData(excelData);
    const [successRows, errorRows] = _.partition(rawRows, this.checkRow);
    if (_.size(errorRows) > 0) {
      console.log(errorRows);
      toastr.error(
        'Error',
        `${_.size(errorRows)} customers have invalid information (at row: ${_.join(
          _.map(errorRows, (errorRow) => errorRow.rowIndex + 1),
          ','
        )}).
        Please make sure phone is in correct format`,
        {
          timeOut: 0,
          closeOnToastrClick: false,
        }
      );
      return;
    }

    const successCustomers = this.parseRawCustomers(successRows);
    const customers = _.map(successCustomers, (customer, phone) => ({
      ...customer,
      phone,
    }));
    toastr.info('Info', `Importing ${_.size(customers)} customers`);
    _.forEach(_.chunk(customers, 500), (chunk) => {
      handler(chunk);
    });
  };

  handleImport = (files) => {
    const reader = new FileReader();
    reader.onload = () => {
      const wb = XLSX.read(reader.result, {
        type: 'binary',
        cellDates: true,
      });
      const excelData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
        header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'],
        skipHeader: true,
        blankrows: false,
      });
      this.handleImportSheet(excelData);
    };
    reader.readAsBinaryString(files[0]);
  };

  bulkAddGroupHandler = () => {
    const { groups, mergeGroupsHandler } = this.props;
    const { selected, bulkAddGroups } = this.state;
    if (!_.size(selected)) {
      toastr.warning('Warning', 'Select customers to bulk add to group');
      return;
    }

    const newGroups = {};
    _.forEach(bulkAddGroups, (groupId) => {
      newGroups[groupId] = {
        ...groups[groupId],
        customers: _.uniq([..._.get(groups[groupId], 'customers', []), ...selected]),
      };
    });
    mergeGroupsHandler(newGroups, () => {
      this.setState({
        bulkAddGroups: [],
      });
    });
  };

  renderBulkAddGroup = () => {
    const { handling, groups } = this.props;
    const { bulkAddGroups } = this.state;
    const groupOptions = _.map(groups, (group, groupId) => ({
      key: groupId,
      value: groupId,
      text: group.name,
    }));

    return (
      <Popup
        trigger={
          <Button primary loading={handling}>
            <Icon name="group" />
            Bulk Add to Group
          </Button>
        }
        hoverable
      >
        <Form>
          <Form.Field inline>
            <label>Select Groups</label>
            <Button primary loading={handling} onClick={this.bulkAddGroupHandler}>
              <Icon name="group" />
              Bulk Add
            </Button>
            <Dropdown
              clearable
              search
              selection
              multiple
              options={groupOptions}
              value={bulkAddGroups}
              onChange={(e, { value }) =>
                this.setState({
                  bulkAddGroups: value,
                })
              }
            />
          </Form.Field>
        </Form>
      </Popup>
    );
  };

  renderEditor = () => {
    const { groups, smsSetup, enableSms } = this.props;
    const { editor } = this.state;
    if (editor) {
      const { id } = editor;
      const groupOptions = _.map(groups, (group, groupId) => ({
        key: groupId,
        value: groupId,
        text: group.name,
      }));

      const defaultEnableSms = isEnableSms(enableSms, smsSetup.enableSms);

      return (
        <Modal
          size="large"
          open
          onClose={() =>
            this.setState({
              editor: undefined,
            })
          }
          closeIcon={
            <Icon
              name="close"
              color="red"
              size="large"
              onClick={() =>
                this.setState({
                  editor: undefined,
                })
              }
            />
          }
        >
          <Modal.Header>{id ? 'Edit Customer' : 'Add Customer'}</Modal.Header>
          <Modal.Content>
            <Form
              style={{
                padding: 10,
              }}
            >
              <Form.Group widths="equal">
                <Form.Input
                  label="#"
                  type="number"
                  placeholder="#"
                  value={_.get(editor, 'order', '')}
                  onChange={(e, { value }) => this.editHandler('order', value)}
                />
                <Form.Input
                  required
                  label="First Name"
                  placeholder="First Name"
                  value={_.get(editor, 'firstName', '')}
                  onChange={(e, { value }) => this.editHandler('firstName', value)}
                />
                <Form.Input
                  label="Last Name"
                  placeholder="Last Name"
                  value={_.get(editor, 'lastName', '')}
                  onChange={(e, { value }) => this.editHandler('lastName', value)}
                />
                <Form.Field>
                  <label>Enable SMS</label>
                  <Checkbox
                    disabled={!defaultEnableSms}
                    toggle
                    checked={
                      !defaultEnableSms ? false : _.get(editor, 'enableSms', defaultEnableSms)
                    }
                    onChange={(e, { checked }) => this.editHandler('enableSms', checked)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  required
                  label="Phone"
                  placeholder="Phone"
                  value={_.get(editor, 'phone', '')}
                  onChange={(e, { value }) => this.editHandler('phone', value)}
                />
                <Form.Input
                  label="Email"
                  placeholder="Email"
                  value={_.get(editor, 'email', '')}
                  onChange={(e, { value }) => this.editHandler('email', value)}
                />
                <Form.Field>
                  <label>Birthday</label>
                  <Form.Input
                    placeholder="MM/DD"
                    value={editor.birthdayRaw || ''}
                    onChange={(e, { value }) => this.editHandler('birthdayRaw', value)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Groups</label>
                  <Dropdown
                    clearable
                    search
                    selection
                    multiple
                    options={groupOptions}
                    value={editor.groups || []}
                    onChange={(e, { value }) => this.editHandler('groups', value)}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            {id ? (
              <Button negative onClick={this.removeHandler}>
                <Icon name="trash" />
                Delete
              </Button>
            ) : null}
            <Button primary onClick={this.saveHandler}>
              <Icon name="save" />
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }
    return null;
  };

  renderCustomers = () => {
    const { customers, rewardSetup, timeZone } = this.props;
    const { selected, selectedAll } = this.state;

    const columns = [
      {
        width: 30,
        Header: ({ data }) => (
          <Checkbox
            checked={selectedAll}
            onChange={() => {
              if (selectedAll) {
                this.setState({
                  selected: [],
                  selectedAll: false,
                });
              } else {
                this.setState({
                  selected: _.slice(
                    data.map((row) => row._original.id),
                    this.pageSize * this.page,
                    this.pageSize * (this.page + 1)
                  ),
                  selectedAll: true,
                });
              }
            }}
          />
        ),
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
          margin: 'auto',
        },
        Cell: (row) => {
          const { id } = row.original;
          const checked = _.includes(selected, id);
          return (
            <Checkbox
              checked={checked}
              onChange={() => {
                if (checked) {
                  _.remove(selected, (itemId) => itemId === id);
                } else {
                  selected.push(id);
                }
                this.setState({
                  selected,
                });
              }}
            />
          );
        },
        filterable: false,
        sortable: false,
      },
      {
        id: 'order',
        Header: '#',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
          margin: 'auto',
        },
        maxWidth: 50,
        Cell: (row) => <div>{row.value || row.index + 1}</div>,
      },
      {
        id: 'firstName',
        accessor: 'firstName',
        Header: 'First Name',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
          margin: 'auto',
        },
        Cell: (row) => <div>{row.value}</div>,
      },
      {
        id: 'lastName',
        accessor: 'lastName',
        Header: 'Last Name',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
          margin: 'auto',
        },
        Cell: (row) => <div>{row.value}</div>,
      },
      {
        id: 'phone',
        accessor: 'phone',
        Header: 'Phone',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
          margin: 'auto',
        },
        Cell: (row) => <div>{formatPhone(row.value)}</div>,
      },
      {
        id: 'email',
        accessor: 'email',
        Header: 'Email',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
          margin: 'auto',
        },
        Cell: (row) => <div>{row.value}</div>,
      },
      {
        id: 'birthday',
        accessor: (row) => row.birthday && moment(row.birthday).format('MM/DD'),
        Header: 'Birth day',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
          margin: 'auto',
        },
        maxWidth: 60,
        Cell: (row) => <div>{row.value}</div>,
      },
      {
        id: 'point',
        accessor: (row) => {
          const visitPoints = _.sumBy(row.visits, (visit) => +visit.points);
          const redeemPoints = _.sumBy(row.rewards, (reward) => +reward.points);
          const totalPoints = visitPoints - redeemPoints;
          return totalPoints % rewardSetup.points;
        },
        Header: 'Current Points',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
          margin: 'auto',
        },
        maxWidth: 60,
        Cell: (row) => <div>{row.value}</div>,
      },
      {
        id: 'lifetime',
        accessor: (row) => _.sumBy(row.visits, (visit) => +visit.points),
        Header: 'Lifetime Points',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
          margin: 'auto',
        },
        maxWidth: 65,
        Cell: (row) => <div>{row.value}</div>,
      },
      {
        id: 'rewards',
        accessor: (row) => {
          const visitPoints = _.sumBy(row.visits, (visit) => +visit.points);
          const redeemPoints = _.sumBy(row.rewards, (reward) => +reward.points);
          const totalPoints = visitPoints - redeemPoints;
          const count = _.toInteger(totalPoints / rewardSetup.points);
          const redeemUsd = count * rewardSetup.usd;
          return redeemUsd;
        },
        Header: 'Reward',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
          margin: 'auto',
        },
        maxWidth: 60,
        Cell: (row) => (
          <div>
            {row.value}
            <br />
            <Button
              primary
              onClick={() =>
                this.setState({
                  showReward: row.original.id,
                })
              }
              icon="gift"
            />
          </div>
        ),
      },
      {
        id: 'visitLast',
        accessor: (row) => {
          const lastVisit = _.size(row.visits) && _.last(row.visits).time;
          return lastVisit ? new Date(lastVisit).getTime() : 0;
        },
        Header: 'Last visit',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
          margin: 'auto',
        },
        maxWidth: 70,
        Cell: (row) => (
          <div>
            {_.size(row.original.visits) && _.last(row.original.visits).time
              ? moment.tz(_.last(row.original.visits).time, timeZone).format('MM/DD/YY')
              : ''}
          </div>
        ),
      },
      {
        id: 'visits',
        accessor: (row) => _.size(row.visits),
        Header: '# visits',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
          margin: 'auto',
        },
        maxWidth: 70,
        Cell: (row) => (
          <Button
            primary
            onClick={() =>
              this.setState({
                showVisit: row.original,
              })
            }
          >
            {_.size(row.original.visits)}
          </Button>
        ),
      },
      {
        id: 'edit',
        Header: '',
        headerClassName: 'tableHeader',
        maxWidth: 50,
        style: {
          textAlign: 'center',
          margin: 'auto',
        },
        Cell: (row) => (
          <div>
            Edit
            <br />
            <Button
              icon="edit"
              primary
              onClick={() =>
                this.setState({
                  editor: {
                    ...row.original,
                    ...(row.original.birthday && {
                      birthdayRaw: moment(row.original.birthday).format('MM/DD'),
                    }),
                  },
                })
              }
            />
          </div>
        ),
        filterable: false,
      },
    ];

    return (
      <ReactTable
        {...reactTableProps}
        onPageChange={(page) => {
          this.page = page;
          this.setState({
            selected: [],
            selectedAll: false,
          });
        }}
        onPageSizeChange={(pageSize) => {
          this.pageSize = pageSize;
          this.setState({
            selected: [],
            selectedAll: false,
          });
        }}
        data={_.map(customers, (customer, id) => ({
          ...customer,
          id,
        }))}
        columns={columns}
      />
    );
  };

  renderSigninList = (inServiceRequests, signInRequests) => {
    const { showSigninList } = this.state;
    if (showSigninList) {
      return (
        <SigninList
          checkOutHandler={this.checkOutHandler}
          cancelHandler={this.cancelHandler}
          closeHandler={() =>
            this.setState({
              showSigninList: undefined,
            })
          }
          {...showSigninList}
          requests={showSigninList.type === 'in-service' ? inServiceRequests : signInRequests}
        />
      );
    }
    return null;
  };

  renderVisit = () => {
    const { showVisit } = this.state;
    if (showVisit) {
      return (
        <Visit
          customerID={showVisit.id}
          visits={showVisit.visits}
          closeHandler={() =>
            this.setState({
              showVisit: undefined,
            })
          }
        />
      );
    }
    return null;
  };

  renderReward = () => {
    const { customers } = this.props;
    const { showReward } = this.state;
    if (showReward) {
      const customer = customers[showReward];
      const visitPoints = _.sumBy(customer.visits, (visit) => +visit.points);
      const redeemPoints = _.sumBy(customer.rewards, (reward) => +reward.points);
      const totalPoints = visitPoints - redeemPoints;
      return (
        <Reward
          customerID={showReward}
          totalPoints={totalPoints}
          rewards={customer.rewards}
          closeHandler={() =>
            this.setState({
              showReward: undefined,
            })
          }
        />
      );
    }
    return null;
  };

  render() {
    const { handling, requests, customers, user } = this.props;
    const [inServiceRequests, signInRequests] = _.partition(
      requests,
      (request) => request.inService
    );
    const isAdmin = _.get(user.roles, 'admin');

    return (
      <div>
        {this.renderSigninList(inServiceRequests, signInRequests)}
        {this.renderEditor()}
        {this.renderVisit()}
        {this.renderReward()}

        <div align="center">
          <Button
            color="orange"
            style={{
              padding: 20,
              paddingLeft: 50,
              paddingRight: 50,
              margin: 10,
            }}
            loading={handling}
            onClick={() =>
              this.setState({
                showSigninList: {
                  title: 'Sign-in List',
                  type: 'new',
                },
              })
            }
          >
            <Icon name="list" />
            Sign-in List ({signInRequests.length})
          </Button>
          <Button
            color="pink"
            style={{
              padding: 20,
              paddingLeft: 50,
              paddingRight: 50,
              margin: 10,
            }}
            loading={handling}
            onClick={() =>
              this.setState({
                showSigninList: {
                  title: 'In-Service List',
                  type: 'in-service',
                },
              })
            }
          >
            <Icon name="list" />
            In-Service List ({inServiceRequests.length})
          </Button>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 20,
          }}
        >
          <Header as="h3">Customer ({_.size(customers)})</Header>
          <div
            style={{
              marginBottom: 5,
            }}
          >
            <Button
              as="a"
              href={`${RESOURCES}/Template-Customer.xlsx`}
              target="_blank"
              download
              loading={handling}
            >
              <Icon name="download" />
              Download Template
            </Button>
            <Button positive loading={handling}>
              <Dropzone
                style={{
                  margin: 0,
                }}
                accept=".xls, .xlsx"
                multiple={false}
                onDrop={this.handleImport}
              >
                <Icon name="file excel outline" />
                Import excel
              </Dropzone>
            </Button>
            <Button
              positive
              loading={handling}
              onClick={() =>
                this.setState({
                  editor: {},
                })
              }
            >
              <Icon name="add" />
              Add Customer
            </Button>
            <Button negative loading={handling} onClick={this.bulkRemoveHandler}>
              <Icon name="close" />
              Bulk Delete
            </Button>
            {this.renderBulkAddGroup()}
            {isAdmin && (
              <Button
                onClick={() => {
                  const data = [];
                  _.forEach(customers, (customer, phone) => {
                    const lastVisit = _.last(customer.visits);
                    const totalPoints = _.sumBy(customer.visits, 'points');
                    data.push({
                      FirstName: customer.firstName,
                      LastName: customer.lastName,
                      DOB: moment(customer.birthday).format('MM/DD'),
                      Phone: phone,
                      Email: customer.email,
                      LastUpdated: '',
                      VIP: '',
                      Point: totalPoints - _.sumBy(customer.rewards, 'points'),
                      TotalPoint: totalPoints,
                      RewardValue: _.sumBy(customer.rewards, 'usd'),
                      LastRating: '',
                      LastVisit: lastVisit ? moment(lastVisit.time).format('YYYY/MM/DD') : '',
                    });
                  });
                  exportToExcel(data);
                }}
              >
                <Icon name="file excel outline" />
                Export excel
              </Button>
            )}
          </div>
        </div>
        {this.renderCustomers()}
      </div>
    );
  }
}

const mapStateToProps = ({ user, shop }) => ({
  user,
  handling: shop.handling,
  shopID: shop.shopID,
  requests: shop.requests || {},
  customers: shop.customers || {},
  groups: shop.groups || {},
  services: shop.shopData.services || {},
  rewardSetup: shop.shopData.rewardSetup || {
    usd: 5,
    points: 200,
  },
  smsSetup: shop.shopData.smsSetup || {},
  smsNumber: shop.shopData.smsNumber,
  timeZone: shop.shopData.timeZone || '',
  enableSms: shop.shopData.enableSms,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteRequestHandler,
      mergeGroupsHandler,
      updateCustomersHandler,
      handleMergeCustomers,
      updateShopHandler,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
