import _ from 'lodash';
import moment from 'moment';
import React, {
  useEffect,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import ReactTable from 'react-table';
import {
  Rating,
} from 'semantic-ui-react';

import {
  formatPhone,
  getTextTimeZone,
  reactTableProps,
} from '../../../libs/utils';
import {
  REVIEW,
} from '../../../redux/actions/constants';

import ReviewGraph from './ReviewGraph';

export default function Review() {
  const {
    shopID,
    customers,
    reviews,
    timeZone,
  } = useSelector(({
    shop,
  }) => ({
    shopID: shop.shopID,
    customers: shop.customers,
    reviews: shop.reviews,
    timeZone: shop.shopData.timeZone,
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    if (shopID) {
      dispatch({
        type: REVIEW.handlers.fetch,
        shopID,
      });
    }
  }, [shopID]);

  const data = _.flatMap(reviews, (reviewItems, phone) => _.map(reviewItems, (reviewItem) => ({
    ...customers[phone],
    ...reviewItem,
    phone,
  })));

  const renderReviews = () => {
    const columns = [
      {
        id: 'order',
        Header: '#',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center', margin: 'auto',
        },
        maxWidth: 50,
        Cell: (row) => <div>{row.index + 1}</div>,
      },
      {
        id: 'time',
        accessor: 'time',
        Header: 'Date',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center', margin: 'auto',
        },
        Cell: (row) => (
          <div>
            {moment.tz(row.value.toDate(), timeZone).format('MM/DD/YYYY hh:mm')}
            {` ${getTextTimeZone(timeZone)}`}
          </div>
        ),
      },
      {
        id: 'firstName',
        accessor: 'firstName',
        Header: 'First Name',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center', margin: 'auto',
        },
        Cell: (row) => <div>{row.value}</div>,
      },
      {
        id: 'lastName',
        accessor: 'lastName',
        Header: 'Last Name',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center', margin: 'auto',
        },
        Cell: (row) => <div>{row.value}</div>,
      },
      {
        id: 'phone',
        accessor: 'phone',
        Header: 'Phone',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center', margin: 'auto',
        },
        Cell: (row) => <div>{formatPhone(row.value)}</div>,
      },
      {
        id: 'rating',
        accessor: 'rating',
        Header: 'Rating',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center', margin: 'auto',
        },
        Cell: (row) => (
          <div>
            <Rating
              disabled
              icon="star" maxRating={5} size="huge"
              rating={row.value}
            />
          </div>
        ),
      },
      {
        id: 'comment',
        accessor: 'comment',
        Header: 'Comment',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
          margin: 'auto',
          whiteSpace: 'pre-wrap',
        },
        Cell: (row) => <div>{row.value}</div>,
      },
    ];

    return (
      <ReactTable
        {...reactTableProps}
        data={data}
        columns={columns}
      />
    );
  };

  return (
    <div>
      <ReviewGraph data={data} />
      {renderReviews()}
    </div>
  );
}
