export const SIGN_OUT = 'SIGN_OUT';

export const SCREEN = {
  mounted: 'SCREEN_MOUNTED',
};

export const USER = {
  handlers: {
    login: 'USER_LOGIN_HANDLER',
    update: 'USER_UPDATE_HANDLER',
  },
  update: 'USER_UPDATE',
};

export const SHOPS = {
  handlers: {
    fetch: 'SHOPS_FETCH_HANDLER',
    update: 'SHOPS_UPDATE_HANDLER',
    remove: 'SHOPS_REMOVE_HANDLER',
  },
  fetch: 'SHOPS_FETCH',
  update: 'SHOPS_UPDATE',
};

export const SHOP = {
  handlers: {
    fetch: 'SHOP_FETCH_HANDLER',
    update: 'SHOP_UPDATE_HANDLER',
    updateGroups: 'GROUPS_UPDATE_HANDLER',
    mergeGroups: 'GROUPS_MERGE_HANDLER',
    updateCustomers: 'CUSTOMERS_UPDATE_HANDLER',
    importCustomers: 'CUSTOMERS_IMPORT_HANDLER',
    deleteRequest: 'REQUEST_DELETE_HANDLER',
  },
  update: 'SHOP_UPDATE',
};

export const REVIEW = {
  handlers: {
    fetch: 'REVIEW_FETCH_HANDLER',
    update: 'REVIEW_UPDATE_HANDLER',
  },
  update: 'REVIEW_UPDATE',
};
