import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Header, Icon, Message, Modal } from 'semantic-ui-react';

export default class ConfirmModal extends Component {
  static propTypes = {
    closeHandler: PropTypes.func.isRequired,
    confirmHandler: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    changes: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        important: PropTypes.bool,
      })
    ),
    positiveNext: PropTypes.bool,
  };

  static defaultProps = {
    positiveNext: false,
    changes: undefined,
  };

  render() {
    const { closeHandler, confirmHandler, title, content, positiveNext, changes } = this.props;
    return (
      <Modal
        className="custom"
        open
        size="small"
        closeIcon={<Icon name="close" color="red" size="large" onClick={closeHandler} />}
      >
        <Header className="form-header" as="h3">
          <Icon name="question" />
          {title}
        </Header>
        <Modal.Content>
          {content}
          {changes ? (
            <Message
              color="teal"
              style={{
                marginTop: 10,
              }}
            >
              <Message.Header>The changes just made :</Message.Header>
              <Message.List>
                {_.map(changes, (changed, index) => (
                  <Message.Item
                    key={index}
                    style={
                      changed.important
                        ? {
                            color: 'red',
                          }
                        : {}
                    }
                  >
                    {_.get(changed, 'content', '')}
                  </Message.Item>
                ))}
              </Message.List>
            </Message>
          ) : null}
        </Modal.Content>
        <Modal.Actions>
          <Button positive={!positiveNext} negative={positiveNext} onClick={closeHandler}>
            NO
          </Button>
          <Button positive={positiveNext} negative={!positiveNext} onClick={confirmHandler}>
            YES
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
