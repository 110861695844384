import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  Component,
} from 'react';
import ReactApexChart from 'react-apexcharts';
import DatePicker from 'react-datepicker';
import {
  connect,
} from 'react-redux';
import {
  toastr,
} from 'react-redux-toastr';
import {
  Button,
  Form, Header,
} from 'semantic-ui-react';

import {
  post,
} from '../../../libs/api';

class SMSMonthly extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateFrom: moment().subtract(3, 'month').startOf('month').valueOf(),
      dateTo: moment().endOf('month').valueOf(),
      categorySeries: [],
      dataSeries: [],
      loading: true,
    };
  }

  async componentDidMount() {
    const data = await this.getData();
    this.setState({
      loading: false,
      ...data,
    });
  }

  getSmsMonth = async (shopID, month) => {
    try {
      const res = await post('/smsMonth', {
        shopID,
        month,
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
    return 0;
  }

  getData = async () => {
    const {
      shopID,
    } = this.props;
    const {
      dateFrom,
      dateTo,
    } = this.state;

    const categorySeries = [];
    const dataSeries = [];

    const to = moment(dateTo);
    const dateCurr = moment(dateFrom);

    while (dateCurr <= to) {
      const year = dateCurr.year();
      const month = dateCurr.month();

      categorySeries.push(`${month + 1}/${year}`);

      const count = await this.getSmsMonth(shopID, month);

      dataSeries.push(count);
      dateCurr.add(1, 'month');
    }

    return {
      categorySeries,
      dataSeries,
    };
  }

  isValidChart = () => {
    const {
      dateFrom,
      dateTo,
    } = this.state;
    let result = true;
    if (dateFrom > dateTo) {
      toastr.warning('Date From should smaller than Date To');
      result = false;
    }
    return result;
  }

  renderFilter = () => {
    const {
      dateFrom,
      dateTo,
      loading,
    } = this.state;

    return (
      <Form>
        <Form.Group inline>
          <Form.Field required>
            <label>Date from</label>
            <DatePicker
              selected={dateFrom}
              onChange={(date) => {
                this.setState({
                  dateFrom: moment(date).startOf('month').valueOf(),
                });
              }}
            />
          </Form.Field>
          <Form.Field required>
            <label>Date to</label>
            <DatePicker
              selected={dateTo}
              onChange={(date) => {
                this.setState({
                  dateTo: moment(date).endOf('month').valueOf(),
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <Button
              primary
              style={{
                marginTop: 23,
              }}
              loading={loading}
              disabled={loading}
              onClick={async () => {
                if (!this.isValidChart()) {
                  return;
                }
                this.setState({
                  loading: true,
                });
                const data = await this.getData();
                this.setState({
                  loading: false,
                  ...data,
                });
              }}
            >
              Search
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }

  renderChart = () => {
    const {
      categorySeries,
      dataSeries,
    } = this.state;

    return (
      <ReactApexChart
        series={
          [
            {
              name: 'Total',
              data: dataSeries,
            },
          ]
        }
        options={
          {
            chart: {
              type: 'bar',
            },
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent'],
            },
            xaxis: {
              categories: categorySeries,
            },
            yaxis: {
              title: {
                text: 'Number of SMS',
              },
            },
            fill: {
              opacity: 1,
            },
            tooltip: {
              y: {
                formatter: (val) => val,
              },
            },
          }
        }
        type="bar"
        height={400}
      />
    );
  }

  render() {
    return (
      <div>
        <Header as="h3">
          SMS Monthly Statistics
        </Header>
        {this.renderFilter()}
        {this.renderChart()}
      </div>
    );
  }
}

SMSMonthly.propTypes = {
  shopID: PropTypes.string.isRequired,
};

const mapStateToProps = ({
  shop,
}) => ({
  shopID: shop.shopID,
});

export default connect(mapStateToProps)(SMSMonthly);
