import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { Menu, Segment } from 'semantic-ui-react';

import { db } from '../../libs/firebase';
import { formatPhone } from '../../libs/utils';
import { SHOP } from '../../redux/actions/constants';

import Customer from './Customer';
import Dashboard from './Dashboard';
import Group from './Group';
import Management from './Management';
import Request from './Request';
import Review from './Review';
import Service from './Service';
import Tech from './Tech';

class Shop extends Component {
  static propTypes = {
    fetchShopHandler: PropTypes.func.isRequired,
    shopID: PropTypes.string.isRequired,
    user: PropTypes.shape({
      roles: PropTypes.object,
      shops: PropTypes.object,
    }).isRequired,
    members: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      menuActive: 'signin',
    };
  }

  componentDidMount() {
    const { timeZone, shopID, fetchShopHandler } = this.props;
    ReactGA.ga('send', 'pageview', `/${shopID}`);

    fetchShopHandler(shopID);

    if (timeZone) moment.tz.setDefault(timeZone);

    const ref = db.collection('shops').doc(shopID).collection('customers');
    ref
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          if (_.split(doc.id, '-').length !== 3) {
            const phone = formatPhone(doc.id);
            const newCustomer = {
              ...doc.data(),
              phone,
            };
            db.collection('shops')
              .doc(shopID)
              .collection('customers')
              .doc(phone)
              .set(newCustomer)
              .then(() => {
                db.collection('shops')
                  .doc(shopID)
                  .collection('customers')
                  .doc(doc.id)
                  .delete()
                  .catch((error) => console.log(error));
              })
              .catch((error) => console.log(error));
          } else if (_.split(doc.data().phone, '-').length !== 3) {
            db.collection('shops')
              .doc(shopID)
              .collection('customers')
              .doc(doc.id)
              .update({
                phone: doc.id,
              })
              .catch((error) => console.log(error));
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  renderContents = () => {
    const { menuActive } = this.state;
    switch (menuActive) {
      case 'signin':
        return <Request />;
      case 'customer':
        return (
          <Segment>
            <Customer />
          </Segment>
        );
      case 'group':
        return (
          <Segment>
            <Group />
          </Segment>
        );
      case 'service':
        return (
          <Segment>
            <Service />
          </Segment>
        );
      case 'technician':
        return (
          <Segment>
            <Tech />
          </Segment>
        );
      case 'management':
        return (
          <Segment>
            <Management />
          </Segment>
        );
      case 'dashboard':
        return (
          <Segment>
            <Dashboard />
          </Segment>
        );
      case 'review':
        return (
          <Segment>
            <Review />
          </Segment>
        );
      default:
        return null;
    }
  };

  render() {
    const { user, members, active } = this.props;
    const { menuActive } = this.state;

    const isAdmin = _.get(user.roles, 'admin');
    const isStaff = _.get(members, [user.email, 'role']) === 'staff' || isAdmin;
    const isModerator = _.get(members, [user.email, 'role']) === 'moderator' || isAdmin;
    const isOwner = _.get(members, [user.email, 'role']) === 'owner' || isAdmin;

    if (!active) return <div>Loading...</div>;

    const menuItemStyle = {
      color: 'black',
      fontWeight: 600,
      fontSize: 17,
    };
    return (
      <div>
        <Menu
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            background: 'transparent',
          }}
          secondary
          pointing
          icon="labeled"
          color="blue"
        >
          {isStaff || isModerator || isOwner ? (
            <Menu.Item
              style={{
                display: isStaff && !isAdmin ? 'none' : '',
                ...menuItemStyle,
              }}
              name="signin"
              active={menuActive === 'signin'}
              onClick={() =>
                this.setState({
                  menuActive: 'signin',
                })
              }
            >
              Sign-In
            </Menu.Item>
          ) : null}
          {isModerator || isOwner ? (
            <>
              <Menu.Item
                style={{
                  ...menuItemStyle,
                }}
                name="customer"
                active={menuActive === 'customer'}
                onClick={() =>
                  this.setState({
                    menuActive: 'customer',
                  })
                }
              >
                Customer
              </Menu.Item>
              <Menu.Item
                style={{
                  ...menuItemStyle,
                }}
                name="group"
                active={menuActive === 'group'}
                onClick={() =>
                  this.setState({
                    menuActive: 'group',
                  })
                }
              >
                Group
              </Menu.Item>
            </>
          ) : null}
          {isOwner ? (
            <>
              <Menu.Item
                style={{
                  ...menuItemStyle,
                }}
                name="service"
                active={menuActive === 'service'}
                onClick={() =>
                  this.setState({
                    menuActive: 'service',
                  })
                }
              >
                Service
              </Menu.Item>
              <Menu.Item
                style={{
                  ...menuItemStyle,
                }}
                name="technician"
                active={menuActive === 'technician'}
                onClick={() =>
                  this.setState({
                    menuActive: 'technician',
                  })
                }
              >
                Technician
              </Menu.Item>
              <Menu.Item
                style={{
                  ...menuItemStyle,
                }}
                name="management"
                active={menuActive === 'management'}
                onClick={() =>
                  this.setState({
                    menuActive: 'management',
                  })
                }
              >
                Management
              </Menu.Item>
              <Menu.Item
                style={{
                  ...menuItemStyle,
                }}
                name="dashboard"
                active={menuActive === 'dashboard'}
                onClick={() =>
                  this.setState({
                    menuActive: 'dashboard',
                  })
                }
              >
                Dashboard
              </Menu.Item>
              <Menu.Item
                style={{
                  ...menuItemStyle,
                }}
                name="review"
                active={menuActive === 'review'}
                onClick={() =>
                  this.setState({
                    menuActive: 'review',
                  })
                }
              >
                Review
              </Menu.Item>
            </>
          ) : null}
        </Menu>
        {this.renderContents()}
      </div>
    );
  }
}

const mapStateToProps = ({ user, shop }) => ({
  user,
  requests: shop.requests || {},
  customers: shop.customers || {},
  signinSetup: shop.shopData.signinSetup || {},
  members: shop.shopData.members || {},
  active: shop.shopData.active,
  timeZone: shop.shopData.timeZone || '',
});

const mapDispatchToProps = (dispatch) => ({
  fetchShopHandler: (shopID) =>
    dispatch({
      type: SHOP.handlers.fetch,
      shopID,
    }),
  updateCustomersHandler: (customerID, data, onSuccess, ignoreNoti) =>
    dispatch({
      type: SHOP.handlers.updateCustomers,
      customerID,
      data,
      onSuccess,
      ignoreNoti,
    }),
  deleteRequestHandler: (customerID, onSuccess) =>
    dispatch({
      type: SHOP.handlers.deleteRequest,
      customerID,
      onSuccess,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
