import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import ReactTable from 'react-table';
import { bindActionCreators } from 'redux';
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Header,
  Icon,
  Input,
  List,
  Modal,
  TextArea,
} from 'semantic-ui-react';

import { createUserApp, deleteField } from '../../libs/firebase';
import { defaultSms, isEmailValid, isEnableSms, reactTableProps } from '../../libs/utils';
import { updateShopHandler } from '../../redux/actions/shop';

const roles = [
  {
    key: 'owner',
    value: 'owner',
    text: 'Owner',
  },
  {
    key: 'moderator',
    value: 'moderator',
    text: 'Moderator',
  },
  {
    key: 'staff',
    value: 'staff',
    text: 'Staff',
  },
];

class Management extends Component {
  static propTypes = {
    members: PropTypes.object,
  };

  constructor() {
    super();
    this.state = {};
  }

  editHandler = (field, value) => {
    const { editor } = this.state;
    this.setState({
      editor: {
        ...editor,
        [field]: value,
      },
    });
  };

  validateHandler = () => {
    const { editor } = this.state;
    const requiredFields = [
      {
        field: editor.email,
        text: 'Email',
      },
      {
        field: editor.password,
        text: 'Password',
      },
      {
        field: editor.role,
        text: 'Role',
      },
    ];

    let result = true;
    _.forEach(requiredFields, (item) => {
      if (result && !item.field) {
        result = false;
        toastr.warning(`${item.text} is required!`);
      }
    });

    if (result && !isEmailValid(editor.email)) {
      result = false;
      toastr.error('Error', 'Wrong email format! Please try again.');
    }
    if (result && (_.size(editor.password) < 6 || _.size(editor.password) > 16)) {
      result = false;
      toastr.error('Error', 'Your password must be between 6-16 characters.');
    }

    return result;
  };

  removeHandler = () => {
    const { members, updateShopHandler, shopID } = this.props;
    const { editor } = this.state;
    const { email } = editor;

    const newMembers = _.cloneDeep(members);
    const { password } = newMembers[email];

    createUserApp
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        createUserApp
          .firestore()
          .collection('users')
          .doc(email)
          .get()
          .then((userDoc) => {
            const numShops = _.size(_.get(userDoc.data(), 'shops'));
            if (numShops > 1) {
              createUserApp
                .firestore()
                .collection('users')
                .doc(email)
                .update({
                  [`shops.${shopID}`]: deleteField,
                })
                .catch((error) => {
                  console.log(error);
                  toastr.error('Error', error.message || error);
                });
            } else {
              createUserApp
                .firestore()
                .collection('users')
                .doc(email)
                .delete()
                .then(() => {
                  createUserApp
                    .auth()
                    .currentUser.delete()
                    .catch((error) => {
                      console.log(error);
                      toastr.error('Error', error.message || error);
                    });
                })
                .catch((error) => {
                  console.log(error);
                  toastr.error('Error', error.message || error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
            toastr.error('Error', error.message || error);
          });
      })
      .catch((error) => {
        console.log(error);
        toastr.error('Error', error.message || error);
      });

    delete newMembers[email];
    updateShopHandler(
      {
        members: newMembers,
      },
      () => {
        this.setState({
          editor: undefined,
        });
      }
    );
  };

  saveHandler = () => {
    if (!this.validateHandler()) {
      return;
    }

    const { members, updateShopHandler, shopID } = this.props;
    const { editor } = this.state;
    const { isNew, email, password, role } = editor;

    this.setState({
      isWaiting: true,
    });

    const updateMembers = () => {
      const newMembers = _.cloneDeep(members);
      newMembers[email] = {
        ...newMembers[email],
        password,
        role,
      };
      updateShopHandler(
        {
          members: newMembers,
        },
        () => {
          this.setState({
            editor: undefined,
            isWaiting: undefined,
          });
        }
      );
    };

    const updatePassword = () => {
      createUserApp
        .auth()
        .currentUser.updatePassword(password)
        .then(() => {
          updateMembers();
        })
        .catch((error) => {
          console.log(error);
          toastr.error('Error', error.message || error);
          this.setState({
            isWaiting: undefined,
          });
        });
    };

    const signIn = (signInPass) => {
      createUserApp
        .auth()
        .signInWithEmailAndPassword(email, signInPass)
        .then(() => {
          if (isNew) {
            updateMembers();
          } else {
            updatePassword();
          }
        })
        .catch((error) => {
          console.log(error);
          toastr.error('Error', error.message || error);
          this.setState({
            isWaiting: undefined,
          });
        });
    };

    if (isNew) {
      createUserApp
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(() => {
          console.log('Created user ok');
          createUserApp
            .firestore()
            .collection('users')
            .doc(email)
            .set(
              {
                shops: {
                  [shopID]: true,
                },
              },
              {
                mergeFields: [`shops.${shopID}`],
              }
            )
            .then(() => {
              updateMembers();
            })
            .catch((error) => {
              console.log(error);
              toastr.error('Error', error.message || error);
              this.setState({
                isWaiting: undefined,
              });
            });
        })
        .catch((error) => {
          console.log('Failed to create user', error);
          signIn(password);
        });
    } else {
      signIn(members[email].password);
    }
  };

  saveRewardSetupHandler = () => {
    const { updateShopHandler } = this.props;
    const { editorReward } = this.state;

    updateShopHandler(
      {
        rewardSetup: editorReward,
      },
      () => {
        this.setState({
          editorReward: undefined,
        });
      }
    );
  };

  saveSmsSetupHandler = () => {
    const { updateShopHandler } = this.props;
    const { editorSms } = this.state;

    updateShopHandler(
      {
        smsSetup: editorSms,
      },
      () => {
        this.setState({
          editorSms: undefined,
        });
      }
    );
  };

  saveSigninSetupHandler = () => {
    const { updateShopHandler } = this.props;
    const { editorSignin } = this.state;

    updateShopHandler(
      {
        signinSetup: editorSignin,
      },
      () => {
        this.setState({
          editorSignin: undefined,
        });
      }
    );
  };

  renderHeader = (header) => (
    <span
      style={{
        fontSize: 18,
        fontWeight: 600,
      }}
    >
      {header}
    </span>
  );

  renderSigninSetup = () => {
    const { signinSetup } = this.props;
    const { editorSignin } = this.state;
    return (
      <div
        style={{
          marginTop: 30,
        }}
      >
        <div>
          {editorSignin ? (
            <>
              <Button positive icon="check" onClick={this.saveSigninSetupHandler} />
              <Button
                negative
                icon="close"
                onClick={() =>
                  this.setState({
                    editorSignin: undefined,
                  })
                }
              />
            </>
          ) : (
            <Button
              primary
              icon="edit"
              onClick={() =>
                this.setState({
                  editorSignin: signinSetup,
                })
              }
            />
          )}
          {this.renderHeader('Signin/Checkout Setup')}
        </div>
        <div>
          <b>Form Full</b> &nbsp;
          <Checkbox
            disabled={!editorSignin}
            toggle
            checked={editorSignin ? editorSignin.formFull : signinSetup.formFull || false}
            onChange={(e, { checked }) =>
              this.setState({
                editorSignin: {
                  ...editorSignin,
                  formFull: checked,
                },
              })
            }
          />
        </div>
        <div>
          <b>Bypass Choosing Service</b> &nbsp;
          <Checkbox
            disabled={!editorSignin}
            toggle
            checked={editorSignin ? editorSignin.bypassService : signinSetup.bypassService || false}
            onChange={(e, { checked }) =>
              this.setState({
                editorSignin: {
                  ...editorSignin,
                  bypassService: checked,
                },
              })
            }
          />
        </div>
        <div>
          <b>Enable Waiting List</b> &nbsp;
          <Checkbox
            disabled={!editorSignin}
            toggle
            checked={
              editorSignin
                ? editorSignin.enableWaitingList
                : signinSetup.enableWaitingList === false
                ? false
                : signinSetup.enableWaitingList || true
            }
            onChange={(e, { checked }) =>
              this.setState({
                editorSignin: {
                  ...editorSignin,
                  enableWaitingList: checked,
                },
              })
            }
          />
        </div>
        <br />
        <div>
          <b>Auto-CheckOut Period</b> &nbsp;
          {editorSignin ? (
            <>
              <Input
                style={{
                  width: 100,
                }}
                value={
                  editorSignin.autoCheckOutPeriod !== undefined
                    ? editorSignin.autoCheckOutPeriod
                    : 5
                }
                onChange={(e, { value }) =>
                  this.setState({
                    editorSignin: {
                      ...editorSignin,
                      autoCheckOutPeriod: _.toNumber(value),
                    },
                  })
                }
              />
              &nbsp;
              <Dropdown
                required
                placeholder="Select Unit"
                selection
                search
                options={[
                  {
                    key: 'hours',
                    value: 'hours',
                    text: 'hours',
                  },
                  {
                    key: 'minutes',
                    value: 'minutes',
                    text: 'minutes',
                  },
                ]}
                value={
                  editorSignin.autoCheckOutPeriodUnit !== undefined
                    ? editorSignin.autoCheckOutPeriodUnit
                    : 'hours'
                }
                onChange={(e, { value }) =>
                  this.setState({
                    editorSignin: {
                      ...editorSignin,
                      autoCheckOutPeriodUnit: value,
                    },
                  })
                }
              />
            </>
          ) : (
            `${signinSetup.autoCheckOutPeriod || 5} ${
              signinSetup.autoCheckOutPeriodUnit || 'hours'
            }`
          )}
        </div>
        <div>
          <b>Auto-CheckOut SMS</b> &nbsp;
          <Checkbox
            disabled={!editorSignin}
            toggle
            checked={
              editorSignin
                ? editorSignin.autoCheckOutSms
                : signinSetup.autoCheckOutSms === false
                ? false
                : signinSetup.autoCheckOutSms || true
            }
            onChange={(e, { checked }) =>
              this.setState({
                editorSignin: {
                  ...editorSignin,
                  autoCheckOutSms: checked,
                },
              })
            }
          />
        </div>
      </div>
    );
  };

  renderSmsSetup = () => {
    const { smsSetup, smsChar, enableSms } = this.props;
    const { editorSms } = this.state;

    const defaultEnableSms = isEnableSms(enableSms);

    const smsCharOffset = 18;
    return (
      <div
        style={{
          marginTop: 30,
        }}
      >
        <div>
          {editorSms ? (
            <>
              <Button
                positive
                icon="check"
                onClick={() => {
                  if (_.size(editorSms.template) - smsCharOffset > smsChar) {
                    toastr.error(`Reach character limit: ${smsChar}`);
                    return;
                  }
                  this.saveSmsSetupHandler();
                }}
              />
              <Button
                negative
                icon="close"
                onClick={() =>
                  this.setState({
                    editorSms: undefined,
                  })
                }
              />
            </>
          ) : (
            <Button
              disabled={!defaultEnableSms}
              primary
              icon="edit"
              onClick={() =>
                this.setState({
                  editorSms: smsSetup,
                })
              }
            />
          )}
          {this.renderHeader('SMS Setup')}
        </div>
        <div>
          <b>SMS Enabled</b> &nbsp;
          <Checkbox
            disabled={!editorSms}
            toggle
            checked={
              !defaultEnableSms
                ? false
                : _.get(
                    editorSms,
                    'enableSms',
                    smsSetup.enableSms !== undefined ? smsSetup.enableSms : defaultEnableSms
                  )
            }
            onChange={(e, { checked }) =>
              this.setState({
                editorSms: {
                  ...editorSms,
                  enableSms: checked,
                },
              })
            }
          />
        </div>
        <div>
          <b>
            SMS Template
            {' ('}
            {_.size(editorSms ? editorSms.template : smsSetup.template || defaultSms()) -
              smsCharOffset}
            )
          </b>
          <br />
          {editorSms ? (
            <div>
              <TextArea
                style={{
                  width: '-webkit-fill-available',
                }}
                placeholder="type sms template"
                value={editorSms.template || defaultSms()}
                onChange={(e, { value }) => {
                  this.setState({
                    editorSms: {
                      ...editorSms,
                      template: value,
                    },
                  });
                }}
              />
            </div>
          ) : (
            smsSetup.template || defaultSms()
          )}
          <br />
          <b>Image Urls (comma separator)</b>
          <br />
          {editorSms ? (
            <div>
              <TextArea
                style={{
                  width: '-webkit-fill-available',
                }}
                placeholder="type image urls"
                value={editorSms.imageUrls || ''}
                onChange={(e, { value }) => {
                  this.setState({
                    editorSms: {
                      ...editorSms,
                      imageUrls: value,
                    },
                  });
                }}
              />
            </div>
          ) : (
            smsSetup.imageUrls || ''
          )}
        </div>
      </div>
    );
  };

  renderRewardSetup = () => {
    const { rewardSetup } = this.props;
    const { editorReward } = this.state;

    return (
      <div>
        <div>
          {editorReward === undefined ? (
            <Button
              primary
              icon="edit"
              onClick={() =>
                this.setState({
                  editorReward: rewardSetup,
                })
              }
            />
          ) : (
            <>
              <Button positive icon="check" onClick={this.saveRewardSetupHandler} />
              <Button
                negative
                icon="close"
                onClick={() =>
                  this.setState({
                    editorReward: undefined,
                  })
                }
              />
            </>
          )}
          {this.renderHeader('Reward Setup')}
        </div>
        <div
          style={{
            marginTop: 3,
          }}
        >
          {editorReward === undefined ? (
            rewardSetup.points
          ) : (
            <Input
              style={{
                width: 100,
              }}
              placeholder="points"
              value={editorReward.points}
              onChange={(e, { value }) =>
                this.setState({
                  editorReward: {
                    ...editorReward,
                    points: _.toNumber(value),
                  },
                })
              }
            />
          )}
          &nbsp; Points &nbsp; = &nbsp; $
          {editorReward === undefined ? (
            rewardSetup.usd
          ) : (
            <Input
              style={{
                width: 100,
              }}
              placeholder="usd"
              value={editorReward.usd}
              onChange={(e, { value }) =>
                this.setState({
                  editorReward: {
                    ...editorReward,
                    usd: _.toNumber(value),
                  },
                })
              }
            />
          )}
        </div>
        <div>
          <b>Cash Double Points</b> &nbsp;
          <Checkbox
            disabled={!editorReward}
            toggle
            checked={editorReward ? editorReward.cashDouble : rewardSetup.cashDouble}
            onChange={(e, { checked }) =>
              this.setState({
                editorReward: {
                  ...editorReward,
                  cashDouble: checked,
                  ...(checked && {
                    cashTriple: false,
                  }),
                },
              })
            }
          />
        </div>
        <div>
          <b>Cash Triple Points</b> &nbsp;
          <Checkbox
            disabled={!editorReward}
            toggle
            checked={editorReward ? editorReward.cashTriple : rewardSetup.cashTriple}
            onChange={(e, { checked }) =>
              this.setState({
                editorReward: {
                  ...editorReward,
                  cashTriple: checked,
                  ...(checked && {
                    cashDouble: false,
                  }),
                },
              })
            }
          />
        </div>
      </div>
    );
  };

  renderMembers = () => {
    const { members } = this.props;
    const columns = [
      {
        id: 'email',
        accessor: 'email',
        Header: 'Email',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
        },
        Cell: (row) => <div>{row.value}</div>,
      },
      {
        id: 'role',
        accessor: 'role',
        Header: 'Roles',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
        },
        Cell: (row) => (
          <div>
            {_.get(
              _.find(roles, (role) => role.value === row.value),
              'text'
            )}
          </div>
        ),
      },
      {
        id: 'edit',
        Header: '',
        headerClassName: 'tableHeader',
        maxWidth: 50,
        style: {
          textAlign: 'center',
        },
        Cell: (row) => (
          <Button
            icon="edit"
            primary
            onClick={() =>
              this.setState({
                editor: {
                  ..._.cloneDeep(row.original),
                },
              })
            }
          />
        ),
        filterable: false,
      },
    ];
    return (
      <div
        style={{
          marginTop: 30,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Header as="h3">Member Roles ({_.size(members)})</Header>
          <Button.Group>
            <Button
              positive
              onClick={() =>
                this.setState({
                  editor: {
                    order: _.size(members) + 1,
                    isNew: true,
                  },
                })
              }
            >
              <Icon name="add circle" />
              Add Member
            </Button>
          </Button.Group>
        </div>
        <ReactTable
          {...reactTableProps}
          data={_.map(members, (member, email) => ({
            ...member,
            email,
          }))}
          columns={columns}
        />
        <Header>Role Explanation</Header>
        <List>
          <List.Item>Staff has access to tab Sign-in</List.Item>
          <List.Item>Moderator has access to tab Customer</List.Item>
          <List.Item>Owner has access to Customer, Service, and Technician tabs</List.Item>
          <List.Item>Customer has access to tab Account</List.Item>
          <List.Item>Admin has access to all tabs</List.Item>
        </List>
      </div>
    );
  };

  renderEditor = () => {
    const { editor, isWaiting } = this.state;
    if (editor) {
      const { isNew } = editor;
      return (
        <Modal
          open
          onClose={() =>
            this.setState({
              editor: undefined,
            })
          }
          closeIcon={
            <Icon
              name="close"
              color="red"
              size="large"
              onClick={() =>
                this.setState({
                  editor: undefined,
                })
              }
            />
          }
        >
          <Modal.Header>{isNew ? 'Add Member' : 'Edit Member'}</Modal.Header>
          <Modal.Content>
            <Form
              style={{
                padding: 10,
              }}
            >
              <Form.Field>
                <label>Email</label>
                <Form.Input
                  disabled={!isNew}
                  required
                  placeholder="Email"
                  value={_.get(editor, 'email', '')}
                  onChange={(e, { value }) => this.editHandler('email', _.toLower(value))}
                />
              </Form.Field>
              <Form.Input
                required
                label="Password"
                placeholder="Password"
                value={_.get(editor, 'password', '')}
                onChange={(e, { value }) => this.editHandler('password', value)}
              />
              <Form.Dropdown
                required
                label="Role"
                placeholder="Select role"
                selection
                search
                options={roles}
                value={_.get(editor, 'role', '')}
                onChange={(e, { value }) => this.editHandler('role', value)}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            {isNew ? null : (
              <Button
                negative
                onClick={this.removeHandler}
                loading={isWaiting}
                disabled={isWaiting}
              >
                <Icon name="trash" />
                Delete
              </Button>
            )}
            <Button primary onClick={this.saveHandler} loading={isWaiting} disabled={isWaiting}>
              <Icon name="save" />
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }
    return null;
  };

  render() {
    return (
      <div>
        {this.renderEditor()}
        {this.renderRewardSetup()}
        {this.renderSmsSetup()}
        {this.renderSigninSetup()}
        {this.renderMembers()}
      </div>
    );
  }
}

const mapStateToProps = ({ shop }) => ({
  shopID: shop.shopID,
  members: shop.shopData.members || {},
  rewardSetup: shop.shopData.rewardSetup || {
    usd: 5,
    points: 200,
  },
  smsSetup: shop.shopData.smsSetup || {},
  enableSms: shop.shopData.enableSms,
  smsChar: shop.shopData.smsChar || 150,
  signinSetup: shop.shopData.signinSetup || {
    enableWaitingList: true,
  },
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateShopHandler,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Management);
