import _ from 'lodash';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';
import {
  Button,
  Checkbox,
  Image,
  Rating, TextArea,
} from 'semantic-ui-react';

import {
  formatPhone,
  isPhoneValid,
} from '../../../libs/utils';
import {
  REVIEW, SHOPS,
} from '../../../redux/actions/constants';

export default function Submit({
  match,
}) {
  const {
    shopID, phone,
  } = _.get(match, 'params', {});

  const dispatch = useDispatch();
  const {
    shops,
  } = useSelector((state) => ({
    shops: state.shops,
  }));

  const shop = shops[shopID] || {};
  const {
    logo,
    google: googleUrl,
    yelp: yelpUrl,
    facebook: facebookUrl,
  } = shop;

  useEffect(() => {
    dispatch({
      type: SHOPS.handlers.fetch,
    });
  }, []);

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [socials, setSocials] = useState({});
  const [done, setDone] = useState();

  const submit = () => {
    dispatch({
      type: REVIEW.handlers.update,
      shopID,
      phone: formatPhone(phone),
      data: {
        rating,
        comment,
        time: new Date(),
      },
      onSuccess: () => {
        setDone(true);
      },
    });
  };

  const getUrl = (url) => (_.startsWith(url, 'http') ? url : `http://${url}`);

  const renderComment = () => {
    if (rating < 4) {
      return (
        <div style={{
          marginTop: 10,
        }}
        >
          <label>Please leave us a comment</label>
          <div>
            <TextArea
              disabled={done}
              onChange={(e, {
                value,
              }) => setComment(value)}
            />
          </div>
          {done ? 'Submitted. Thank you!' : (
            <Button
              disabled={done}
              positive
              onClick={submit}
            >
              Submit
            </Button>
          )}
        </div>
      );
    }

    return (
      <div style={{
        marginTop: 20,
      }}
      >
        {!done && (
          <div style={{
            marginBottom: 20,
          }}
          >
            <label>
              Follow us on our social media
            </label>
          </div>
        )}

        {googleUrl && (!done || socials.google) && (
          <div
            style={{
              display: 'flex',
              marginLeft: 'calc(50% - 50px)',
              marginBottom: 20,
            }}
            onClick={() => {
              if (done) return;
              setSocials((prev) => ({
                ...prev,
                google: !prev.google,
              }));
            }}
            role="presentation"
          >
            <Checkbox
              disabled={done}
              style={{
                transform: 'scale(1.2)',
                marginRight: 10,
              }}
              checked={socials.google || false}
            />
            <div>
              Google
            </div>
          </div>
        )}
        {done && socials.google && (
          <p><a href={getUrl(googleUrl)} target="_blank" rel="noreferrer">Click here to leave us a comment on Google</a></p>
        )}

        {yelpUrl && (!done || socials.yelp) && (
          <div
            style={{
              display: 'flex',
              marginLeft: 'calc(50% - 50px)',
              marginBottom: 20,
            }}
            onClick={() => {
              if (done) return;
              setSocials((prev) => ({
                ...prev,
                yelp: !prev.yelp,
              }));
            }}
            role="presentation"
          >
            <Checkbox
              disabled={done}
              style={{
                transform: 'scale(1.2)',
                marginRight: 10,
              }}
              checked={socials.yelp || false}
            />
            <div>
              Yelp
            </div>
          </div>
        )}
        {done && socials.yelp && (
          <p><a href={getUrl(yelpUrl)} target="_blank" rel="noreferrer">Click here to leave us a comment on Yelp</a></p>
        )}

        {facebookUrl && (!done || socials.facebook) && (
          <div
            style={{
              display: 'flex',
              marginLeft: 'calc(50% - 50px)',
              marginBottom: 20,
            }}
            onClick={() => {
              if (done) return;
              setSocials((prev) => ({
                ...prev,
                facebook: !prev.facebook,
              }));
            }}
            role="presentation"
          >
            <Checkbox
              disabled={done}
              style={{
                transform: 'scale(1.2)',
                marginRight: 10,
              }}
              checked={socials.facebook || false}
            />
            <div>
              Facebook
            </div>
          </div>
        )}
        {done && socials.facebook && (
          <p><a href={getUrl(facebookUrl)} target="_blank" rel="noreferrer">Click here to leave us a comment on Facebook</a></p>
        )}

        {done || (
          <Button
            disabled={done}
            positive
            style={{}}
            onClick={() => {
              submit();
            }}
          >
            Continue
          </Button>
        )}
      </div>
    );
  };

  if (!isPhoneValid(phone)) {
    return (
      <div>
        Invalid Phone:
        {' '}
        {phone}
      </div>
    );
  }

  return (
    <div
      align="center"
      style={{
        padding: 50,
      }}
    >
      <Image
        src={logo}
        style={{
          maxHeight: 100,
          maxWidth: '100%',
        }}
        ui={false}
        size="medium"
      />
      <h3>Thank you for visiting us. Please provide feedback to help us improve our service:</h3>
      <Rating
        disabled={done}
        icon="star" maxRating={5} size="massive"
        clearable
        onRate={(e, {
          rating: newRating,
        }) => setRating(newRating)}
      />
      {rating ? renderComment() : null}
    </div>
  );
}
