import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Header, Icon, Image, Table } from 'semantic-ui-react';

import ReturnSignin from './ReturnSignin';
import Signin from './Signin';

class Request extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: undefined,
    };
  }

  backHandler = () => {
    this.setState({
      type: undefined,
    });
  };

  renderContent = () => {
    const { type } = this.state;
    if (type === 'new') {
      return (
        <>
          <Button
            primary
            style={{
              marginBottom: 5,
              fontSize: 20,
            }}
            onClick={this.backHandler}
          >
            <Icon name="angle left" />
            Back
          </Button>
          <Signin backHandler={this.backHandler} />
        </>
      );
    }
    if (type === 'return') {
      return (
        <>
          <Button
            primary
            style={{
              marginBottom: 5,
              fontSize: 20,
            }}
            onClick={() =>
              this.setState({
                type: undefined,
              })
            }
          >
            <Icon name="angle left" />
            Back
          </Button>
          <ReturnSignin backHandler={this.backHandler} />
        </>
      );
    }

    return (
      <div align="center">
        <Button
          primary
          style={{
            padding: 20,
            margin: 10,
            fontSize: 20,
            background: '#0A64E8',
          }}
          onClick={() =>
            this.setState({
              type: 'return',
            })
          }
        >
          Returning Customer Sign-In
        </Button>
        <Button
          negative
          style={{
            padding: 20,
            margin: 10,
            fontSize: 20,
            background: '#11A711',
          }}
          onClick={() =>
            this.setState({
              type: 'new',
            })
          }
        >
          New Customer Sign-In
        </Button>
      </div>
    );
  };

  renderSigninList = () => {
    const { requests } = this.props;
    const [inServiceRequests, signInRequests] = _.partition(
      requests,
      (request) => request.inService
    );

    return (
      <div
        style={{
          display: 'flex',
          marginTop: 20,
        }}
      >
        <div>
          <Header textAlign="center">Waiting List</Header>
          <Table
            style={{
              margin: 0,
              width: '0%',
            }}
            unstackable
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Phone</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(signInRequests, (request, index) => {
                const { firstName, phone } = request;
                return (
                  <Table.Row key={index}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{firstName}</Table.Cell>
                    <Table.Cell>*{_.takeRight(phone, 4)}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
        <div>
          <Header textAlign="center">In-Service List</Header>
          <Table
            style={{
              margin: 0,
              width: '0%',
            }}
            unstackable
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Phone</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(inServiceRequests, (request, index) => {
                const { firstName, phone } = request;
                return (
                  <Table.Row key={index}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{firstName}</Table.Cell>
                    <Table.Cell>*{_.takeRight(phone, 4)}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      </div>
    );
  };

  render() {
    const { name, signinSetup, logo } = this.props;

    const enableWaitingList =
      signinSetup.enableWaitingList === false ? false : signinSetup.enableWaitingList || true;

    return (
      <div>
        <center>
          {logo ? <Image src={logo} height={80} wrapped ui={false} /> : null}
          <h2>
            {'Welcome to '}
            {name}
          </h2>
        </center>
        {this.renderContent()}
        {enableWaitingList && this.renderSigninList()}
      </div>
    );
  }
}

const mapStateToProps = ({ user, shop }) => ({
  user,
  shopID: shop.shopID,
  requests: shop.requests || {},
  customers: shop.customers || {},
  name: shop.shopData.name || 'Shop',
  logo: shop.shopData.logo,
  signinSetup: shop.shopData.signinSetup || {
    enableWaitingList: true,
  },
});

export default connect(mapStateToProps)(Request);
