import {
  SHOPS, SIGN_OUT,
} from '../actions/constants';

const initialState = {};

export default function shops(state = initialState, action) {
  switch (action.type) {
    case SHOPS.fetch:
      return {
        ...state,
        ...action.data,
      };
    case SHOPS.update:
      return {
        ...state,
        [action.id]: action.data,
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
