import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Button, Form, Header } from 'semantic-ui-react';

class TechMonthly extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateFrom: moment().subtract(3, 'month').startOf('month').valueOf(),
      dateTo: moment().endOf('month').valueOf(),
      categorySeries: [],
      dataSeries: [],
      prevByPoints: props.byPoints,
      loading: true,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.byPoints !== state.prevByPoints) {
      return {
        prevByPoints: props.byPoints,
        ...TechMonthly.getData(props, state),
      };
    }
    return null;
  }

  componentDidMount() {
    const { props, state } = this;
    this.setState({
      loading: false,
      ...TechMonthly.getData(props, state),
    });
  }

  static getData = (props, state) => {
    const { customers, techs, byPoints } = props;
    const { dateFrom, dateTo } = state;

    const categorySeries = [];
    const dataSeries = {};
    _.forEach(techs, (tech, techKey) => {
      dataSeries[techKey] = {
        name: tech.name,
        data: [],
      };
    });

    const to = moment(dateTo);
    const dateCurr = moment(dateFrom);

    while (dateCurr <= to) {
      const year = dateCurr.year();
      const month = dateCurr.month();

      categorySeries.push(`${month + 1}/${year}`);

      _.forEach(techs, (tech, techKey) => {
        let count = 0;
        _.forEach(customers, (customer) => {
          const techVisits = _.filter(
            customer.visits,
            (visit) =>
              visit.time &&
              moment(visit.time).year() === year &&
              moment(visit.time).month() === month &&
              _.includes(_.flatten(_.values(visit.techs)), techKey)
          );
          count += byPoints ? _.sumBy(techVisits, (visit) => visit.points) : techVisits.length;
        });
        dataSeries[techKey].data.push(count);
      });

      dateCurr.add(1, 'month');
    }

    return {
      categorySeries,
      dataSeries: _.sortBy(dataSeries, 'name'),
    };
  };

  isValidChart = () => {
    const { dateFrom, dateTo } = this.state;
    let result = true;
    if (dateFrom > dateTo) {
      toastr.warning('Date From should smaller than Date To');
      result = false;
    }
    return result;
  };

  renderFilter = () => {
    const { props, state } = this;
    const { dateFrom, dateTo, loading } = this.state;

    return (
      <Form>
        <Form.Group>
          <Form.Field required>
            <label>Date from</label>
            <DatePicker
              selected={dateFrom}
              onChange={(date) => {
                this.setState({
                  dateFrom: moment(date).startOf('month').valueOf(),
                });
              }}
            />
          </Form.Field>
          <Form.Field required>
            <label>Date to</label>
            <DatePicker
              selected={dateTo}
              onChange={(date) => {
                this.setState({
                  dateTo: moment(date).endOf('month').valueOf(),
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <Button
              primary
              style={{
                marginTop: 23,
              }}
              loading={loading}
              disabled={loading}
              onClick={() => {
                if (!this.isValidChart()) {
                  return;
                }
                this.setState({
                  loading: true,
                });
                this.setState({
                  loading: false,
                  ...TechMonthly.getData(props, state),
                });
              }}
            >
              Search
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    );
  };

  renderChart = () => {
    const { byPoints } = this.props;
    const { categorySeries, dataSeries } = this.state;

    const unitY = byPoints ? 'Points' : 'Customers';
    return (
      <ReactApexChart
        series={dataSeries}
        options={{
          chart: {
            type: 'bar',
          },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          xaxis: {
            categories: categorySeries,
          },
          yaxis: {
            title: {
              text: `Number of ${unitY}`,
            },
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: (val) => `${val} ${unitY}`,
            },
          },
        }}
        type="bar"
        height={400}
      />
    );
  };

  render() {
    return (
      <div>
        <Header as="h3">Technician Monthly Statistics</Header>
        {this.renderFilter()}
        {this.renderChart()}
      </div>
    );
  }
}

TechMonthly.defaultProps = {
  byPoints: false,
};

TechMonthly.propTypes = {
  byPoints: PropTypes.bool,
};

const mapStateToProps = ({ shop }) => ({
  customers: shop.customers || {},
  techs: shop.shopData.techs || {},
});

export default connect(mapStateToProps)(TechMonthly);
