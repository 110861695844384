import React, {
  Component,
} from 'react';
import {
  Tab,
} from 'semantic-ui-react';

import CustomerDaily from './CustomerDaily';
import CustomerMonthly from './CustomerMonthly';
import CustomerWeekly from './CustomerWeekly';
import SMSMonthly from './SMSMonthly';
import ServiceDaily from './ServiceDaily';
import ServiceMonthly from './ServiceMonthly';
import ServiceWeekly from './ServiceWeekly';
import TechDaily from './TechDaily';
import TechMonthly from './TechMonthly';
import TechWeekly from './TechWeekly';

class Dashboard extends Component {
  render() {
    const panes = [
      {
        menuItem: 'Customer',
        render: () => (
          <Tab.Pane>
            <div>
              <CustomerDaily />
              <hr />
              <br />
              <CustomerWeekly />
              <hr />
              <br />
              <CustomerMonthly />
            </div>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Service',
        render: () => (
          <Tab.Pane>
            <ServiceDaily />
            <hr />
            <br />
            <ServiceWeekly />
            <hr />
            <br />
            <ServiceMonthly />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Technician (by Points)',
        render: () => (
          <Tab.Pane>
            <TechDaily byPoints />
            <hr />
            <br />
            <TechWeekly byPoints />
            <hr />
            <br />
            <TechMonthly byPoints />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Technician (by Customers)',
        render: () => (
          <Tab.Pane>
            <TechDaily />
            <hr />
            <br />
            <TechWeekly />
            <hr />
            <br />
            <TechMonthly />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'SMS',
        render: () => (
          <Tab.Pane>
            <SMSMonthly />
          </Tab.Pane>
        ),
      },
    ];

    return <Tab panes={panes} />;
  }
}

export default Dashboard;
