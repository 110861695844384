import 'moment-timezone';

import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { Button, Header, Icon, Input, Modal, Table } from 'semantic-ui-react';

import { getTextTimeZone } from '../../../libs/utils';
import { updateCustomersHandler } from '../../../redux/actions/shop';

class Reward extends Component {
  static propTypes = {
    closeHandler: PropTypes.func.isRequired,
    customerID: PropTypes.string.isRequired,
    rewards: PropTypes.array,
  };

  static defaultProps = {
    rewards: [],
  };

  constructor(props) {
    super(props);

    const { rewardSetup, totalPoints } = this.props;
    const count = _.toInteger(totalPoints / rewardSetup.points);
    this.state = {
      redeemUsd: count * rewardSetup.usd,
      redeemPoints: count * rewardSetup.points,
    };
  }

  rewardHandler = () => {
    const { customerID, updateCustomersHandler, rewards, totalPoints, closeHandler } = this.props;
    const { redeemUsd, redeemPoints } = this.state;

    if (redeemPoints > totalPoints) {
      toastr.error('Error', `Only ${totalPoints} is available to redeem`);
      return;
    }

    const newRewards = [
      ...rewards,
      {
        time: new Date().getTime(),
        usd: redeemUsd,
        points: redeemPoints,
      },
    ];
    updateCustomersHandler(
      customerID,
      {
        rewards: newRewards,
      },
      closeHandler
    );
  };

  deleteHandler = (rewards, index) => {
    const { customerID, updateCustomersHandler } = this.props;
    updateCustomersHandler(customerID, {
      rewards: _.filter(rewards, (reward, rewardIndex) => rewardIndex !== index),
    });
  };

  renderRewards = () => {
    const {
      closeHandler,
      user,
      members,
      rewards: propRewards,
      totalPoints,
      fromSignin,
      rewardSetup,
      timeZone,
    } = this.props;
    const { redeemUsd, redeemPoints } = this.state;

    const isAdmin = _.get(user.roles, 'admin');
    const isModerator = _.get(members, [user.email, 'role']) === 'moderator' || isAdmin;
    const isOwner = _.get(members, [user.email, 'role']) === 'owner' || isAdmin;
    const allowEdit = !fromSignin && (isModerator || isOwner);

    const rewards = _.orderBy(propRewards, 'time', 'desc');

    const currentPoints = totalPoints % rewardSetup.points;

    return (
      <Modal
        open
        size="small"
        closeIcon={<Icon name="close" color="red" size="large" onClick={closeHandler} />}
      >
        <Modal.Header>Rewards</Modal.Header>
        <Modal.Content>
          <div>
            <Header as="h3">
              {'Current Points: '}
              {currentPoints}
            </Header>
          </div>

          <div
            style={{
              marginTop: 20,
            }}
          >
            <Header as="h3">
              Available To Redeem
              {allowEdit ? '' : `: $${redeemUsd}`}
            </Header>
            {allowEdit ? (
              <>
                <Input
                  disabled={!allowEdit}
                  style={{
                    width: 100,
                  }}
                  placeholder="type number of usd"
                  value={redeemUsd}
                  onChange={(e, { value }) =>
                    this.setState({
                      redeemUsd: _.toNumber(value),
                    })
                  }
                />
                &nbsp; USD &nbsp; = &nbsp;
                <Input
                  disabled={!allowEdit}
                  style={{
                    width: 100,
                  }}
                  placeholder="type number of points"
                  value={redeemPoints}
                  onChange={(e, { value }) =>
                    this.setState({
                      redeemPoints: _.toNumber(value),
                    })
                  }
                />
                &nbsp; Points &nbsp;
                <Button
                  primary
                  onClick={() => {
                    if (!redeemUsd && !redeemPoints) {
                      toastr.warning('Warning', 'No reward');
                    } else if (
                      redeemUsd % rewardSetup.usd ||
                      redeemPoints % rewardSetup.points ||
                      redeemUsd / rewardSetup.usd !== redeemPoints / rewardSetup.points
                    ) {
                      let count = _.toInteger(redeemUsd / rewardSetup.usd);
                      if (count === 0 && (count + 1) * rewardSetup.points <= totalPoints) {
                        count = 1;
                      }
                      this.setState({
                        redeemUsd: count * rewardSetup.usd,
                        redeemPoints: count * rewardSetup.points,
                      });
                      toastr.warning(
                        'Warning',
                        'The calculation is just corrected itself, now please try again to proceed'
                      );
                    } else {
                      this.rewardHandler();
                    }
                  }}
                >
                  <Icon name="gift" />
                  Redeem
                </Button>
              </>
            ) : null}
          </div>

          {allowEdit ? (
            <div
              style={{
                marginTop: 20,
              }}
            >
              <Header as="h3">Redeem History</Header>
              <Table color="blue" compact unstackable celled striped selectable textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>#</Table.HeaderCell>
                    <Table.HeaderCell>Time</Table.HeaderCell>
                    <Table.HeaderCell>USD</Table.HeaderCell>
                    <Table.HeaderCell>Points</Table.HeaderCell>
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {_.map(rewards, (reward, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>{index + 1}</Table.Cell>
                      <Table.Cell>
                        {moment.tz(_.get(reward, 'time', ''), timeZone).format('MM/DD/YYYY hh:mm')}
                        {` ${getTextTimeZone(timeZone)}`}
                      </Table.Cell>
                      <Table.Cell>{reward.usd}</Table.Cell>
                      <Table.Cell>{reward.points}</Table.Cell>
                      <Table.Cell>
                        <Button
                          negative
                          icon="trash"
                          onClick={() => this.deleteHandler(rewards, index)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          ) : null}
        </Modal.Content>
      </Modal>
    );
  };

  render() {
    return <div>{this.renderRewards()}</div>;
  }
}

const mapStateToProps = ({ user, shop }) => ({
  user,
  timeZone: shop.shopData.timeZone || '',
  members: shop.shopData.members || {},
  rewardSetup: shop.shopData.rewardSetup || {
    usd: 5,
    points: 200,
  },
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCustomersHandler,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Reward);
