import 'moment-timezone';

import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { Button, Checkbox, Dropdown, Icon, Input, Modal, Table } from 'semantic-ui-react';

import { db } from '../../../libs/firebase';
import { errorHandler, successHandler } from '../../../libs/log';
import { formatPhone, getTextTimeZone, isEnableSms } from '../../../libs/utils';
import { updateCustomersHandler } from '../../../redux/actions/shop';

class SigninList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      services: {},
      techs: {},
      points: {},
      payMethods: {},
      redeemUsds: {},
      enableSmses: {},
    };
  }

  rewardHandler = (phone, redeemUsd) => {
    const { customers, rewardSetup, updateCustomersHandler } = this.props;
    const count = _.toInteger(redeemUsd / rewardSetup.usd);

    const newRewards = [
      ..._.get(customers[phone], 'rewards', []),
      {
        time: new Date().getTime(),
        usd: redeemUsd,
        points: count * rewardSetup.points,
      },
    ];
    updateCustomersHandler(phone, {
      rewards: newRewards,
    });
  };

  renderSigninList = () => {
    const {
      services: servicesList,
      techs: techsList,
      checkOutHandler,
      cancelHandler,
      closeHandler,
      rewardSetup,
      smsSetup,
      shopID,
      title,
      requests,
      type,
      timeZone,
      customers,
      enablePointbyVisit,
      enableSms: shopEnableSms,
    } = this.props;
    const {
      services: servicesState,
      techs: techsState,
      points: pointsState,
      redeemUsds: redeemUsdsState,
      enableSmses: enableSmsesState,
      payMethods: payMethodsState,
    } = this.state;

    const groupServices = _.groupBy(
      _.map(servicesList, (service, key) => ({
        ...service,
        key,
      })),
      (service) => service.group
    );
    const sortedGroupNames = _.sortBy(_.keys(groupServices));
    const sortedServiceList = _.flatMap(sortedGroupNames, (groupName) =>
      _.sortBy(groupServices[groupName], (service) => service.name)
    );

    const serviceOptions = _.map(sortedServiceList, (service) => ({
      key: service.key,
      value: service.key,
      text: service.name,
    }));
    const techOptions = _.sortBy(
      _.map(techsList, (tech, techKey) => ({
        key: techKey,
        value: techKey,
        text: tech.name,
      })),
      'text'
    );

    const headerStyle = {
      background: '#07c2e4',
      color: 'white',
    };

    return (
      <Modal
        open
        size="fullscreen"
        style={{
          top: 30,
          bottom: 150,
        }}
        closeIcon={<Icon name="close" color="red" size="large" onClick={closeHandler} />}
      >
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content
          style={{
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Table color="blue" compact unstackable celled striped selectable textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={headerStyle}>Time & Name & Phone</Table.HeaderCell>
                <Table.HeaderCell style={headerStyle}>Points & Reward</Table.HeaderCell>
                <Table.HeaderCell style={headerStyle}>Redeem</Table.HeaderCell>
                <Table.HeaderCell style={headerStyle}>Services & Points</Table.HeaderCell>
                <Table.HeaderCell style={headerStyle}>Technicians</Table.HeaderCell>
                <Table.HeaderCell style={headerStyle}>Enable SMS</Table.HeaderCell>
                <Table.HeaderCell style={headerStyle} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(requests, (request) => {
                const {
                  firstName,
                  lastName,
                  phone: rawPhone,
                  enableSms: customerEnableSms,
                  visit: visitCheckin,
                } = request;
                console.log(request);

                const phone = formatPhone(rawPhone);

                const { visits, rewards } = customers[phone] || {};

                const visitPoints = _.sumBy(visits, (visit) => +visit.points);
                const redeemPoints = _.sumBy(rewards, (reward) => +reward.points);
                const totalPoints = visitPoints - redeemPoints;
                const currentPoints = totalPoints % rewardSetup.points;
                const count = _.toInteger(totalPoints / rewardSetup.points);
                const totalUsd = count * rewardSetup.usd;

                const redeemUsd =
                  redeemUsdsState[phone] !== undefined ? redeemUsdsState[phone] : totalUsd || 0;
                const services = servicesState[phone] || visitCheckin.services || [];
                const techs = techsState[phone] || visitCheckin.techs || {};

                const payMethod = payMethodsState[phone] || visitCheckin.payMethod || 'creditcard';
                const requestPoints = enablePointbyVisit ? 1 : visitCheckin.points || 0;
                let points = pointsState[phone] !== undefined ? pointsState[phone] : requestPoints;
                if (payMethod === 'cash') {
                  if (rewardSetup.cashDouble) {
                    points *= 2;
                  } else if (rewardSetup.cashTriple) {
                    points *= 3;
                  }
                }

                const defaultEnableSms = isEnableSms(
                  shopEnableSms,
                  smsSetup.enableSms,
                  customerEnableSms
                );

                return (
                  <Table.Row key={phone}>
                    <Table.Cell>
                      <p>
                        {moment.tz(visitCheckin.time, timeZone).format('MM/DD/YYYY hh:mm')}
                        {` ${getTextTimeZone(timeZone)}`}
                      </p>
                      <p>{`${firstName || ''} ${lastName || ''}`}</p>
                      <p>{phone}</p>
                    </Table.Cell>
                    <Table.Cell>
                      <p>{currentPoints}</p>
                      <p>{totalUsd}</p>
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        style={{
                          maxWidth: 60,
                        }}
                        type="number"
                        placeholder="Redeem"
                        value={redeemUsd}
                        onChange={(e, { value }) =>
                          this.setState({
                            redeemUsds: {
                              ...redeemUsdsState,
                              [phone]: value,
                            },
                          })
                        }
                      />
                      <Button
                        primary
                        onClick={() => {
                          if (!redeemUsd || redeemUsd > totalUsd) {
                            toastr.warning(
                              'Warning',
                              `Reward to redeem should be more than 0, and less than ${totalUsd}`
                            );
                          } else if (redeemUsd % rewardSetup.usd) {
                            let countReq = _.toInteger(redeemUsd / rewardSetup.usd);
                            if (
                              countReq === 0 &&
                              (countReq + 1) * rewardSetup.points <= totalPoints
                            ) {
                              countReq = 1;
                            }
                            this.setState({
                              redeemUsds: {
                                ...redeemUsdsState,
                                [phone]: countReq * rewardSetup.usd,
                              },
                            });
                            toastr.warning(
                              'Warning',
                              'The calculation is just corrected itself, now please try again to proceed'
                            );
                          } else {
                            this.rewardHandler(phone, redeemUsd);
                          }
                        }}
                        icon="check"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Dropdown
                        clearable
                        placeholder="Choose Services"
                        multiple
                        search
                        selection
                        options={serviceOptions}
                        value={services}
                        onChange={(e, { value }) => {
                          this.setState({
                            services: {
                              ...servicesState,
                              [phone]: value,
                            },
                            points: {
                              ...pointsState,
                              [phone]: _.sumBy(
                                value,
                                (service) => +_.get(servicesList, [service, 'point'], 0)
                              ),
                            },
                          });
                        }}
                      />
                      {servicesState[phone] ? (
                        <Icon
                          name="undo"
                          onClick={() => {
                            this.setState({
                              services: {
                                ...servicesState,
                                [phone]: visitCheckin.services,
                              },
                              points: {
                                ...pointsState,
                                [phone]: _.sumBy(
                                  visitCheckin.services,
                                  (service) => +_.get(servicesList, [service, 'point'], 0)
                                ),
                              },
                            });
                          }}
                        />
                      ) : null}
                      <hr />
                      <div>
                        <b>Points </b>
                        <Input
                          style={{
                            maxWidth: 80,
                          }}
                          type="number"
                          placeholder="Type Points"
                          value={points}
                          onChange={(e, { value }) =>
                            this.setState({
                              points: {
                                ...pointsState,
                                [phone]: value,
                              },
                            })
                          }
                        />
                      </div>
                      <div>
                        <b>Paid by </b>
                        <Dropdown
                          style={{
                            minWidth: 100,
                          }}
                          clearable
                          search
                          selection
                          options={[
                            {
                              key: 'creditcard',
                              value: 'creditcard',
                              text: 'Credit Card',
                            },
                            {
                              key: 'cash',
                              value: 'cash',
                              text: 'Cash',
                            },
                            {
                              key: 'giftcard',
                              value: 'giftcard',
                              text: 'Gift Card',
                            },
                          ]}
                          value={payMethod}
                          onChange={(e, { value }) => {
                            this.setState({
                              payMethods: {
                                ...payMethodsState,
                                [phone]: value,
                              },
                            });
                          }}
                        />
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      {_.map(services, (service) => {
                        const serviceName = _.get(servicesList, [service, 'name']);
                        const serviceTechs = techs[service] || [];
                        return (
                          <div key={service}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <div
                                style={{
                                  marginRight: 5,
                                  minWidth: 50,
                                }}
                              >
                                {serviceName}
                              </div>
                              <div>
                                <Dropdown
                                  clearable
                                  placeholder="Choose Technicians"
                                  multiple
                                  search
                                  selection
                                  options={techOptions}
                                  value={serviceTechs}
                                  onChange={(e, { value }) => {
                                    this.setState({
                                      techs: {
                                        ...techsState,
                                        [phone]: {
                                          ...techs,
                                          [service]: value,
                                        },
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <hr />
                          </div>
                        );
                      })}
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        disabled={!defaultEnableSms}
                        toggle
                        checked={
                          !defaultEnableSms
                            ? false
                            : enableSmsesState[phone] !== undefined
                            ? enableSmsesState[phone]
                            : defaultEnableSms
                        }
                        onChange={(e, { checked }) =>
                          this.setState({
                            enableSmses: {
                              ...enableSmsesState,
                              [phone]: checked,
                            },
                          })
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {type === 'in-service' ? null : (
                        <Button
                          content="In-Service"
                          primary
                          onClick={() => {
                            const newRequest = {
                              ...request,
                              visit: {
                                ...visitCheckin,
                                services,
                                techs,
                                points,
                                payMethod,
                              },
                              inService: true,
                              enableSms: defaultEnableSms && enableSmsesState[phone],
                            };
                            console.log(newRequest);
                            db.collection('shops')
                              .doc(shopID)
                              .collection('requests')
                              .doc(phone)
                              .set(newRequest)
                              .then(() => {
                                successHandler('Done', 'In-Service Success');
                              })
                              .catch((error) => {
                                errorHandler('Error', error);
                              });
                          }}
                        />
                      )}
                      <Button
                        content="Check-out"
                        positive
                        onClick={() => {
                          const newRequest = {
                            ...request,
                            visit: {
                              ...visitCheckin,
                              services,
                              techs,
                              points,
                              payMethod,
                            },
                            enableSms: defaultEnableSms && enableSmsesState[phone],
                          };
                          console.log(newRequest);
                          checkOutHandler(phone, newRequest);
                        }}
                      />
                      <Button content="Cancel" negative onClick={() => cancelHandler(phone)} />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Modal.Content>
      </Modal>
    );
  };

  render() {
    return <div>{this.renderSigninList()}</div>;
  }
}

SigninList.propTypes = {
  closeHandler: PropTypes.func.isRequired,
};

const mapStateToProps = ({ shop }) => ({
  services: shop.shopData.services || {},
  techs: shop.shopData.techs || {},
  customers: shop.customers || {},
  shopID: shop.shopID,
  timeZone: shop.shopData.timeZone || '',
  rewardSetup: shop.shopData.rewardSetup || {
    usd: 5,
    points: 200,
  },
  smsSetup: shop.shopData.smsSetup || {},
  enableSms: shop.shopData.enableSms,
  enablePointbyVisit: shop.shopData.enablePointbyVisit,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCustomersHandler,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SigninList);
