import {
  all,
} from 'redux-saga/effects';

import reviewSaga from './review';
import shopSaga from './shop';
import shopsSaga from './shops';
import userSaga from './user';

export default function* rootSaga() {
  yield all([
    reviewSaga(),
    shopSaga(),
    shopsSaga(),
    userSaga(),
  ]);
}
