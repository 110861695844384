import 'react-table/react-table.css';
import 'regenerator-runtime/runtime';
import './semantic/semantic.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './App.css';
import './react-datepicker.css';

import * as Sentry from '@sentry/browser';
import enGB from 'date-fns/locale/en-GB';
import moment from 'moment';
import React from 'react';
import {
  registerLocale,
  setDefaultLocale,
} from 'react-datepicker';
import ReactDOM from 'react-dom';
import {
  Provider,
} from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import {
  applyMiddleware, compose, createStore,
} from 'redux';
import createSagaMiddleware from 'redux-saga';

import App from './App';
import rootSaga from './redux/actions';
import reducer from './redux/reducers';
import * as serviceWorker from './serviceWorker';

if (window.location.hostname !== 'localhost') {
  Sentry.init({
    dsn: 'https://402be24335b34a0485d570ec955d9f4b@o379957.ingest.sentry.io/5205325',
  });
}

registerLocale('enGB', enGB);
setDefaultLocale('enGB');
moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line
const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

ReactDOM.render((
  <Provider store={store}>
    <div>
      <ReduxToastr
        timeOut={2000}
        closeOnToastrClick
      />
      <App />
    </div>
  </Provider>
), document.getElementById('root'));

serviceWorker.unregister();
