import { all, call, put, takeEvery } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';

import { db } from '../../libs/firebase';
import { errorHandler, successHandler } from '../../libs/log';
import { formatPhone } from '../../libs/utils';

import { REVIEW, SHOP } from './constants';

function* updateShop(data = {}) {
  yield put({
    type: SHOP.update,
    data,
  });
}

function* fetchReviews({ shopID }) {
  try {
    const ref = db.collection('shops').doc(shopID).collection('reviews');
    const docs = yield call([ref, ref.get]);
    const reviews = {};
    docs.forEach((doc) => {
      const phone = formatPhone(doc.id);
      reviews[phone] = doc.data();
    });
    yield* updateShop({
      shopID,
      reviews,
    });
  } catch (error) {
    errorHandler('Failed to fetch reviews', error);
  }
}

function* updateReview({ shopID, phone, data, onSuccess }) {
  try {
    yield call(
      () =>
        new Promise((resolve, rejects) => {
          const reviewId = uuid();
          db.collection('shops')
            .doc(shopID)
            .collection('reviews')
            .doc(phone)
            .set(
              {
                [reviewId]: data,
              },
              {
                mergeFields: [reviewId],
              }
            )
            .then(() => resolve(true))
            .catch((err) => rejects(err));
        })
    );
    successHandler('Done', 'Successfully', onSuccess);
  } catch (error) {
    errorHandler('Failed to update', error);
  }
}

export default function* reviewSaga() {
  yield all([
    yield takeEvery(REVIEW.handlers.fetch, fetchReviews),
    yield takeEvery(REVIEW.handlers.update, updateReview),
  ]);
}
