import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { BrowserRouter, Route } from 'react-router-dom';
import { Button, Form, Icon, Image, Modal, Segment } from 'semantic-ui-react';

import packageJson from '../package.json';

import { BASENAME, RESOURCES } from './libs/config';
import { app, facebookProvider, googleProvider } from './libs/firebase';
import { isEmailValid } from './libs/utils';
import { USER } from './redux/actions/constants';
import Home from './screens';
import Submit from './screens/Shop/Review/Submit';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailLogin: undefined,
      editor: undefined,
    };
  }

  componentDidMount() {
    this.removeAuthListener = app.auth().onAuthStateChanged((userAuth) => {
      if (userAuth) {
        const providerData = _.head(userAuth.providerData);
        const user = {
          ...userAuth,
          ...providerData,
          uid: userAuth.uid,
        };

        ReactGA.initialize('UA-150780306-1', {
          gaOptions: {
            userId: userAuth.email,
          },
        });

        if (userAuth.email) {
          this.handleLoggedInUser(user);
        } else {
          userAuth.updateEmail(providerData.email).then(() => {
            this.handleLoggedInUser(user);
          });
        }
      }
    });
  }

  componentWillUnmount() {
    this.removeAuthListener();
  }

  handleLoggedInUser = (user) => {
    const { loginUserHandler } = this.props;
    loginUserHandler(user);
  };

  authWithGoogle = () => {
    app
      .auth()
      .signInWithPopup(googleProvider)
      .then((user, error) => {
        if (error) {
          toastr.error('Login failed', error.message || error);
        }
      });
  };

  authWithFacebook = () => {
    app
      .auth()
      .signInWithPopup(facebookProvider)
      .then((user, error) => {
        if (error) {
          toastr.error('Login failed', error.message || error);
        }
      })
      .catch((error) => {
        if (error.code === 'auth/account-exists-with-different-credential') {
          const pendingCred = error.credential;
          const { email } = error;
          app
            .auth()
            .fetchSignInMethodsForEmail(email)
            .then((methods) => {
              if (methods[0] === 'password') {
                this.setState({
                  passwordForm: {
                    email,
                    pendingCred,
                  },
                });
              } else {
                const provider = this.getProviderForProviderId(methods[0]);
                app
                  .auth()
                  .signInWithPopup(provider)
                  .then((result) => {
                    result.user.linkAndRetrieveDataWithCredential(pendingCred);
                  });
              }
            });
        }
      });
  };

  getProviderForProviderId = (method) => method === 'google.com' && googleProvider;

  editHandler = (type, value) => {
    const { editor } = this.state;
    this.setState({
      editor: {
        ...editor,
        [type]: value,
      },
    });
  };

  validateRegister = (account) => {
    const { emailLogin } = this.state;
    const requiredFields = [
      {
        field: _.get(account, 'email'),
        text: 'Email',
      },
      {
        field: _.get(account, 'password'),
        text: 'Password',
      },
    ];
    if (emailLogin === 'register') {
      requiredFields.push({
        field: _.get(account, 'rePassword'),
        text: 'Confirm Password',
      });
    }
    let result = true;
    _.forEach(requiredFields, (item) => {
      if (result && !item.field) {
        result = false;
        toastr.error('Error', `${item.text} is empty.`);
      }
    });
    if (result && account.email && !isEmailValid(account.email)) {
      result = false;
      toastr.error('Error', 'Wrong email format! Please try again.');
    }
    if (result && emailLogin === 'register' && account.password !== account.rePassword) {
      result = false;
      toastr.error('Error', 'Password and Confirm Password does not match! Please try again.');
    }
    if (result && (_.size(account.password) < 6 || _.size(account.password) > 16)) {
      result = false;
      toastr.error('Error', 'Your password must be between 6-16 characters.');
    }
    return result;
  };

  authWithEmail = (type) => {
    const { editor } = this.state;
    if (!this.validateRegister(editor)) {
      return;
    }
    const { email, password } = editor;
    if (type === 'register') {
      app
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(() => {
          this.setState({
            emailLogin: undefined,
            editor: undefined,
          });
        })
        .catch((error) => {
          toastr.error('Error', error.message || error);
        });
    } else {
      app
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          this.setState({
            emailLogin: undefined,
            editor: undefined,
          });
        })
        .catch((error) => {
          toastr.error('Error', error.message || error);
        });
    }
  };

  passwordHandler = () => {
    const { passwordForm } = this.state;
    const { email, password, pendingCred } = passwordForm;
    if (password) {
      app
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(({ user }) => {
          this.setState({
            passwordForm: undefined,
          });
          return user.linkAndRetrieveDataWithCredential(pendingCred);
        })
        .catch((error) => {
          toastr.error('Error', error.message || error);
        });
    } else {
      toastr.error('Error', 'Please input your password');
    }
  };

  renderLoading = () => (
    <div className="sk-cube-grid">
      <div className="sk-cube sk-cube1" />
      <div className="sk-cube sk-cube2" />
      <div className="sk-cube sk-cube3" />
      <div className="sk-cube sk-cube4" />
      <div className="sk-cube sk-cube5" />
      <div className="sk-cube sk-cube6" />
      <div className="sk-cube sk-cube7" />
      <div className="sk-cube sk-cube8" />
      <div className="sk-cube sk-cube9" />
      QSS
    </div>
  );

  renderFooter = () => (
    <div
      style={{
        position: 'fixed',
        right: 0,
        bottom: 0,
        left: 0,
        padding: '1rem',
        textAlign: 'center',
        background: '#F2F2F2',
        fontFamily: 'sans-serif, Arial, Verdana, "Trebuchet MS"',
      }}
    >
      <center
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Image
          src={`${RESOURCES}/images/favicon.ico`}
          height={20}
          style={{
            marginRight: 5,
          }}
        />
        <b>
          <span
            style={{
              color: 'green',
            }}
          >
            Q
          </span>
          uick&nbsp;
          <span
            style={{
              color: 'green',
            }}
          >
            S
          </span>
          tart&nbsp;
          <span
            style={{
              color: 'green',
            }}
          >
            S
          </span>
          ystem v{packageJson.version}
        </b>
      </center>
    </div>
  );

  renderLogin = () => (
    <center
      style={{
        margin: 'auto',
      }}
    >
      <Button
        style={{
          minWidth: 200,
          margin: 5,
          marginTop: 20,
        }}
        color="google plus"
        onClick={this.authWithGoogle}
      >
        <Icon name="google" />
        Login with Google
      </Button>
      {/*
      <Button style={{ minWidth: 200, margin: 5, marginTop: 20 }} color="facebook" onClick={this.authWithFacebook}>
        <Icon name="facebook" />
        Login with Facebook
      </Button>
      <Button style={{ minWidth: 200, margin: 5, marginTop: 20 }} color='violet'>
        <Icon name="yahoo" />
        Login with Yahoo
      </Button>
      <Button style={{ minWidth: 200, margin: 5, marginTop: 20 }} color='green'>
        <Icon name="microsoft" />
        Login with Microsoft
      </Button>
      */}
      <Button
        style={{
          minWidth: 200,
          margin: 5,
          marginTop: 20,
        }}
        primary
        onClick={() =>
          this.setState({
            emailLogin: 'login',
          })
        }
      >
        <Icon name="mail" />
        Login with Email
      </Button>
    </center>
  );

  render() {
    const { user, screens } = this.props;
    const { handling, email } = user;
    const { editor, emailLogin, passwordForm } = this.state;
    if (handling) {
      return this.renderLoading();
    }

    return (
      <div
        style={{
          background: 'black',
        }}
      >
        <div
          style={{
            height: '100vh',
            backgroundImage: `url(${RESOURCES}/images/${email ? 'home.jpg' : 'login.jpg'})`,
            backgroundSize: `${email ? 'cover' : 'contain'}`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          {passwordForm ? (
            <Modal
              open
              closeIcon={
                <Icon
                  name="close"
                  color="red"
                  size="large"
                  onClick={() =>
                    this.setState({
                      emailLogin: undefined,
                    })
                  }
                />
              }
            >
              <Modal.Header>{`Enter your password of ${passwordForm.email}`}</Modal.Header>
              <Modal.Content scrolling>
                <Form>
                  <Form.Input
                    required
                    type="password"
                    label="Password"
                    placeholder="Please input your password"
                    value={_.get(passwordForm, 'password', '')}
                    onChange={(e, { value }) =>
                      this.setState({
                        passwordForm: {
                          ...passwordForm,
                          password: value,
                        },
                      })
                    }
                  />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button primary onClick={() => this.passwordHandler()}>
                  Login
                </Button>
              </Modal.Actions>
            </Modal>
          ) : null}
          {emailLogin ? (
            <Modal
              open
              closeIcon={
                <Icon
                  name="close"
                  color="red"
                  size="large"
                  onClick={() =>
                    this.setState({
                      emailLogin: undefined,
                    })
                  }
                />
              }
            >
              <Modal.Header>{emailLogin === 'register' ? 'Register' : 'Login'}</Modal.Header>
              <Modal.Content scrolling>
                <Form>
                  <Form.Input
                    required
                    label="Email"
                    placeholder="Please input your email"
                    value={_.get(editor, 'email', '')}
                    onChange={(e, { value }) => this.editHandler('email', value)}
                  />
                  <Form.Input
                    required
                    type="password"
                    label="Password"
                    placeholder="Please input your password"
                    value={_.get(editor, 'password', '')}
                    onChange={(e, { value }) => this.editHandler('password', value)}
                  />
                  {emailLogin === 'register' ? (
                    <Form.Input
                      required
                      type="password"
                      label="Confirm Password"
                      placeholder="Please input your confirm password"
                      value={_.get(editor, 'rePassword', '')}
                      onChange={(e, { value }) => this.editHandler('rePassword', value)}
                    />
                  ) : null}
                </Form>
              </Modal.Content>
              <Modal.Actions>
                {/* {emailLogin === 'register' ?
                  <Button color="brown" onClick={() => this.setState({ emailLogin: 'login' })}>
                    Already have an account? Login here
                </Button>
                  :
                  <Button color="brown" onClick={() => this.setState({ emailLogin: 'register' })}>
                    Don't have an account? Register here
                </Button>} */}
                <Button
                  primary
                  onClick={() =>
                    this.authWithEmail(emailLogin === 'register' ? 'register' : 'login')
                  }
                >
                  {emailLogin === 'register' ? 'Register' : 'Login'}
                </Button>
              </Modal.Actions>
            </Modal>
          ) : null}
          {screens.home ? null : this.renderLoading()}
          <BrowserRouter
            {...(BASENAME && {
              basename: BASENAME,
            })}
          >
            <div>
              <Route
                exact
                path="/:shopID/review/:phone"
                render={(props) => (
                  <Segment>
                    <Submit {...props} />
                  </Segment>
                )}
              />
              <Route
                exact
                path="/:shopID"
                render={(props) => (email ? <Home {...props} /> : this.renderLogin())}
              />
              <Route
                exact
                path="/"
                render={(props) => (email ? <Home {...props} /> : this.renderLogin())}
              />
            </div>
          </BrowserRouter>
        </div>
        {this.renderFooter()}
      </div>
    );
  }
}

const mapStateToProps = ({ user, screens }) => ({
  user,
  screens,
});

const mapDispatchToProps = (dispatch) => ({
  loginUserHandler: (user) =>
    dispatch({
      type: USER.handlers.login,
      ...user,
    }),
});

App.propTypes = {
  loginUserHandler: PropTypes.func.isRequired,
  user: PropTypes.shape({
    handling: PropTypes.bool.isRequired,
    email: PropTypes.string,
  }).isRequired,
  screens: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
