import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  Component,
} from 'react';
import ReactApexChart from 'react-apexcharts';
import DatePicker from 'react-datepicker';
import {
  connect,
} from 'react-redux';
import {
  toastr,
} from 'react-redux-toastr';
import {
  Button,
  Form, Header,
} from 'semantic-ui-react';

class CustomerMonthly extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateFrom: moment().subtract(3, 'month').startOf('month').valueOf(),
      dateTo: moment().endOf('month').valueOf(),
      categorySeries: [],
      dataSeries: [],
      dataSeriesNew: [],
      dataSeriesReturn: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({
      loading: false,
      ...this.getData(),
    });
  }

  getData = () => {
    const {
      customers,
    } = this.props;
    const {
      dateFrom,
      dateTo,
    } = this.state;

    const categorySeries = [];
    const dataSeries = [];
    const dataSeriesNew = [];
    const dataSeriesReturn = [];

    const to = moment(dateTo);
    const dateCurr = moment(dateFrom);

    while (dateCurr <= to) {
      const year = dateCurr.year();
      const month = dateCurr.month();

      categorySeries.push(`${month + 1}/${year}`);

      let count = 0;
      let countNew = 0;
      let countReturn = 0;
      _.forEach(customers, (customer) => {
        const hasVisit = _.some(customer.visits, (visit) => (
          visit.time
          && moment(visit.time).year() === year
          && moment(visit.time).month() === month
        ));
        if (hasVisit) {
          count += 1;
          const numVisits = _.size(customer.visits);
          if (numVisits === 1) {
            countNew += 1;
          } else if (numVisits > 1) {
            countReturn += 1;
          }
        }
      });
      dataSeries.push(count);
      dataSeriesNew.push(countNew);
      dataSeriesReturn.push(countReturn);

      dateCurr.add(1, 'month');
    }

    return {
      categorySeries,
      dataSeries,
      dataSeriesNew,
      dataSeriesReturn,
    };
  }

  isValidChart = () => {
    const {
      dateFrom,
      dateTo,
    } = this.state;
    let result = true;
    if (dateFrom > dateTo) {
      toastr.warning('Date From should smaller than Date To');
      result = false;
    }
    return result;
  }

  renderFilter = () => {
    const {
      dateFrom,
      dateTo,
      loading,
    } = this.state;

    return (
      <Form>
        <Form.Group inline>
          <Form.Field required>
            <label>Date from</label>
            <DatePicker
              selected={dateFrom}
              onChange={(date) => {
                this.setState({
                  dateFrom: moment(date).startOf('month').valueOf(),
                });
              }}
            />
          </Form.Field>
          <Form.Field required>
            <label>Date to</label>
            <DatePicker
              selected={dateTo}
              onChange={(date) => {
                this.setState({
                  dateTo: moment(date).endOf('month').valueOf(),
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <Button
              primary
              style={{
                marginTop: 23,
              }}
              loading={loading}
              disabled={loading}
              onClick={() => {
                if (!this.isValidChart()) {
                  return;
                }
                this.setState({
                  loading: true,
                });
                this.setState({
                  loading: false,
                  ...this.getData(),
                });
              }}
            >
              Search
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }

  renderChart = () => {
    const {
      categorySeries,
      dataSeries,
      dataSeriesNew,
      dataSeriesReturn,
    } = this.state;

    return (
      <ReactApexChart
        series={
          [
            {
              name: 'Total',
              data: dataSeries,
            },
            {
              name: 'New',
              data: dataSeriesNew,
            },
            {
              name: 'Return',
              data: dataSeriesReturn,
            },
          ]
        }
        options={
          {
            chart: {
              type: 'bar',
            },
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent'],
            },
            xaxis: {
              categories: categorySeries,
            },
            yaxis: {
              title: {
                text: 'Number of Customers',
              },
            },
            fill: {
              opacity: 1,
            },
            tooltip: {
              y: {
                formatter: (val) => val,
              },
            },
          }
        }
        type="bar"
        height={400}
      />
    );
  }

  render() {
    return (
      <div>
        <Header as="h3">
          Customer Monthly Statistics
        </Header>
        {this.renderFilter()}
        {this.renderChart()}
      </div>
    );
  }
}

CustomerMonthly.propTypes = {
  customers: PropTypes.object.isRequired,
};

const mapStateToProps = ({
  shop,
}) => ({
  customers: shop.customers || {},
});

export default connect(mapStateToProps)(CustomerMonthly);
