import 'moment-timezone';

import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  Component,
} from 'react';
import {
  connect,
} from 'react-redux';
import {
  bindActionCreators,
} from 'redux';
import {
  Button, Dropdown,
  Form, Icon, Modal,
  Table,
} from 'semantic-ui-react';

import {
  getTextTimeZone,
} from '../../../libs/utils';
import {
  updateCustomersHandler,
} from '../../../redux/actions/shop';

class Visit extends Component {
  static propTypes = {
    closeHandler: PropTypes.func.isRequired,
    customerID: PropTypes.string.isRequired,
    visits: PropTypes.arrayOf(PropTypes.object),
    isCustomer: PropTypes.bool,
  }

  constructor(props) {
    super(props);

    const {
      visits,
    } = this.props;
    this.state = {
      visits: _.cloneDeep(_.orderBy(visits, 'time', 'desc')),
    };
  }

  getPayMethod = (method) => {
    const methods = [
      {
        key: 'creditcard', value: 'creditcard', text: 'Credit Card',
      },
      {
        key: 'cash', value: 'cash', text: 'Cash',
      },
      {
        key: 'giftcard', value: 'giftcard', text: 'Gift Card',
      },
    ];
    return _.get(_.find(methods, (methodItem) => methodItem.value === method), 'text', 'Credit Card');
  }

  editHandler = (field, value, index) => {
    const {
      visits,
    } = this.state;
    visits[index][field] = value;
    this.setState({
      visits,
    });
  }

  removeHandler = (index) => {
    const {
      visits,
    } = this.state;
    visits.splice(index, 1);
    this.setState({
      visits,
    });
  }

  saveHandler = () => {
    const {
      customerID, updateCustomersHandler, closeHandler,
    } = this.props;
    const {
      visits,
    } = this.state;
    updateCustomersHandler(customerID, {
      visits,
    }, closeHandler);
  }

  renderVisits = () => {
    const {
      services,
      techs,
      closeHandler,
      isCustomer,
      user,
      members,
      timeZone,
    } = this.props;
    const {
      visits,
    } = this.state;
    const isAdmin = _.get(user.roles, 'admin');
    const isModerator = _.get(members, [user.email, 'role']) === 'moderator' || isAdmin;
    const isOwner = _.get(members, [user.email, 'role']) === 'owner' || isAdmin;

    const serviceOptions = _.map(services, (service, serviceKey) => ({
      key: serviceKey,
      value: serviceKey,
      text: service.name,
    }));
    const techOptions = _.map(techs, (tech, techKey) => ({
      key: techKey,
      value: techKey,
      text: tech.name,
    }));

    return (
      <Modal
        open
        size="fullscreen"
        style={{
          top: 30, bottom: 150,
        }}
        closeIcon={(
          <Icon
            name="close" color="red" size="large"
            onClick={closeHandler}
          />
        )}
      >
        <Modal.Header>Visits</Modal.Header>
        <Modal.Content style={{
          height: '100%', overflow: 'auto',
        }}
        >
          <Table
            color="blue" compact unstackable
            celled striped selectable
            textAlign="center"
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Sign-In & Check-Out & Duration</Table.HeaderCell>
                <Table.HeaderCell>Services & Points</Table.HeaderCell>
                <Table.HeaderCell>Technicians</Table.HeaderCell>
                {isCustomer ? null : <Table.HeaderCell />}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(visits, (visit, index) => {
                const timeVisit = visit.time ? new Date(_.get(visit, 'time', '')) : '';
                const timeCheckOut = visit.timeCheckOut ? new Date(_.get(visit, 'timeCheckOut', '')) : '';
                return (
                  <Table.Row key={index}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>
                      <p>
                        {moment.tz(timeVisit, timeZone).format('MM/DD/YYYY hh:mm')}
                        {` ${getTextTimeZone(timeZone)}`}
                      </p>
                      <p>
                        {timeCheckOut && moment.tz(timeCheckOut, timeZone).format('MM/DD/YYYY hh:mm')}
                        {` ${getTextTimeZone(timeZone)}`}
                      </p>
                      <p>{timeCheckOut && moment.duration(moment(timeCheckOut).diff(moment(timeVisit))).humanize()}</p>
                    </Table.Cell>
                    <Table.Cell>
                      <Dropdown
                        clearable
                        placeholder="Choose Services"
                        multiple
                        search
                        selection
                        options={serviceOptions}
                        value={_.get(visit, 'services', [])}
                        onChange={(e, {
                          value,
                        }) => this.editHandler('services', value, index)}
                      />
                      <hr />
                      {isModerator || isOwner ? (
                        <Form.Input
                          placeholder="Points"
                          value={_.get(visit, 'points', '')}
                          onChange={(e, {
                            value,
                          }) => this.editHandler('points', value, index)}
                        />
                      ) : _.get(visit, 'points', '')}
                      <b>
                        Paid by
                        {' '}
                        {' '}
                        {this.getPayMethod(visit.payMethod)}
                      </b>
                    </Table.Cell>
                    <Table.Cell>
                      {_.map(_.get(visit, 'services'), (service) => {
                        const serviceName = _.get(services, [service, 'name']);
                        const serviceTechs = _.get(visit, ['techs', service], []);
                        return (
                          <div key={service}>
                            <div style={{
                              display: 'flex', alignItems: 'center',
                            }}
                            >
                              <div style={{
                                marginRight: 5, minWidth: 50,
                              }}
                              >
                                {serviceName}
                              </div>
                              <div>
                                <Dropdown
                                  clearable
                                  placeholder="Choose Technicians"
                                  multiple
                                  search
                                  selection
                                  options={techOptions}
                                  value={serviceTechs}
                                  onChange={(e, {
                                    value,
                                  }) => {
                                    visits[index].techs = {
                                      ...visits[index].techs,
                                      [service]: value,
                                    };
                                    this.setState({
                                      visits,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <hr />
                          </div>
                        );
                      })}
                    </Table.Cell>
                    {isCustomer ? null : (
                      <Table.Cell>
                        <Button
                          color="red"
                          icon
                          onClick={() => this.removeHandler(index)}
                        >
                          <Icon name="trash" />
                        </Button>
                      </Table.Cell>
                    )}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Modal.Content>
        {isCustomer ? null : (
          <Modal.Actions>
            <Button primary onClick={this.saveHandler}>
              <Icon name="save" />
              Save
            </Button>
          </Modal.Actions>
        )}
      </Modal>
    );
  }

  render() {
    return (
      <div>
        {this.renderVisits()}
      </div>
    );
  }
}

const mapStateToProps = ({
  user, shop,
}) => ({
  user,
  members: shop.shopData.members || {},
  services: shop.shopData.services || {},
  timeZone: shop.shopData.timeZone || '',
  techs: shop.shopData.techs || {},
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateCustomersHandler,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Visit);
