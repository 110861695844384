import {
  saveAs,
} from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  Component,
} from 'react';
import DatePicker from 'react-datepicker';
import ReactGA from 'react-ga';
import {
  connect,
} from 'react-redux';
import {
  toastr,
} from 'react-redux-toastr';
import ReactTable from 'react-table';
import {
  bindActionCreators,
} from 'redux';
import {
  Button, Checkbox,
  Form, Icon, Image, Input, Modal, Segment,
} from 'semantic-ui-react';

import {
  post,
} from '../libs/api';
import {
  db,
  deleteField,
} from '../libs/firebase';
import {
  reactTableProps, removeAccents, timeZones,
} from '../libs/utils';
import {
  removeShopsHandler, updateShopsHandler,
} from '../redux/actions/shops';

class ShopList extends Component {
  static propTypes = {
    user: PropTypes.shape({
      roles: PropTypes.object,
      list: PropTypes.object,
    }).isRequired,
    shops: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      editor: undefined,
      smsMonth: {},
    };
  }

  componentDidMount() {
    ReactGA.ga('send', 'pageview', '/');

    post('/smsMonthAll').then((res) => {
      if (res) {
        this.setState({
          smsMonth: res.data,
        });
      }
    }).catch((error) => {
      toastr.error('Error', `${error?.response?.data || error.message || error} - Failed to get monthly sms`);
    });
  }

  clearState = () => {
    this.setState({
      editor: undefined,
      shopID: undefined,
      isNew: undefined,
      isWaiting: undefined,
    });
  }

  editHandler = (field, value) => {
    const {
      editor, isNew,
    } = this.state;
    editor[field] = value;
    if (isNew && field === 'name') {
      this.setState({
        editor,
        shopID: _.replace(_.toLower(removeAccents(value)), /[^a-z0-9]+/ig, ''),
      });
    } else {
      this.setState({
        editor,
      });
    }
  }

  validateHandler = () => {
    const {
      shops,
    } = this.props;
    const {
      editor, shopID, isNew,
    } = this.state;
    const requiredFields = [
      {
        field: editor.name, text: 'Shop Name',
      },
      {
        field: editor.dateCreated, text: 'Date Created',
      },
      {
        field: editor.smsLimit, text: 'SMS Limit',
      },
      {
        field: editor.smsChar, text: 'SMS Character Limit',
      },
      {
        field: editor.timeZone, text: 'Time Zone',
      },
    ];
    let result = true;
    if (isNew && shops[shopID] && !shops[shopID].deleted) {
      result = false;
      toastr.warning(`Shop ID must be unique! ${shopID} already existed.`);
    }
    const reg = /^[a-z0-9]+$/ig;
    if (isNew && !reg.test(shopID)) {
      result = false;
      toastr.warning('Shop ID must not have special characters!');
    }
    _.forEach(requiredFields, (item) => {
      if (result && !item.field) {
        result = false;
        toastr.warning(`${item.text} is required!`);
      }
    });
    return result;
  }

  saveHandler = () => {
    const {
      updateShopsHandler,
    } = this.props;
    const {
      editor, shopID,
    } = this.state;
    if (!this.validateHandler(editor)) { return; }
    this.setState({
      isWaiting: true,
    });
    updateShopsHandler(shopID, {
      ...editor,
      shopID,
    }, () => {
      this.clearState();
    });
  }

  removeHandler = () => {
    const {
      removeShopsHandler,
    } = this.props;
    const {
      shopID,
    } = this.state;
    this.setState({
      isWaiting: true,
    });
    removeShopsHandler(shopID, this.clearState);
  }

  renderBasicCell = (value, textAlign) => (
    <div style={{
      textAlign, whiteSpace: 'pre-line', cursor: 'pointer',
    }}
    >
      {value}
    </div>
  );

  renderEditor = () => {
    const {
      editor, shopID, isNew, isWaiting,
    } = this.state;
    if (editor) {
      const {
        logo, name, phone, address, facebook, google, yelp,
        dateCreated, timeZone, active,
        smsLimit, smsChar, smsNumber, enableSms, enablePointbyVisit, noPromotions,
        smsLimitGroup,
      } = editor;
      return (
        <Modal
          className="custom"
          size="fullscreen"
          open
          onClose={this.clearState}
          closeIcon={(
            <Icon
              name="close"
              color="red"
              size="large"
              onClick={this.clearState}
            />
          )}
        >
          <Modal.Header>{name || ''}</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Group widths="equal">
                <Form.Input
                  required
                  label="Shop Name"
                  placeholder="Input Shop Name"
                  value={name || ''}
                  onChange={(e, {
                    value,
                  }) => this.editHandler('name', value)}
                />
                <Form.Field>
                  <label>Shop ID</label>
                  {isNew ? (
                    <Input
                      required
                      placeholder="Input Shop ID (no space, no special character)"
                      value={shopID || ''}
                      onChange={(e, {
                        value,
                      }) => this.setState({
                        shopID: value,
                      })}
                    />
                  ) : shopID}
                </Form.Field>
                <Form.Field required>
                  <label>Date Created</label>
                  <DatePicker
                    placeholderText="Input Date Created"
                    dateFormat="MM/dd/yyyy"
                    {...(dateCreated && {
                      selected: new Date(dateCreated),
                    })}
                    onChange={(date) => this.editHandler('dateCreated', date.getTime())}
                  />
                </Form.Field>
                <Form.Dropdown
                  required
                  label="Time Zone"
                  placeholder="Select Time Zone"
                  selection
                  search
                  options={timeZones}
                  value={timeZone || ''}
                  onChange={(e, {
                    value,
                  }) => this.editHandler('timeZone', value)}
                />
                <Form.Field>
                  <label>Inactive/Active</label>
                  <Checkbox
                    toggle
                    checked={active || false}
                    onChange={(e, {
                      checked,
                    }) => this.editHandler('active', checked)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  required
                  label="SMS Limit"
                  placeholder="Input the maximum SMS that shop can send out"
                  value={smsLimit || ''}
                  onChange={(e, {
                    value,
                  }) => this.editHandler('smsLimit', value)}
                />
                <Form.Input
                  required
                  label="SMS Character Limit"
                  placeholder="Input the maximum characters per SMS"
                  value={smsChar || ''}
                  onChange={(e, {
                    value,
                  }) => this.editHandler('smsChar', value)}
                />
                <Form.Input
                  label="SMS Number"
                  placeholder="Input the SMS number to send out"
                  value={smsNumber || ''}
                  onChange={(e, {
                    value,
                  }) => this.editHandler('smsNumber', value)}
                />
                <Form.Field>
                  <label>SMS Enabled</label>
                  <Checkbox
                    toggle
                    checked={enableSms || false}
                    onChange={(e, {
                      checked,
                    }) => this.editHandler('enableSms', checked)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Point by Visit</label>
                  <Checkbox
                    toggle
                    checked={enablePointbyVisit || false}
                    onChange={(e, {
                      checked,
                    }) => this.editHandler('enablePointbyVisit', checked)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label>No Promotions</label>
                  <Checkbox
                    toggle
                    checked={noPromotions || false}
                    onChange={(e, {
                      checked,
                    }) => this.editHandler('noPromotions', checked)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Group SMS Limit</label>
                  <Checkbox
                    toggle
                    checked={smsLimitGroup || false}
                    onChange={(e, {
                      checked,
                    }) => this.editHandler('smsLimitGroup', checked)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Input
                label="Shop Phone"
                placeholder="Input Shop Phone"
                value={phone || ''}
                onChange={(e, {
                  value,
                }) => this.editHandler('phone', value)}
              />
              <Form.TextArea
                label="Shop Address"
                placeholder="Input Shop Address"
                value={address || ''}
                onChange={(e, {
                  value,
                }) => this.editHandler('address', value)}
              />
              <Form.TextArea
                label="Shop Logo URL"
                placeholder="Input URL for Shop Logo"
                value={logo || ''}
                onChange={(e, {
                  value,
                }) => this.editHandler('logo', value)}
              />
              <Form.TextArea
                label="Facebook URL"
                placeholder="Input Facebook URL"
                value={facebook || ''}
                onChange={(e, {
                  value,
                }) => this.editHandler('facebook', value)}
              />
              <Form.TextArea
                label="Google URL"
                placeholder="Input Google URL"
                value={google || ''}
                onChange={(e, {
                  value,
                }) => this.editHandler('google', value)}
              />
              <Form.TextArea
                label="Yelp URL"
                placeholder="Input Yelp URL"
                value={yelp || ''}
                onChange={(e, {
                  value,
                }) => this.editHandler('yelp', value)}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            {isNew ? null : (
              <Button
                negative icon="trash" onClick={this.removeHandler}
                loading={isWaiting} disabled={isWaiting}
              />
            )}
            <Button
              primary icon="save" onClick={this.saveHandler}
              loading={isWaiting} disabled={isWaiting}
            />
          </Modal.Actions>
        </Modal>
      );
    }
    return null;
  }

  renderShops = () => {
    const {
      shops,
    } = this.props;
    const {
      smsMonth,
    } = this.state;
    const data = _.map(shops, (shop, shopID) => ({
      ...shop,
      shopID,
      smsMonth: smsMonth[shopID] || 0,
    }));

    const columns = [
      {
        width: 110,
        Header: 'Logo',
        headerClassName: 'tableHeader',
        Cell: (row) => (
          <div style={{
            textAlign: 'left',
          }}
          >
            <Image
              src={row.value} style={{
                height: 100,
              }} ui={false}
              size="medium"
            />
          </div>
        ),
        id: 'logo',
        accessor: 'logo',
        filterable: false,
      },
      {
        width: 150,
        Header: 'Shop ID',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
          overflowWrap: 'break-word',
        },
        Cell: (row) => this.renderBasicCell(row.value, 'left'),
        id: 'shopID',
        accessor: 'shopID',
      },
      {
        width: 150,
        Header: 'Name',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
          overflowWrap: 'break-word',
        },
        Cell: (row) => this.renderBasicCell(row.value, 'left'),
        id: 'name',
        accessor: 'name',
      },
      {
        width: 150,
        Header: 'Phone',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
        },
        Cell: (row) => this.renderBasicCell(row.value, 'left'),
        id: 'phone',
        accessor: 'phone',
      },
      {
        width: 200,
        Header: 'Address',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
          overflowWrap: 'break-word',
        },
        Cell: (row) => this.renderBasicCell(row.value, 'left'),
        id: 'address',
        accessor: 'address',
      },
      {
        width: 100,
        Header: 'Date Created',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
        },
        Cell: (row) => this.renderBasicCell(row.value, 'left'),
        id: 'dateCreated',
        accessor: (row) => (row.dateCreated ? moment(row.dateCreated).format('MM/DD/YYYY') : ''),
      },
      {
        width: 120,
        Header: 'Active',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
        },
        Cell: (row) => this.renderBasicCell(row.value, 'left'),
        id: 'active',
        accessor: (row) => (row.active ? 'Yes' : 'No'),
      },
      {
        width: 100,
        Header: 'SMS Count/Limit',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
        },
        Cell: (row) => this.renderBasicCell(row.original.smsLimit ? `${row.value}/${row.original.smsLimit}` : 'unlimited', 'left'),
        id: 'smsMonth',
        accessor: 'smsMonth',
      },
      {
        width: 200,
        Header: '',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
        },
        Cell: (row) => {
          const {
            shopID,
          } = row.original;
          return (
            <div>
              <Button positive as="a" href={`${window.location}${shopID}`}>
                <Icon name="lightning" />
                Go to Shop
              </Button>
              <Button
                primary icon="edit" onClick={() => this.setState({
                  editor: row.original, shopID,
                })}
              />
            </div>
          );
        },
        filterable: false,
      },
    ];
    return (
      <Segment>
        <ReactTable
          {...reactTableProps}
          data={data}
          columns={columns}
        />
      </Segment>
    );
  }

  render() {
    const {
      user,
      shops,
    } = this.props;
    const isAdmin = _.get(user.roles, 'admin');
    if (!isAdmin) { return null; }

    return (
      <div align="center">
        {this.renderEditor()}
        <Button
          positive icon="add" style={{
            marginBottom: 10,
          }}
          onClick={() => {
            const hotfix = false;
            if (hotfix) {
              _.forEach(shops, (shop, shopID) => {
                db.collection('cached').doc('shops').update({
                  [`${shopID}.shopID`]: deleteField,
                }).then(() => {
                  toastr.success(shopID);
                });
              });
              saveAs(new Blob([JSON.stringify(shops)]), 'shops.json');
            }
            this.setState({
              editor: {}, shopID: '', isNew: true,
            });
          }}
        />
        {this.renderShops()}
      </div>
    );
  }
}

const mapStateToProps = ({
  user, shops,
}) => ({
  user,
  shops,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateShopsHandler,
  removeShopsHandler,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShopList);
