import {
  saveAs,
} from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import {
  toastr,
} from 'react-redux-toastr';
import XLSX from 'xlsx';

import {
  post,
} from './api';

export const reactTableProps = {
  className: '-striped -highlight custom-content-table',
  showPagination: true,
  defaultPageSize: 25,
  filterable: true,
  resizable: false,
  defaultFilterMethod: (filter, row) => _.includes(_.toLower(row[filter.id]), _.toLower(filter.value)),
};

export const isEmailValid = (email) => email && (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))(\.*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email);

export const isBirthdayValid = (birthday) => birthday
  && (/^[0-9][0-9]\/[0-9][0-9]$/).test(birthday)
  && moment(`2000/${birthday}`, 'YYYY/MM/DD', true).isValid();

export const isPhoneValid = (phone) => phone && (
  (/^[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$/).test(phone)
  || (/^[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]$/).test(phone)
  || (/^\([0-9][0-9][0-9]\)\s[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]$/).test(phone)
);
export const formatPhone = (rawPhone) => {
  const phone = _.replace(rawPhone, /[()-\s]/g, '');
  return `${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6, 10)}`;
};

export const removeAccents = (str) => str.normalize('NFD')
  .replace(/[\u0300-\u036f]/g, '')
  .replace(/đ/g, 'd').replace(/Đ/g, 'D');

export const defaultSms = () => 'Hi [firstName]. Thank you for your business. Your Reward Points: [totalPoints]. Available Reward to Use $[redeemUsd]. Please provide a feedback of your visit [feedbackLink]';

export const isEnableSms = (shopEnableSms, smsSetupEnableSms, customerEnableSms, requestEnableSms) => shopEnableSms
  && (smsSetupEnableSms === undefined || smsSetupEnableSms)
  && (customerEnableSms === undefined || customerEnableSms)
  && (requestEnableSms === undefined || requestEnableSms);

export const sendSms = ({
  content: rawContent,
  imageUrls = '',
  shopID, phone, firstName, totalPoints, redeemUsd, smsNumber,
  groupID, groupLastSend,
}, onDone = () => { }) => {
  const feedbackLink = `https://quickstartsystem.net/${shopID}/review/${phone}`;
  const smsPhone = `+1${_.replace(phone, /-/g, '')}`;
  let content = _.cloneDeep(rawContent);
  content = _.replace(content, /\[firstName\]/g, firstName);
  content = _.replace(content, /\[totalPoints\]/g, totalPoints);
  content = _.replace(content, /\[redeemUsd\]/g, redeemUsd);
  content = _.replace(content, /\[feedbackLink\]/g, feedbackLink);
  const mediaUrl = _.compact(imageUrls.trim().split(',').map((imageUrl) => imageUrl.trim()));

  post('/sms', {
    shopID,
    from: smsNumber,
    to: smsPhone,
    body: content,
    ...(mediaUrl.length && {
      mediaUrl,
    }),
    groupID,
    groupLastSend,
  }).then((response) => {
    if (response.status === 200) {
      toastr.success('Success', `SMS Sent to ${smsPhone}`);
      onDone();
    } else {
      toastr.error('Error', response.data);
      console.log(response);
      onDone(`Failed to send SMS to ${smsPhone}`);
    }
  }).catch((error) => {
    console.log(error?.response?.data || error.message || error);
    toastr.error('Error', `${error?.response?.data || error.message || error} - Failed to send SMS to ${smsPhone}`);
    onDone(error);
  });
};

export const defaultGroups = [
  {
    id: '1visit',
    name: '1 visit',
    filter: {
      visitFrom: 1, visitTo: 1,
    },
  },
  {
    id: '2to3visits',
    name: '2-3 visits',
    filter: {
      visitFrom: 2, visitTo: 3,
    },
  },
  {
    id: '4ormorevisits',
    name: '4 or more visits',
    filter: {
      visitFrom: 4,
    },
  },
  {
    id: '14days',
    name: '14 days Sign In',
    filter: {
      lastSignInDays: 14,
    },
  },
  {
    id: '21days',
    name: '21 days Sign In',
    filter: {
      lastSignInDays: 21,
    },
  },
  {
    id: '30days',
    name: '30 days Sign In',
    filter: {
      lastSignInDays: 30,
    },
  },
  {
    id: '60days',
    name: '60 days Sign In',
    filter: {
      lastSignInDays: 60,
    },
  },
  {
    id: 'birthJan',
    name: 'Jan Birthday',
    filter: {
      birthMonth: 0,
    },
  },
  {
    id: 'birthFeb',
    name: 'Feb Birthday',
    filter: {
      birthMonth: 1,
    },
  },
  {
    id: 'birthMar',
    name: 'Mar Birthday',
    filter: {
      birthMonth: 2,
    },
  },
  {
    id: 'birthApr',
    name: 'Apr Birthday',
    filter: {
      birthMonth: 3,
    },
  },
  {
    id: 'birthMay',
    name: 'May Birthday',
    filter: {
      birthMonth: 4,
    },
  },
  {
    id: 'birthJun',
    name: 'Jun Birthday',
    filter: {
      birthMonth: 5,
    },
  },
  {
    id: 'birthJul',
    name: 'Jul Birthday',
    filter: {
      birthMonth: 6,
    },
  },
  {
    id: 'birthAug',
    name: 'Aug Birthday',
    filter: {
      birthMonth: 7,
    },
  },
  {
    id: 'birthSep',
    name: 'Sep Birthday',
    filter: {
      birthMonth: 8,
    },
  },
  {
    id: 'birthOct',
    name: 'Oct Birthday',
    filter: {
      birthMonth: 9,
    },
  },
  {
    id: 'birthNov',
    name: 'Nov Birthday',
    filter: {
      birthMonth: 10,
    },
  },
  {
    id: 'birthDec',
    name: 'Dec Birthday',
    filter: {
      birthMonth: 11,
    },
  },
  {
    id: 'allCustomers',
    name: 'All Customers',
    filter: {
      all: true,
    },
  },
];

export const timeZones = [
  {
    key: 'EST', value: 'America/New_York', text: 'EST (Eastern Standard Time)',
  },
  {
    key: 'CST', value: 'America/Chicago', text: 'CST (Central Standard Time)',
  },
  {
    key: 'MST', value: 'America/Phoenix', text: 'MST (Mountain Standard Time)',
  },
  {
    key: 'MDT', value: 'America/Denver', text: 'MDT (Mountain Daylight Time)',
  },
  {
    key: 'PST', value: 'America/Los_Angeles', text: 'PST (Pacific Standard Time)',
  },
  {
    key: 'AKST', value: 'US/Alaska', text: 'AKST (Alaska)',
  },
  {
    key: 'HST', value: 'US/Hawaii', text: 'HST (Hawaii)',
  },
];

export const getTextTimeZone = (timeZone) => _.get(_.find(timeZones, (tz) => tz.value === timeZone), 'key', '');

export const exportToExcel = (csvData, fileName = 'export') => {
  // csvData is an array of object, e.g. [{firstName: 'a1', lastName: 'b1'}, {firstName: 'a2', lastName: 'b2'}]
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = {
    Sheets: {
      data: ws,
    },
    SheetNames: ['data'],
  };
  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx', type: 'array',
  });
  const data = new Blob([excelBuffer], {
    type: fileType,
  });
  saveAs(data, fileName + fileExtension);
};
