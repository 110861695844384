import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import ReactTable from 'react-table';
import { bindActionCreators } from 'redux';
import { Button, Dropdown, Form, Header, Icon, Input, Modal } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';

import { ConfirmModal } from '../../components';
import {
  defaultGroups,
  defaultSms,
  exportToExcel,
  isEnableSms,
  reactTableProps,
  sendSms,
} from '../../libs/utils';
import { updateGroupsHandler } from '../../redux/actions/shop';

class Group extends Component {
  static propTypes = {
    groups: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      editor: undefined,
      isSendingSms: {},
    };
  }

  editHandler = (field, value) => {
    const { editor } = this.state;
    this.setState({
      editor: {
        ...editor,
        [field]: value,
      },
    });
  };

  validateHandler = () => {
    const { editor } = this.state;
    const requiredFields = [
      {
        field: editor.name,
        text: 'Name',
      },
    ];

    let result = true;
    _.forEach(requiredFields, (item) => {
      if (result && !item.field) {
        result = false;
        toastr.warning(`${item.text} is required!`);
      }
    });
    return result;
  };

  removeHandler = () => {
    const { updateGroupsHandler } = this.props;
    const { editor } = this.state;
    updateGroupsHandler(editor.id, 'deleted', () => {
      this.setState({
        editor: undefined,
      });
    });
  };

  saveHandler = (groupCustomers) => {
    if (!this.validateHandler()) {
      return;
    }

    const { groups, updateGroupsHandler } = this.props;
    const { editor } = this.state;
    const id = editor.id || uuid();
    const newGroup = {
      ..._.cloneDeep(groups[id]),
      ..._.cloneDeep(editor),
    };
    newGroup.customers = groupCustomers;
    delete newGroup.copyFrom;

    updateGroupsHandler(id, newGroup, () => {
      this.setState({
        editor: undefined,
      });
    });
  };

  copyHandler = (groups, groupCustomers) => {
    const { editor } = this.state;
    let customers = [];
    _.forEach(editor.copyFrom, (groupId) => {
      customers = _.concat(customers, _.find(groups, (group) => group.id === groupId).customers);
    });
    customers = _.concat(customers, groupCustomers);
    this.editHandler('customers', _.uniq(customers));
  };

  sendSms = (groupInfo) => {
    const { shopID, rewardSetup, customers, smsNumber, updateGroupsHandler } = this.props;
    const { isSendingSms } = this.state;

    this.setState({
      isSendingSms: {
        ...isSendingSms,
        [groupInfo.id]: true,
      },
    });

    const groupCustomers = groupInfo.customers.filter((phone) => {
      if (!customers[phone]) return false;
      if (customers[phone].enableSms === false) return false;
      return true;
    });

    let count = 0;
    let sentCount = 0;
    let errorCount = 0;
    const smsResults = [];

    const checkDone = ({ phone, error }) => {
      if (error) {
        count += 1;
        toastr.warning(
          `Failed to send SMS to ${phone}. Progress: ${count}/${_.size(groupCustomers)}`
        );
        smsResults.push({
          phone,
          status: 'Failed',
        });
      } else if (phone) {
        count += 1;
        toastr.info(`SMS sent to ${phone}. Progress: ${count}/${_.size(groupCustomers)}`);
        smsResults.push({
          phone,
          status: 'Sent',
        });
      } else {
        toastr.info(`${sentCount} sent, ${errorCount} failed`);

        exportToExcel(smsResults, `sms-results-${groupInfo.name}`);
        this.setState({
          isSendingSms: {
            ...isSendingSms,
            [groupInfo.id]: false,
          },
        });

        if (sentCount) {
          updateGroupsHandler(
            groupInfo.id,
            {
              lastSent: new Date().getTime(),
            },
            () => {},
            true
          );
        }
      }
    };

    const doSend = (listCustomers) => {
      const phone = _.head(listCustomers);
      if (phone) {
        const { firstName, visits, rewards } = customers[phone];
        const visitPoints = _.sumBy(visits, (visit) => +visit.points);
        const redeemPoints = _.sumBy(rewards, (reward) => +reward.points);
        const totalPoints = visitPoints - redeemPoints;
        const rewardCount = _.toInteger(totalPoints / rewardSetup.points);
        const redeemUsd = rewardCount * rewardSetup.usd;
        sendSms(
          {
            content: _.get(groupInfo, 'sms', defaultSms()),
            imageUrls: _.get(groupInfo, 'imageUrls', ''),
            shopID,
            phone,
            firstName,
            totalPoints,
            redeemUsd,
            smsNumber,
            groupID: groupInfo.id,
            groupLastSend: _.size(listCustomers) === 1,
          },
          (error) => {
            if (error) {
              errorCount += 1;
            } else {
              sentCount += 1;
            }
            const errMsg = error?.response?.data || error?.message || error;
            checkDone({
              phone,
              error: errMsg,
            });
            const isStop =
              errMsg?.includes('Reach group sms limit') ||
              errMsg?.includes('Reach limit') ||
              errMsg?.includes('Reach character limit');
            if (isStop) {
              checkDone({});
            } else {
              doSend(_.tail(listCustomers));
            }
          }
        );
      } else {
        checkDone({});
      }
    };

    doSend(groupCustomers);
  };

  renderEditor = (groups) => {
    const { editor } = this.state;

    if (editor) {
      const { customers } = this.props;
      const customerOpts = _.map(customers, (member, phone) => ({
        key: phone,
        value: phone,
        text: `${member.firstName} - ${phone}`,
      }));
      const groupOptions = _.map(groups, (group) => ({
        key: group.id,
        value: group.id,
        text: group.name,
      }));

      const { id, isDefault } = editor;
      const groupCustomers = _.filter(editor.customers, (customerId) => customers[customerId]);
      return (
        <Modal
          className="custom"
          open
          onClose={() =>
            this.setState({
              editor: undefined,
            })
          }
          closeIcon={
            <Icon
              name="close"
              color="red"
              size="large"
              onClick={() =>
                this.setState({
                  editor: undefined,
                })
              }
            />
          }
        >
          <Modal.Header>{id ? 'Edit Group' : 'Add Group'}</Modal.Header>
          <Modal.Content>
            <Form
              style={{
                padding: 10,
              }}
            >
              <Form.Input
                label="#"
                type="number"
                placeholder="#"
                value={_.get(editor, 'order', '')}
                onChange={(e, { value }) => this.editHandler('order', value)}
              />
              <Form.Field>
                <label>Name</label>
                <Input
                  disabled={isDefault}
                  required
                  placeholder="Name"
                  value={_.get(editor, 'name', '')}
                  onChange={(e, { value }) => this.editHandler('name', value)}
                />
              </Form.Field>
              <Form.Field>
                <label>Customers</label>
                <Dropdown
                  disabled={isDefault}
                  clearable
                  fluid
                  placeholder="Select Customers"
                  selection
                  search
                  multiple
                  options={customerOpts}
                  value={groupCustomers}
                  onChange={(e, { value }) => this.editHandler('customers', value)}
                />
              </Form.Field>
              <Form.TextArea
                label="SMS Template"
                placeholder="Input SMS Template"
                value={_.get(editor, 'sms', defaultSms())}
                onChange={(e, { value }) => this.editHandler('sms', value)}
              />
              <Form.TextArea
                label="MMS Image Urls"
                placeholder="Input MMS Image Urls"
                value={_.get(editor, 'imageUrls', '')}
                onChange={(e, { value }) => this.editHandler('imageUrls', value)}
              />
              {isDefault ? null : (
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Copy from</label>
                    <Dropdown
                      clearable
                      search
                      selection
                      multiple
                      options={groupOptions}
                      value={editor.copyFrom || []}
                      onChange={(e, { value }) => this.editHandler('copyFrom', value)}
                    />
                    <Button primary onClick={() => this.copyHandler(groups, groupCustomers)}>
                      <Icon name="copy" />
                      Copy
                    </Button>
                  </Form.Field>
                </Form.Group>
              )}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            {id ? (
              <Button disabled={isDefault} negative onClick={this.removeHandler}>
                <Icon name="trash" />
                Delete
              </Button>
            ) : null}
            <Button primary onClick={() => this.saveHandler(groupCustomers)}>
              <Icon name="save" />
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }
    return null;
  };

  renderGroups = (groupType, groups, allowAdd) => {
    const { customers, enableSms, noPromotions, smsSetup, smsMonth, smsLimit, timeZone } =
      this.props;
    const { isSendingSms } = this.state;

    const defaultEnableSms = isEnableSms(enableSms, smsSetup.enableSms);

    const columns = [
      {
        id: 'order',
        accessor: 'order',
        Header: '#',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
        },
        maxWidth: 50,
        Cell: (row) => <div>{row.value || row.index + 1}</div>,
      },
      {
        id: 'name',
        accessor: 'name',
        Header: 'Name',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
        },
        Cell: (row) => <div>{row.value}</div>,
      },
      {
        id: 'customers',
        accessor: 'customers',
        Header: 'Customers',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
        },
        Cell: (row) => (
          <div>{_.filter(row.value, (customerId) => customers[customerId]).length} Customers</div>
        ),
      },
      {
        id: 'lastSent',
        accessor: 'lastSent',
        Header: 'Last Sent',
        headerClassName: 'tableHeader',
        style: {
          textAlign: 'center',
        },
        Cell: (row) => (
          <div>{row.value ? moment.tz(row.value, timeZone).format('MM/DD/YYYY') : ''}</div>
        ),
      },
      {
        id: 'edit',
        Header: '',
        headerClassName: 'tableHeader',
        maxWidth: 100,
        style: {
          textAlign: 'center',
        },
        Cell: (row) => (
          <div>
            <Button
              icon="edit"
              primary
              onClick={() =>
                this.setState({
                  editor: row.original,
                })
              }
            />
            <Button
              loading={isSendingSms[row.original.id]}
              disabled={noPromotions || !defaultEnableSms || isSendingSms[row.original.id]}
              icon="send"
              primary
              onClick={() => {
                const numCustomers = _.size(row.original.customers);
                if (smsMonth !== undefined && smsLimit !== undefined) {
                  const smsRenaming = smsLimit - smsMonth;
                  console.log(`smsRenaming: ${smsRenaming}`);
                  if (numCustomers > smsRenaming) {
                    toastr.warning(`Reach limit, sms renaming is ${smsRenaming}`);
                    return;
                  }
                }

                this.setState({
                  openConfirm: {
                    action: () => {
                      this.sendSms(row.original);
                      this.setState({
                        openConfirm: undefined,
                      });
                    },
                    group: row.original.name,
                    numCustomers,
                  },
                });
              }}
            />
          </div>
        ),
        filterable: false,
      },
    ];

    return (
      <div
        style={{
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Header as="h3">{groupType}</Header>
          {allowAdd ? (
            <Button.Group>
              <Button
                positive
                onClick={() =>
                  this.setState({
                    editor: {
                      order: _.size(groups) + 1,
                    },
                  })
                }
              >
                <Icon name="add circle" />
                Add Group
              </Button>
            </Button.Group>
          ) : null}
        </div>
        <ReactTable {...reactTableProps} data={_.orderBy(groups, 'order')} columns={columns} />
      </div>
    );
  };

  render() {
    const { groups, customers } = this.props;
    const { openConfirm } = this.state;

    const defaultGroupIds = _.map(defaultGroups, 'id');

    const cusGroups = _.filter(
      _.map(groups, (group, id) => ({
        ...group,
        id,
      })),
      (group) => !defaultGroupIds.includes(group.id)
    );

    const defGroups = _.map(defaultGroups, (defaultGroup) => {
      const { id, filter } = defaultGroup;

      const groupCustomers = [];
      const { lastSignInDays, birthMonth, visitFrom, visitTo, all } = filter;

      _.forEach(customers, (customer, phone) => {
        if (lastSignInDays) {
          const lastVisit = _.get(_.last(customer.visits), 'time');
          if (lastVisit && moment().diff(lastVisit, 'days') >= lastSignInDays) {
            groupCustomers.push(phone);
          }
        }

        if (
          birthMonth !== undefined &&
          customer.birthday &&
          moment(customer.birthday).month() === birthMonth
        ) {
          groupCustomers.push(phone);
        }

        if (visitFrom) {
          const numVisits = _.size(customer.visits);
          if (visitTo) {
            if (numVisits >= visitFrom && numVisits <= visitTo) {
              groupCustomers.push(phone);
            }
          } else if (numVisits >= visitFrom) {
            groupCustomers.push(phone);
          }
        }

        if (all) {
          groupCustomers.push(phone);
        }
      });

      return {
        ...defaultGroup,
        ...groups[id],
        isDefault: filter !== undefined,
        customers: groupCustomers,
      };
    });

    return (
      <div>
        {openConfirm && (
          <ConfirmModal
            positiveNext
            closeHandler={() =>
              this.setState({
                openConfirm: undefined,
              })
            }
            confirmHandler={openConfirm.action}
            title={`Confirm to send sms to all customers in "${openConfirm.group}"`}
            content={`"${openConfirm.group}" group has ${openConfirm.numCustomers} customers`}
          />
        )}
        {this.renderEditor(_.concat(defGroups, cusGroups))}
        {this.renderGroups(`Default Groups (${_.size(defGroups)})`, defGroups)}
        {this.renderGroups(`Custom Groups (${_.size(cusGroups)})`, cusGroups, true)}
      </div>
    );
  }
}

const mapStateToProps = ({ shop }) => ({
  shopID: shop.shopID,
  customers: shop.customers || {},
  groups: shop.groups || {},
  smsSetup: shop.shopData.smsSetup || {},
  enableSms: shop.shopData.enableSms,
  noPromotions: shop.shopData.noPromotions,
  smsNumber: shop.shopData.smsNumber,
  rewardSetup: shop.shopData.rewardSetup || {
    usd: 5,
    points: 200,
  },
  timeZone: shop.shopData.timeZone,
  smsLimit: shop.shopData.smsLimit,
  smsMonth: shop.smsMonth,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateGroupsHandler,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Group);
