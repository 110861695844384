import {
  toastr,
} from 'react-redux-toastr';

export const successHandler = (title, message, onSuccess) => {
  toastr.success(title, message);
  if (onSuccess) { onSuccess(); }
};

export const errorHandler = (title, error, onError) => {
  toastr.error(title, error.message || error);
  console.log(title, error);
  if (onError) { onError(error); }
};

export const warningHandler = (title, warning) => {
  toastr.warning(title, warning);
  console.log(title, warning);
};
