import _ from 'lodash';
import { eventChannel } from 'redux-saga';
import { all, call, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import { post } from '../../libs/api';
import { db, deleteField } from '../../libs/firebase';
import { errorHandler, successHandler } from '../../libs/log';
import { formatPhone } from '../../libs/utils';

import { SHOP } from './constants';

function* update(data = {}) {
  yield put({
    type: SHOP.update,
    data,
  });
}

function* fetchCustomers() {
  const { shopID } = _.get(yield select(), 'shop');
  const ref = db.collection('shops').doc(shopID).collection('customers');
  const docs = yield call([ref, ref.get]);
  const customers = {};
  docs.forEach((doc) => {
    const phone = formatPhone(doc.id);
    customers[phone] = {
      ...doc.data(),
      phone,
    };
  });
  yield* update({
    customers,
  });
}

function* fetchGroups() {
  const { shopID } = _.get(yield select(), 'shop');
  const ref = db.collection('shops').doc(shopID).collection('groups');
  const docs = yield call([ref, ref.get]);
  const groups = {};
  docs.forEach((doc) => {
    groups[doc.id] = {
      ...doc.data(),
      id: doc.id,
    };
  });
  yield* update({
    groups,
  });
}

function* listenRequests() {
  const { shopID, shopData, customers } = _.get(yield select(), 'shop');
  const channel = eventChannel((emitter) => {
    const listener = db
      .collection('shops')
      .doc(shopID)
      .collection('requests')
      .onSnapshot(
        (snapshot) => {
          const requests = [];
          snapshot.forEach((doc) => {
            requests.push({
              ...doc.data(),
              phone: doc.id,
            });
          });

          if (requests.length) {
            db.collection('shops').doc(shopID).update({
              hasRequests: true,
            });
          } else {
            db.collection('shops').doc(shopID).update({
              hasRequests: false,
            });
          }

          _.forEach(_.chunk(requests, 500), emitter);
        },
        (error) => {
          console.log(error);
          // toastr.error(error.message);
        }
      );
    return () => listener(); // unsubscribe function
  });

  try {
    while (true) {
      const requests = yield take(channel);

      const numRequests = _.size(requests);
      if (numRequests) {
        const newRequests = {};
        _.forEach(requests, (request) => {
          const { firstName, lastName, phone, email, birthday, checkins, time } = request;
          const services = _.map(checkins, 'service');
          const points = _.sumBy(
            services,
            (service) => +_.get(shopData.services, [service, 'point'], 0)
          );

          newRequests[phone] = {
            ...request,
            ...customers[phone],
            ...(firstName && {
              firstName,
            }),
            ...(lastName && {
              lastName,
            }),
            phone,
            ...(email && {
              email,
            }),
            ...(birthday && {
              birthday,
            }),
            visit: {
              ...request.visit,
              time,
              services,
              points,
            },
          };
        });
        yield* update({
          requests: newRequests,
        });

        const numNewRequests = _.filter(newRequests, (request) => !request.inService).length;
        if (numNewRequests) {
          successHandler('News', `${numNewRequests} customer(s) signed in.`);
        }
      }
    }
  } catch (error) {
    channel.close();
  }
}

function* fetchRequests() {
  yield fork(listenRequests);
}

function* fetchShop({ shopID }) {
  try {
    const ref = db.collection('shops').doc(shopID);
    const shopData = yield call([ref, ref.get]);
    const shop = shopData.data() || {};
    console.log(shop);
    yield* update({
      shopID,
      shopData: shop,
    });

    const members = shop.members || {};
    const { email, roles } = _.get(yield select(), 'user');
    const isAdmin = _.get(roles, 'admin');
    const isModerator = _.get(members[email], 'role') === 'moderator' || isAdmin;
    const isOwner = _.get(members[email], 'role') === 'owner' || isAdmin;
    if (isModerator || isOwner) {
      yield* fetchCustomers();
      yield* fetchGroups();
    }
    yield* fetchRequests();

    if (shop.groups) {
      yield call(
        () =>
          new Promise((resolve, reject) => {
            const batch = db.batch();
            _.forEach(shop.groups, (group, groupID) => {
              batch.set(
                db.collection('shops').doc(shopID).collection('groups').doc(groupID),
                group,
                {
                  mergeFields: _.keys(group),
                }
              );
            });
            batch
              .commit()
              .then(() => {
                resolve(true);
              })
              .catch((error) => {
                errorHandler('Error', error);
                reject(error);
              });
          })
      );
      yield call(
        () =>
          new Promise((resolve, reject) => {
            db.collection('shops')
              .doc(shopID)
              .update({
                groups: deleteField,
              })
              .then(() => {
                resolve(true);
              })
              .catch((error) => {
                errorHandler('Error', error);
                reject(error);
              });
          })
      );

      if (isModerator || isOwner) {
        yield* fetchGroups();
      }
    }

    const smsMonth = yield call(
      () =>
        new Promise((resolve, reject) => {
          post('/smsMonth', {
            shopID,
          })
            .then((res) => resolve(res.data))
            .catch((error) => reject(error));
        })
    );
    yield* update({
      smsMonth,
    });
  } catch (error) {
    console.log(error);
    // errorHandler('Failed to fetch shop', error);
    // app.auth().signOut().then(() => {
    // window.location = '/';
    // });
  }
}

function* updateShop({ data, onSuccess }) {
  try {
    const { shopID, shopData } = _.get(yield select(), 'shop');
    _.forEach(data, (value, path) => {
      if (value === deleteField) {
        _.unset(shopData, path);
      } else {
        _.set(shopData, path, value);
      }
    });

    if (!_.get(shopData, 'createdAt')) {
      _.set(shopData, 'createdAt', new Date());
    }
    _.set(shopData, 'modifiedAt', new Date());
    console.log(shopData);

    yield call(
      () =>
        new Promise((resolve, reject) => {
          db.collection('shops')
            .doc(shopID)
            .set(shopData, {
              mergeFields: _.keys(shopData),
            })
            .then(() => resolve(true))
            .catch((err) => reject(err));
        })
    );
    yield* update({
      shopData,
    });
    successHandler('Done', 'Successfully', onSuccess);
  } catch (error) {
    errorHandler('Failed to update', error);
  }
}

function* updateGroups({ groupID, data, onSuccess, ignoreNoti }) {
  try {
    const { shopID, groups: oldGroups } = _.get(yield select(), 'shop');
    const groups = _.cloneDeep(oldGroups);
    if (data === 'deleted') {
      yield call(
        () =>
          new Promise((resolve, reject) => {
            db.collection('shops')
              .doc(shopID)
              .collection('groups')
              .doc(groupID)
              .delete()
              .then(() => resolve(true))
              .catch((err) => reject(err));
          })
      );

      _.unset(groups, groupID);
    } else {
      const group = _.cloneDeep(data);
      const mergeFields = _.keys(data);

      yield call(
        () =>
          new Promise((resolve, reject) => {
            const batch = db.batch();
            batch.set(db.collection('shops').doc(shopID).collection('groups').doc(groupID), group, {
              mergeFields,
            });
            batch
              .commit()
              .then(() => {
                resolve(true);
              })
              .catch((error) => {
                errorHandler('Error', error);
                reject(error);
              });
          })
      );

      groups[groupID] = {
        ...groups[groupID],
        ...group,
      };
    }
    yield* update({
      groups,
    });
    if (ignoreNoti) {
      onSuccess();
    } else {
      successHandler('Done', 'Successfully', onSuccess);
    }
  } catch (error) {
    console.log(groupID, data);
    errorHandler('Failed to update', error);
  }
}

function* mergeGroups({ data, onSuccess, ignoreNoti }) {
  try {
    const { shopID, groups: oldGroups } = _.get(yield select(), 'shop');
    const groups = _.cloneDeep(oldGroups);
    yield call(
      () =>
        new Promise((resolve, reject) => {
          const batch = db.batch();
          _.forEach(data, (group, groupId) => {
            if (group === 'deleted') {
              batch.delete(db.collection('shops').doc(shopID).collection('groups').doc(groupId));
              delete groups[groupId];
            } else {
              batch.set(
                db.collection('shops').doc(shopID).collection('groups').doc(groupId),
                group
              );
              groups[groupId] = group;
            }
          });
          batch
            .commit()
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              errorHandler('Error', error);
              reject(error);
            });
        })
    );
    yield* update({
      groups,
    });
    if (!ignoreNoti) {
      successHandler('Done', 'Successfully', onSuccess);
    }
  } catch (error) {
    errorHandler('Error', error);
  }
}

function* updateCustomers({ customerID, data, onSuccess, ignoreNoti }) {
  try {
    const { shopID, customers: oldCustomers } = _.get(yield select(), 'shop');
    const customers = _.cloneDeep(oldCustomers);
    if (data === 'deleted') {
      yield call(
        () =>
          new Promise((resolve, reject) => {
            db.collection('shops')
              .doc(shopID)
              .collection('customers')
              .doc(customerID)
              .delete()
              .then(() => resolve(true))
              .catch((err) => reject(err));
          })
      );

      _.unset(customers, customerID);
    } else {
      const customer = _.cloneDeep(data);

      const phone = formatPhone(customer.phone || customers[customerID].phone);
      customer.phoneLastNumbers = _.join(_.takeRight(phone, 4), '');

      const mergeFields = _.keys(data);
      mergeFields.push('phoneLastNumbers');

      yield call(
        () =>
          new Promise((resolve, reject) => {
            const batch = db.batch();
            if (phone !== customerID) {
              batch.delete(
                db.collection('shops').doc(shopID).collection('customers').doc(customerID)
              );
            }
            batch.set(
              db.collection('shops').doc(shopID).collection('customers').doc(phone),
              customer,
              {
                mergeFields,
              }
            );
            batch
              .commit()
              .then(() => {
                resolve(true);
              })
              .catch((error) => {
                errorHandler('Error', error);
                reject(error);
              });
          })
      );

      customers[customerID] = {
        ...customers[customerID],
        ...customer,
      };
    }
    yield* update({
      customers,
    });
    if (ignoreNoti) {
      onSuccess();
    } else {
      successHandler('Done', 'Successfully', onSuccess);
    }
  } catch (error) {
    console.log(customerID, data);
    errorHandler('Failed to update', error);
  }
}

function* mergeCustomers({ data, ignoreNoti }) {
  try {
    const { shopID, customers: oldCustomers } = _.get(yield select(), 'shop');
    const customers = _.cloneDeep(oldCustomers);
    yield call(
      () =>
        new Promise((resolve, reject) => {
          const batch = db.batch();
          _.forEach(data, (customer, customerPhone) => {
            if (customer === 'deleted') {
              batch.delete(
                db.collection('shops').doc(shopID).collection('customers').doc(customerPhone)
              );
              delete customers[customerPhone];
            } else {
              const phone = formatPhone(customer.phone);
              const phoneLastNumbers = _.join(_.takeRight(phone, 4), '');
              const customerData = {
                ...customer,
                phone,
                phoneLastNumbers,
              };
              batch.set(
                db.collection('shops').doc(shopID).collection('customers').doc(phone),
                customerData
              );
              customers[phone] = customerData;
            }
          });
          batch
            .commit()
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              errorHandler('Error', error);
              reject(error);
            });
        })
    );
    yield* update({
      customers,
    });
    if (!ignoreNoti) {
      successHandler('Done', 'Successfully');
    }
  } catch (error) {
    errorHandler('Error', error);
  }
}

function* deleteRequest({ customerID, onSuccess }) {
  const { shopID, requests } = _.get(yield select(), 'shop');
  yield call(
    () =>
      new Promise((resolve, reject) => {
        db.collection('shops')
          .doc(shopID)
          .collection('requests')
          .doc(customerID)
          .delete()
          .then(() => resolve(true))
          .catch((err) => reject(err));
      })
  );

  const newRequests = _.cloneDeep(requests);
  delete newRequests[customerID];
  yield* update({
    requests: newRequests,
  });

  if (onSuccess) {
    onSuccess();
  }
}

export const fetchShopHandler = (shopID) => ({
  type: SHOP.handlers.fetch,
  shopID,
});

export const updateShopHandler = (data, onSuccess) => ({
  type: SHOP.handlers.update,
  data,
  onSuccess,
});

export const updateGroupsHandler = (groupID, data, onSuccess, ignoreNoti) => ({
  type: SHOP.handlers.updateGroups,
  groupID,
  data,
  onSuccess,
  ignoreNoti,
});

export const mergeGroupsHandler = (data, onSuccess) => ({
  type: SHOP.handlers.mergeGroups,
  data,
  onSuccess,
});

export const updateCustomersHandler = (customerID, data, onSuccess) => ({
  type: SHOP.handlers.updateCustomers,
  customerID,
  data,
  onSuccess,
});

export const handleMergeCustomers = (data) => ({
  type: SHOP.handlers.importCustomers,
  data,
});

export const deleteRequestHandler = (customerID, onSuccess) => ({
  type: SHOP.handlers.deleteRequest,
  customerID,
  onSuccess,
});

export default function* shopSaga() {
  yield all([
    yield takeEvery(SHOP.handlers.fetch, fetchShop),
    yield takeEvery(SHOP.handlers.update, updateShop),
    yield takeEvery(SHOP.handlers.updateGroups, updateGroups),
    yield takeEvery(SHOP.handlers.mergeGroups, mergeGroups),
    yield takeEvery(SHOP.handlers.updateCustomers, updateCustomers),
    yield takeEvery(SHOP.handlers.importCustomers, mergeCustomers),
    yield takeEvery(SHOP.handlers.deleteRequest, deleteRequest),
  ]);
}
