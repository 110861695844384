import _ from 'lodash';
import React, { Component } from 'react';
import NumPad from 'react-numpad';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Button, Form, Header, Icon, Segment } from 'semantic-ui-react';

import { db } from '../../../libs/firebase';
import { errorHandler } from '../../../libs/log';
import { formatPhone } from '../../../libs/utils';

import SearchResult from './SearchResult';

class ReturnSignin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editor: {},
      searchResults: {},
    };
  }

  componentDidMount() {
    document.getElementsByClassName('numpad-signin')[0].childNodes[1].childNodes[0].click();
  }

  searchHandler = () => {
    const { shopID } = this.props;
    const { editor } = this.state;
    const { lastPhone } = editor;

    if (!lastPhone || _.toString(lastPhone).length !== 4) {
      errorHandler('Error', 'Please input last 4 digits of your Phone');
      return;
    }

    let ref = db.collection('shops').doc(shopID).collection('customers');
    if (lastPhone) {
      ref = ref.where('phoneLastNumbers', '==', lastPhone);
    }

    ref
      .get()
      .then((snapshot) => {
        toastr.info('Search Results', `Found ${snapshot.size} customers`);
        const searchResults = {};
        snapshot.forEach((doc) => {
          const phone = formatPhone(doc.id);
          searchResults[phone] = {
            ...doc.data(),
            phone,
          };
        });
        this.setState({
          searchResults,
        });
      })
      .catch((error) => errorHandler('Error', error));
  };

  editHandler = (field, value) => {
    const { editor } = this.state;
    editor[field] = _.trim(value);
    this.setState({
      editor,
    });
  };

  renderSearch = () => {
    const { editor } = this.state;
    return (
      <Form
        style={{
          fontSize: 20,
        }}
      >
        <Header>Search Customer</Header>
        <Form.Field className="numpad-signin">
          <NumPad.DateTime
            onChange={(value) => this.editHandler('lastPhone', value)}
            label="Last 4 digits of phone"
            placeholder="0000"
            value={editor.lastPhone || ''}
            dateFormat="YYYY"
            decimal={false}
            negative={false}
            position="center"
            sync
            confirm={console.log}
            update={console.log}
          />
          <Button
            positive
            onClick={this.searchHandler}
            style={{
              fontSize: 20,
            }}
          >
            <Icon name="search" />
            Search
          </Button>
        </Form.Field>
      </Form>
    );
  };

  render() {
    const { backHandler } = this.props;
    const { searchResults } = this.state;
    return (
      <div>
        <Segment>{this.renderSearch()}</Segment>
        {_.size(searchResults) ? (
          <SearchResult
            searchResults={searchResults}
            onClose={() => {
              this.setState({
                editor: {},
                searchResults: {},
              });
            }}
            onCheckIn={backHandler}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ shop }) => ({
  shopID: shop.shopID,
});

export default connect(mapStateToProps)(ReturnSignin);
