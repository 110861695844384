import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {
  Component,
} from 'react';
import ReactApexChart from 'react-apexcharts';

class ReviewGraph extends Component {
  render() {
    const {
      data,
    } = this.props;

    const ratingData = _.orderBy(_.map(_.groupBy(data, (item) => item.rating), (items, rating) => ({
      name: `${rating}* Reviews`,
      count: items.length,
    })), ['name'], ['desc']);

    const seriesData = [
      {
        name: 'Total Reviews',
        count: data.length,
      },
      ...ratingData,
    ];

    return (
      <ReactApexChart
        series={[{
          data: seriesData.map((i) => i.count),
        }]}
        options={
          {
            plotOptions: {
              bar: {
                horizontal: true,
                distributed: true,
              },
            },
            xaxis: {
              categories: seriesData.map((i) => i.name),
            },
            yaxis: {
              labels: {
                show: true,
                style: {
                  fontSize: 14,
                  fontWeight: 'bold',
                },
              },
            },
            tooltip: {
              enabled: false,
            },
          }
        }
        type="bar"
        height={300}
        colors={[
          '#33b2df',
          '#546E7A',
          '#d4526e',
          '#13d8aa',
          '#A5978B',
          '#2b908f',
          '#f9a3a4',
          '#90ee7e',
          '#f48024',
          '#69d2e7',
        ]}
      />
    );
  }
}

ReviewGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default ReviewGraph;
